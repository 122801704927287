import React from 'react';
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import { useRecoilValue } from 'recoil';
import { userState } from "../../state";

// utils
import { verifyPermissions } from '../../assets/js/security'

// components
import { ReactComponent as HomeSVG } from '../../assets/svg/icons/home.svg';
import { ReactComponent as HomeActiveSVG } from '../../assets/svg/icons/home-active.svg';
import { ReactComponent as BookSVG } from '../../assets/svg/icons/book.svg';
import { ReactComponent as BookActiveSVG } from '../../assets/svg/icons/book-active.svg';
import { ReactComponent as CCTVSVG } from '../../assets/svg/icons/CCTV.svg';
import { ReactComponent as CCTVSVGActive } from '../../assets/svg/icons/CCTV-active.svg';

// styles
import './navbar.scoped.less';


const Navbar = () => {
  const user = useRecoilValue(userState);
  const location = useLocation();
  const navigate = useNavigate();
  const USER_QUERY = loader('../../graphql/USER_QUERY.gql');
  const userQuery = useQuery(USER_QUERY);


  return (
    <div className="navBar bg-base-850 text-base-200">
      <div
        className={`
          back-button ${location.pathname.split('/').length > 2 ? ' active' : ''}
        `}
        to={"/home"}
        onClick={() => navigate(-1)}>
        <i className="far fa-arrow-left"></i>
        <span>Назад</span>
      </div>
      <div className="buttonsContainer">
        {verifyPermissions(user, [12001]) && (
          <div className={`
              navLinkButton ${location.pathname === "/home" ? "selected" : ""}
            `}
            onClick={() => navigate("/home")}>
            {location.pathname === "/home" ?
              <HomeActiveSVG className="button" /> :
              <HomeSVG className="button" />}
            <span>Главная</span>
          </div>
        )}
        {verifyPermissions(user, [13001]) && (
          <div className={`
             navLinkButton ${location.pathname === "/tickets" ? "selected" : ""}
            `}
            onClick={() => navigate("/tickets")}>
            {location.pathname === "/tickets" ?
              <i className="fas fa-flag-alt button"></i> :
              <i className="far fa-flag-alt button"></i>}
            <span>Тикеты</span>
            <span className="marker">alpha</span>
          </div>
        )}
        {verifyPermissions(user, [14001]) && (
          <div
            className={`
              navLinkButton ${location.pathname === "/branches" ? "selected" : ""}
            `}
            onClick={() => navigate("/branches")}>
            {location.pathname === "/branches" ?
              <i className="fas fa-store fanav button"></i> :
              <i className="far fa-store fanav button"></i>}
            <span>Филиалы</span>
          </div>
        )}
        {verifyPermissions(user, [19001]) && (
          <div
            className={`navLinkButton ${location.pathname === "/courses" ? "selected" : ""}`}
            onClick={() => navigate("/courses")}>
            {location.pathname === "/courses" ?
              <BookActiveSVG className="button" /> :
              <BookSVG className="button" />}
            <span className="label">Обучение</span>
            <span className="marker dev">dev</span>
          </div>
        )}
        {verifyPermissions(user, [11001]) && (
          <div
            className={`navLinkButton ${location.pathname === "/workers" ? "selected" : ""}`}
            onClick={() => navigate("/workers")}>
            {location.pathname === "/workers" ?
              <i className="fas fa-users fanav button"></i> :
              <i className="far fa-users fanav button"></i>}
            <span>Сотрудники</span>
          </div>
        )}
        {verifyPermissions(user, [18001]) && (
          <div
            className={`navLinkButton ${location.pathname === "/cctv" ? "selected" : ""}`}
            onClick={() => navigate("/cctv")}>
            {location.pathname.includes('cctv') ?
              <CCTVSVGActive className="button" /> :
              <CCTVSVG className="button" />}
            <span className="label">Камеры</span>
            <span className="marker concept">concept</span>
          </div>
        )}
        {
          verifyPermissions(user, [1]) && (
          <div
            className={`navLinkButton ${location.pathname === "/sandbox" ? "selected" : ""}`}
            onClick={() => navigate("/sandbox")}>
            {location.pathname.includes('sandbox') ?
              <i className="fas fa-flask button" aria-hidden="true"></i> :
              <i className="far fa-flask button" aria-hidden="true"></i>}
            <span className="label">Песочница</span>
            <span className="marker dev">dev</span>
          </div>
        )
        }
        {
          verifyPermissions(user, [20001]) && (
            <div
              className={`navLinkButton ${location.pathname === "/pos-statistics" ? "selected" : ""}`}
              onClick={() => navigate("/pos-statistics")}>
              {location.pathname.includes('pos-statistics') ?
                <i className="fas fa-chart-pie button" aria-hidden="true"></i> :
                <i className="fal fa-chart-pie button" aria-hidden="true"></i>}
              <span className="label">Статистика</span>
              <span className="marker">pre-alpha</span>
            </div>
          )
        }
        {verifyPermissions(user, [30001]) && (
          <div
            className={`navLinkButton ${location.pathname === "/network" ? "selected" : ""}`}
            onClick={() => navigate("/network")}>
            {location.pathname.includes('network') ?
              <i className="fas fa-chart-network button" aria-hidden="true"></i> :
              <i className="far fa-chart-network button" aria-hidden="true"></i>}
            <span className="label">Сеть</span>
            <span className="marker dev">dev</span>
          </div>
        )}
        { /* <NavLink className="navLinkButton" to={ "/marketing" }>
          { location.pathname === "/marketing"  ? <div className="activeButton"><i className="far fa-bullseye-pointer"></i><span className="label">Маркетинг</span></div> : <i className="fal fa-bullseye-pointer"></i> }
        </NavLink> */}
      </div>
      <div
        className="account-wrapper bg-base-800"
        onClick={() => navigate("/account")}>
        <div className="account">
          <div className="photo">
            {!userQuery.loading && userQuery.data && userQuery.data.user && (
              <img src={user.profilePhotoPath} alt="" />
            )}
          </div>
          <span>{
            !userQuery.loading &&
            userQuery.data &&
            userQuery.data.user &&
            userQuery.data.user.first_name}</span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
