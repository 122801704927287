const util = require("util");
const EventEmitter = require("events");
const { RPCInterface } = require('./RPCInterface.js');
const {
  WarpIotDevice
} = require('./WarpIotDevice')
const {
  WarpIotService
} = require('./WarpIotService')


function ConnectToCluster(serviceGroup) {
  const that = this;
  this.devices = [];
  this.connectedPeersMap = new Map();
  this.interface = new RPCInterface(serviceGroup);
  this.disconnect = () => {
    this.interface.disconnect();
    this.removeAllListeners();
  }


  util.inherits(WarpIotDevice, {
    prototype: {
      ...EventEmitter.prototype,
      cluster: this,
      interface: this.interface,
      serviceGroup
    }
  })
  util.inherits(WarpIotService, {
    prototype: {
      cluster: this,
      interface: this.interface,
      ...EventEmitter.prototype,
    }
  })

  const externalDevicesMap = new Map();
  let localDevice = undefined;


  this.interface.on('peerAvalible', peer => {
      peer
        .request('handshake', localDevice ? ({
            id: localDevice.id,
            name: localDevice.name,
            version: localDevice.version,
            services: Array.from(localDevice.servicesMap).map(([key, service]) => ({
              name: key,
              state: service.getState()
            }))
          }) : false)
        .catch(e => console.log('e', e))
  });
  this.interface.on('peerUnavalible', ({node}) => {
    that.connectedPeersMap.delete(node.id);
    if (externalDevicesMap.has(node.id)) {
      this.devices =[...externalDevicesMap.values()];
      this.emit('deviceDisconnected', externalDevicesMap.get(node.id));
      externalDevicesMap.delete(node.id);
    }
  });

  this.interface.addMethod('handshake', (props, rpcPeer, source) => {
    return new Promise((resolve, reject) => {
      if(!props)
        return resolve();
      const newDevice = new WarpIotDevice({
        id: props.id,
        name: props.name,
        version: props.version,
        remote: true,
        source: rpcPeer,
        node: source
      });
      if(props.services && props.services.length > 0)
        props.services.forEach(serviceData => {
          newDevice.defineService(new WarpIotService({
            name: serviceData.name,
            state: serviceData.state
          }))
        });
      externalDevicesMap.set(source.id, newDevice);
      that.devices = [...externalDevicesMap.values()];
      that.emit('deviceConnected', newDevice)
      resolve()
    })
  })
  this.interface.addMethod('service:service-defined', (props, rpcPeer, source) => {
    return new Promise((resolve, reject) => {
      if (!externalDevicesMap.has(source.id))
        reject('Device not found')

      externalDevicesMap
        .get(source.id)
        .defineService(new WarpIotService({
          name: props.name
        }))
    })
  })
  this.interface.addMethod('service:state-changed', (props, rpcPeer, source) => {
    return new Promise((resolve, reject) => {
      if (!externalDevicesMap.has(source.id))
        reject('Device not found')
      if (!externalDevicesMap.get(source.id).servicesMap.get(props.name))
        reject('Service not found')

      externalDevicesMap
        .get(source.id)
        .servicesMap.get(props.name)
        .updateServiceStateHandler(props.state)
        resolve(props.state);
    })
  })
  this.interface.addMethod('service:set-state', props => {
    return new Promise((resolve, reject) => {
      if(!localDevice || !localDevice.servicesMap || !localDevice.servicesMap.has(props.name))
        reject('Service not found');
      localDevice.servicesMap.get(props.name)
        .setState(props.state, true)
      this.interface.broadcast('service:state-changed', {
        name: props.name,
        state: props.state
      })
      .catch(e => reject(e))
      that.emit('state-changed', props.state)
      resolve(props.state);
    })
  })
  this.interface.addMethod('service:get-state', props => {
    return new Promise((resolve, reject) => {
      if(!localDevice || !localDevice.servicesMap || !localDevice.servicesMap.has(props.name))
        reject('Service not found');
      resolve(
        localDevice.servicesMap.get(props.name)
        .getState()
      )
    })
  })
  this.interface.addMethod('service:call', ({name, props}) => {
    return new Promise((resolve, reject) => {
      if(!localDevice || !localDevice.servicesMap || !localDevice.servicesMap.has(name))
        reject('Service not found');
      localDevice.servicesMap.get(name)
        .call(props)
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
  })
  this.interface.addMethod('device:data-updated', (props, rpcPeer, source) => {
    return new Promise((resolve, reject) => {
      if (!externalDevicesMap.has(source.id))
        reject('Device not found')

      externalDevicesMap
        .get(source.id)
        .setDeviceData(props)
        .emit('deviceDataUpdated', props);

      resolve(props);
    })
  })

  this.defineDevice = function(device) {
    return new Promise((resolve, reject) => {
      localDevice = device;
      this.interface.broadcast('handshake', localDevice ? ({
        id: localDevice.id,
        name: localDevice.name,
        version: localDevice.version,
        services: Array.from(device.servicesMap).map(([key, service]) => ({
          name: key,
          state: service.getState()
        }))
      }) : false)
      .then(() => resolve())
      .catch(e => reject(e))
    });
  }


}
util.inherits(ConnectToCluster, EventEmitter);

// export ConnectToCluster;
// export WarpIotDevice;
// export WarpIotService;

export {
  ConnectToCluster,
  WarpIotDevice,
  WarpIotService
}
