import React from 'react';
import * as config from "../../config.js";

import { useSetRecoilState } from 'recoil';
import { overlayState } from "../../state";


// styles
import './worker-card.scoped.less';

// utils
import stringToHslColor from '../../assets/js/stringToHslColor'
  // onClick={() => setOverlay(prevState => ({...prevCtx, modalView: {...prevCtx.modalView, visible: true, src: profilePhotoPath  ? profilePhotoPath : `https://koreana.link:${config.port}/public/user/${id}.jpg?`}}))}

function WorkerCard({ first_name, last_name, position, id, profilePhotoPath, branch }) {
  const setOverlay = useSetRecoilState(overlayState);

  return (
    <div className="worker-card">
      <div className="color-box" style={{ backgroundColor: stringToHslColor(id, 50, 50) }}>
        <i className="fas fa-ban block"></i>
      </div>
      <div
        className="avatar"
       onClick={() => setOverlay(prevState => ({
         type: 'modal-view',
         data: {
           src: `https://koreana.link:${config.port}/public/user/${id}.jpg?`
         }
       }))}
      >
        <img src={profilePhotoPath  ? profilePhotoPath : `https://koreana.link:${config.port}/public/user/${id}.jpg?`} alt="" />
      </div>
      <p className="name">{first_name + ' ' + last_name}</p>
      <p className="position">{position}</p>
      { branch.length === 1 &&
        <p
          className="branch"
          style={{background: stringToHslColor(branch[0].id, 50, 50)}}
        >{branch[0].name}</p> }
      <div className="actionsContainer">
        <div className="action-btn permissions"><i className="fas fa-shield"></i></div>
        <div className="action-btn study"><i className="fas fa-graduation-cap"></i></div>
        <div className="action-btn schedule"><i className="fas fa-calendar-alt"></i></div>
        <div className="action-btn message"> <i className="fas fa-comment-alt-lines"></i> </div>
      </div>
    </div>
  );
}

export default WorkerCard;
