import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayState } from "../../state";


// styles
import './select.scoped.less';

const UAParser = require('ua-parser-js').UAParser;
const uaParser = new UAParser();

function Select({
  options,
  placeholder,
  value = undefined,
  onChange = () => { },
}) {
  const setOverlay = useSetRecoilState(overlayState);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [currentTitle, setCurrentTitle] = useState("");
  const [selectedValue, setSelectedValue] = useState(
    value ?
      value :
      !placeholder ?
        options[0].value : undefined);



  useEffect(() => {
    if (options && options.length > 0)
      setCurrentOptions(options);
  }, [
      options
    ]);

  useEffect(() => {
    if (options && options.length > 0) {
      let filtredOptions = currentOptions.filter(option => option.value === selectedValue);
      setCurrentTitle(
        filtredOptions[0] ?
          filtredOptions[0].title :
          placeholder ? placeholder :
            options[0].title);
    }
  }, [
      currentOptions,
      selectedValue,
      options,
      placeholder
    ]);


  if (uaParser.getBrowser().name === 'Mobile Safari')
    return (
      <div
        className="select"
        onClick={e => options.length > 1 && setOverlay(prevState => ({
          type: 'select',
          data: {
            currentOptions,
            selectedValue
          },
          events: {
            onChange: newValue => {
              setSelectedValue(newValue);
              onChange(newValue);
            }
          }
        }))}>
        <span>
          {options.length === 1 ? options[0].title : currentTitle}
        </span>
        <i className="fa fa-chevron-down" aria-hidden="true"></i>
      </div>
    );
  else
    return (
      <select
        value={value || selectedValue}
        onChange={e => {onChange(e.target.value); setSelectedValue(e.target.value)}}>
        {placeholder &&
          <option
            disabled
            selected>
            {placeholder}
          </option>
        }
        {
          currentOptions.map((option, index) =>
            <option
              key={"select-option-" + index}
              value={option.value}
              placeholder={placeholder}
              selected={!placeholder && index === 0}>
              {option.title}
            </option>
          )
        }
      </select>
    );
}

export default Select;
