import React from 'react';

// styles
import './modal-view.scoped.less'

function ModalView({ close, src }) {
  return (
    <div className="modal-view">
      <img className="modal-view-img" src={src} alt="" />
      <div
        className="close"
        onClick={() => close ? close() : closeModalView()}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>
  )
}

export default ModalView;
