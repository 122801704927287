const util = require("util");
const EventEmitter = require("events");

function WarpIotService(serviceProps) {
  const that = this;
  this.state = serviceProps.state || undefined;
  this.name = serviceProps.name;

  this.cluster.on('disconnected', () => {
    this.removeAllListeners();
  })

  this.getState = () => {
    if (that.device.remote)
      return new Promise((resolve, reject) => {
        that.device.source
          .request('service:get-state', {
            name: this.name
          })
          .then((result) => {
            resolve(result)
          })
          .catch(e => reject(e))
      });
    else
      return new Promise((resolve, reject) => {
        resolve(this.state)
      });
  }

  this.setState = (state, quiet) => {
    if (that.device.remote) {
      return new Promise((resolve, reject) => {
        if(!quiet)
          that.device.source
            .request('service:set-state', {
              name: that.name,
              state

            })
            .then((result) => {
              this.state = state;
              resolve(state);
            }, e=>reject(e))
        else {
          this.state = state;
          this.emit('state-changed', this.state);
          resolve(this.state);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        this.state = state;
        this.emit('state-changed', this.state);
        if(!quiet)
          that.interface.broadcast('service:state-changed', {
            name: that.name,
            state: this.state
          })
          .catch(e => reject(e))
        resolve(state);
      });
    }
  }

  this.updateServiceStateHandler = state =>
    this.setState(state, true)

  this.handler = () => {}

  this.call = (props) => {
    if (that.device.remote)
      return new Promise((resolve, reject) => {
        that.device.source
          .request('service:call', {name: that.name, props})
          .then(result => resolve(result))
          .catch(error => reject(error.message))
      });
    else
      return new Promise(async (resolve, reject) => {
        that.handler(props)
          .then(result => resolve(result))
          .catch(error => reject(error))
      })

    that.emit('call', props)
  }
}

export {
  WarpIotService
};
