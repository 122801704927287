import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  ApolloProvider
} from 'react-apollo';
import {
  setContext
} from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache
} from '@apollo/client';
import {
  createUploadLink
} from 'apollo-upload-client';
import {
  split
} from '@apollo/client';
import {
  getMainDefinition
} from '@apollo/client/utilities';
import {
  WebSocketLink
} from '@apollo/client/link/ws';
import axios from 'axios';
import {
  buildAxiosFetch
} from "@lifeomic/axios-fetch";
// import Context from './context';
import {
  RecoilRoot
} from 'recoil';

import App from "./App.tsx";

import * as config from "./config.js";

function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }
    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }
    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }
    return false;
}
window.isElectron = isElectron;

let ipcRenderer;
if (window.isElectron()) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

async function render() {
  if (window.isElectron()) {
    window.screenID = await ipcRenderer.invoke('WINDOW-ID');
  }
  const httpLink = createUploadLink({
    uri: `https://koreana.link:${config.port}/graphql`,
    fetch: buildAxiosFetch(axios, (config, input, init) => ({
      ...config,
      onUploadProgress: init.onUploadProgress,
    })),
  });

  const wsLink = new WebSocketLink({
    uri: `wss://koreana.link:${config.port}/subscriptions`,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          token: localStorage.getItem(window.screenID ? window.screenID + '_' + 'token' : 'token') ? localStorage.getItem(window.screenID ? window.screenID + '_' + 'token' : 'token') : "",
          appType: localStorage.getItem('app-type') ? localStorage.getItem('app-type') : "",
          clientid: localStorage.getItem('clientID') ? localStorage.getItem('clientID') : "",
        }
      }
    }
  });


  const authLink = setContext((_, {
    headers
  }) => {

    return {
      headers: {
        ...headers,
        token: localStorage.getItem(window.screenID ? window.screenID + '_' + 'token' : 'token') ?
        localStorage.getItem(window.screenID ? window.screenID + '_' + 'token' : 'token') : "",
        appType: localStorage.getItem('app-type') ? localStorage.getItem('app-type') : "",
        clientid: localStorage.getItem('clientID') ? localStorage.getItem('clientID') : "",
      }
    }
  });

  const splitLink = split(
    ({
      query
    }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );


  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          project: {
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming
              };
            }
          }
        }
      }
    }
  });


  const client = new ApolloClient({
    link: splitLink,
    cache
  });

  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <ApolloProvider client={client}>
          <App/>
        </ApolloProvider>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
render();

// sendTouchEvent(150, 150, myElement, 'touchstart');
// sendTouchEvent(220, 200, myElement, 'touchmove');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
window.addEventListener('load', () => {
  serviceWorkerRegistration.subscribeNotifications();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
