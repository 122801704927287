import React from 'react';

// styles
import './modal.scoped.less';

const Modal = ({ data, close }) => {
  if (data)
    return (
      <div className="modal">
        <div className="title">{data.title}</div>
        <i
          className="fas fa-times close"
          onClick={() =>
            close()}></i>
        {
          data.description !== undefined &&
          <div className="description">{data.description}</div>
        }
        <div className="actions">
          {data.actions.map((action, index) =>
            <button
              key={'modalBtn' + index}
              onClick={() =>
                action.callback !== undefined &&
                action.callback(close())}>{action.title}</button>
          )}
        </div>
      </div>
    );
  else
    return null;
}


export default Modal;
