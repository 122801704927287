import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../../state";

import DeviceCard from './DeviceCard';

// styles
import './devices.scoped.less';

const DEVICES_QUERY = loader('../../../graphql/DEVICES_QUERY.gql');

function Devices() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const devicesQuery = useQuery(DEVICES_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Устройства',
    }));

    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [setTopNavBar]);


  return (
    <div className="about-app scene">
      <div className="wrapper">
        {
          devicesQuery.data &&
          devicesQuery.data.devices.map((device, index) =>
            <DeviceCard key={"device-card-" + index} device={device} />)
        }
      </div>
    </div>
  );
}


export default Devices;
