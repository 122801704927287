import React, {
  useState,
  useEffect,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

// import { NavLink } from 'react-router-dom';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState, topNavBarState } from "../../state";

// components
import Loading from '../../components/Loading';
import WorkerCard from '../../components/WorkerCard'

// styles
import './workers.scoped.less';

const USERS_QUERY = loader('../../graphql/USERS_QUERY.gql');
const ALL_BRANCHES_QUERY = loader('../../graphql/ALL_BRANCHES_QUERY.gql');


function Workers() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const topNavBar = useRecoilValue(topNavBarState);
  const userAtom = useRecoilValue(userState);
  const {
    data,
    loading
  } = useQuery(USERS_QUERY);
  const allBranchesQuery = useQuery(ALL_BRANCHES_QUERY);
  const [filtredUsers, setFiltredUsers] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    const usersFilter = users => {
      let filtredUsersReult = [];
      if (keyword !== undefined && keyword !== '') {
        filtredUsersReult = users.filter(user =>
          user.first_name.toLowerCase().includes(keyword) || user.last_name.toLowerCase().includes(keyword));
      } else if (keyword === '' && data && data.users && data.users.length > 0) {
        filtredUsersReult = data.users;
      } else {
        filtredUsersReult = users;
      }
      setFiltredUsers(filtredUsersReult);
    }

    if (!loading && data && data.users && data.users.length > 0) {
      usersFilter(data.users);
    }
  }, [data, keyword, loading]);

  useEffect(() => {
    // configurating topNavBar
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Сотрудники',
      search: {
        ...prevState.search,
        visible: true
      }
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
      search: {
        ...prevState.search,
        value: '',
        visible: false
      }
    }));
  }, [setTopNavBar])

  useEffect(() => {
    setKeyword(topNavBar.search.value);
  }, [topNavBar.search.value]);

  return (
    <div className="workers scene" >
      <div className="profiles grid">
        {(loading || allBranchesQuery.loading) && <Loading />}
        {
          allBranchesQuery.data &&
          allBranchesQuery.data.allBranches &&
          filtredUsers.length !== 0 &&
          filtredUsers.map(user => (
            <WorkerCard
              key={user.id}
              branch={allBranchesQuery.data.allBranches.filter(branch =>
                user.branch_id.includes(branch.id))}
              first_name={user.first_name}
              last_name={user.last_name}
              position={user.position}
              profilePhotoPath={
                user.id === userAtom.id ? userAtom.profilePhotoPath : undefined
              }
              id={user.id} />))}
      </div>
    </div>
  );
}

export default Workers;
