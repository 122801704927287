import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { topNavBarState, swipedState } from "../../state";

// styles
import './top-navbar.scoped.less';
import './top-navbar.less';

const TopNavbarComponent = ({ onSwipeButtonClick }) => {
  const topNavBar = useRecoilValue(topNavBarState);
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const swiped = useRecoilValue(swipedState);
  const setSwiped = useSetRecoilState(swipedState);

  useEffect(() => {
    onSwipeButtonClick(swiped);
  }, [swiped, onSwipeButtonClick]);

  return (
    <div className={`title-bar bg-base-800 mx-lim${topNavBar.hiddenOndesctop ? ' hide-on-desctop' : ''}`}>
      <ul
        className={"burger" + (swiped ? ' close' : '')}
        style={{ pointerEvents: swiped ? "none" : "auto" }}
        onClick={e => setSwiped(prevState => !prevState)}>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <p className="title">{topNavBar.title}</p>
      <div className="actions">
        {topNavBar.search.visible && (
          <div className="search">
            <input
              type="text"
              onChange={e => setTopNavBar(prevState => ({
                ...prevState,
                search: {
                  ...prevState.search,
                  value: e.target.value
                }
              }))}
              value={topNavBar.search.value} />
            <div className="search-button">
              <i className="fas fa-search"></i>
            </div>
          </div>
        )}
        {topNavBar.actions.map((action, index) =>
          action.type === 'button' ?
            <div
              onClick={() => action.onClick()}
              key={"top-navbar-btn-" + index}
              className={`top-navbar-action-button ${action.inActive ? ' in-active' : ''}`}
              to={action.to}>
              <div className="button-background">
                {action.content}
              </div>
            </div> :
            <NavLink
              key={"top-navbar-link-btn-" + index}
              className={`top-navbar-action-button ${action.inActive ? ' in-active' : ''}`}
              to={action.to}>
              <div className="button-background bg-base-700 hover:bg-base-650">
                {action.content}
              </div>
            </NavLink>
        )}

      </div>
    </div>
  )
};

export default TopNavbarComponent;
