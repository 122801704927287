import React from 'react';

// styles
import './cctv-view.scoped.less';

// components
import CameraCard from '../../components/CameraCard' ;

function CCTVView() {
  return (
    <div className="CCTVView scene">
      <div className="sceneTitle">
        <span>Средний пр. ВО, 11</span>
      </div>
      <div className="grid">
        <CameraCard camID={1}/>
        <CameraCard camID={1}/>
        <CameraCard camID={1}/>
        <CameraCard camID={1}/>
        <CameraCard camID={1}/>
        <CameraCard camID={1}/>
      </div>
    </div>
  );
}

export default CCTVView;
