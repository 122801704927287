import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';


// svg
import { ReactComponent as ChromeIcon } from '../../../assets/svg/icons/browsers/chrome.svg';
import { ReactComponent as FirefoxIcon } from '../../../assets/svg/icons/browsers/firefox.svg';
import { ReactComponent as SafariIcon } from '../../../assets/svg/icons/browsers/safari.svg';
import { ReactComponent as BraveIcon } from '../../../assets/svg/icons/browsers/brave.svg';
import { ReactComponent as EdgeIcon } from '../../../assets/svg/icons/browsers/edge.svg';
import moment from 'moment'

// styles
import './device-card.scoped.less';

// querys
const LOGOUT_MUTATION = loader('../../../graphql/LOGOUT_MUTATION.gql');
const DEVICES_QUERY = loader('../../../graphql/DEVICES_QUERY.gql');

const uaParser = require('ua-parser-js');

function Devices({ device }) {
  const [mutateLogout] = useMutation(LOGOUT_MUTATION, {
    refetchQueries: [
      { query: DEVICES_QUERY }
    ]
  });
  const ua = uaParser(device.user_agent);
  const browser = ua.browser;
  const os = ua.os;

  return (
    <div className="device">
      <div className={`icon-wrapper
          ${browser.name === "Chrome" && " chrome"}
          ${browser.name === "Brave" && " brave"}
          `}>
        {(
          browser.name === "Chrome" ? <ChromeIcon /> :
            browser.name === "Firefox" ? <FirefoxIcon /> :
              browser.name === "Safari" ? <SafariIcon /> :
                browser.name === "Brave" ? <BraveIcon /> :
                  browser.name === "Brave" ? <BraveIcon /> :
                    browser.name === "Edge" ? <EdgeIcon /> :
                      <div className="unknown-agent">?</div>
        )}
      </div>
      <div className="info">
        <div>
          <span>{os.name && (os.name + (os.version ? " " + os.version : ""))}</span>
          <span>
            {
              browser.name &&
              (", " + browser.name + (browser.version ? " " + browser.version : ""))
            }
          </span>
        </div>
        <div>
          <span>
            {
              device.last_online &&
              moment.unix(device.last_online).format("hh:mm DD.MM.YYYY")
            }
          </span>
        </div>
      </div>
      <div className="block" onClick={() => mutateLogout({
        variables: {
          deviceID: device.id
        }
      })}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
    </div>
  );
}

export default Devices;
