import React, { useState, useEffect } from 'react';
import { AutoComplete, Tag, DatePicker } from 'antd';
import moment from 'moment';
import Ripples from 'react-ripples'


import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
// styles
import 'antd/dist/antd.less';
import '../../assets/less/antd-overwrite.less';
import './selectors-card.scoped.less';

const { RangePicker } = DatePicker;

const AVG_POSITIOBSSTATISTICS_QUERY = loader('../../graphql/AVG_POSITIOBSSTATISTICS_QUERY.gql');
const POS_ORDERS_QUERY = loader('../../graphql/POS_ORDERS_QUERY.gql');


function SelectorsCard({
  setPosDataSelectors,
  posDataSelector,
  branches = [],
  posUnicItems,
  statisticsEvents,
  delayPerItem,
}) {
  const [availableItems, setAvailableItems] = useState([]);
  const [availableBranches, setAvailableBranches] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [period, setPeriod] = useState([
    moment().startOf('day'),
    moment().endOf('day')
  ]);
  const [branchesearchValue, setBranchesearchValue] = useState('');
  const [itemSearchValue, setItemSearchValue] = useState('');
  const [canApply, setCanApply] = useState(true);
  const [fetchAvgPositionsQuery, avgPositionsQuery] = useLazyQuery(AVG_POSITIOBSSTATISTICS_QUERY, {
    fetchPolicy: "network-only"
  });
  const [fetchPosOrdersQuery, posOrdersQuery] = useLazyQuery(POS_ORDERS_QUERY, {
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    setCanApply(true);
  }, [selectedItems, selectedBranches, period]);
  useEffect(() => {
    setAvailableBranches(branches.map(branch => ({ value: branch.name })));
  }, [branches]);
  useEffect(() => {
    setAvailableItems(posUnicItems.map(item => ({ value: item.name })));
  }, [posUnicItems]);
  useEffect(() => {
    setCanApply(true);
  }, [selectedItems]);
  useEffect(() => {
    if (avgPositionsQuery.data) {
      setPosDataSelectors(prevState =>
        prevState.map(e =>
          e.index !== posDataSelector.index ? e : {
            ...e,
            data: avgPositionsQuery.data.avgPositionsStatistics,
          }))
    }
  }, [
      avgPositionsQuery.data,
      posDataSelector.index,
      setPosDataSelectors
    ]);
  useEffect(() => {
    if (posOrdersQuery.data) {
      if (posOrdersQuery.data.posOrders.length > 0) {
        setSelectedOrders(prevState => [
          ...prevState,
          ...posOrdersQuery.data.posOrders
        ]);
      }
      if (posOrdersQuery.data.posOrders.length < ((selectedOrders.length === 0 && posDataSelector.ordersFetchCounter) ? posDataSelector.ordersFetchCounter * 10 : 10)) {
        setPosDataSelectors(prevState =>
          prevState.map(e =>
            e.index !== posDataSelector.index ? e : {
              ...e,
              finish: true
            }))
      }
    }
  }, [posOrdersQuery.data]);
  useEffect(() => {
    setPosDataSelectors(prevState =>
      prevState.map(e =>
        e.index !== posDataSelector.index ? e : {
          ...e,
          selectedOrders: selectedOrders || []
        }))
  }, [selectedOrders])

  useEffect(() => {
    if(delayPerItem === undefined)
      return;

    setSelectedOrders([]);
    fetchPosOrdersQuery({
      variables: {
        start: period[0].unix(),
        end: period[1].unix(),
        items: selectedItems,
        stations: [],
        branches: selectedBranches,
        limit: 10,
        delayPerItem: delayPerItem
      }
    });
  }, [
    delayPerItem,
    fetchPosOrdersQuery,
  ])
  useEffect(() => {
    statisticsEvents.on('LOAD_NEW_ORDERS', () => {
      let markersMap = new Map();
      for (let i = 0; i < selectedOrders.length; i++) {
        if (
          !markersMap.get(selectedOrders[i].device_id) ||
          selectedOrders[i].creator_action < markersMap.get(selectedOrders[i].device_id))
          markersMap.set(selectedOrders[i].device_id, selectedOrders[i].creator_action)
      }
      fetchPosOrdersQuery({
        variables: {
          start: period[0].unix(),
          end: period[1].unix(),
          items: selectedItems,
          stations: [],
          branches: selectedBranches,
          delayPerItem: delayPerItem,
          limit: 10,
          posDeviceLimitMarkers: Array.from(markersMap, function(item) {
            return { device_id: item[0], creator_action: item[1] }
          })
        }
      });
      // console.log('LOAD_NEW_ORDERS');
    })
  }, [statisticsEvents]);

  return (
    <section
      className="selector">
      <div className="title">
        <div
          className="label"
          onClick={() => setPosDataSelectors(prevState => prevState.map(e => e.index !== posDataSelector.index ? e : ({ ...e, hidden: !e.hidden })))}
        >
          <span style={!posDataSelector.hidden ? { color: posDataSelector.color } : {}}>
            Период {posDataSelector.index}
          </span>
          {
            !posDataSelector.hidden ?
              <i className="fa fa-eye" aria-hidden="true"></i> :
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
          }
        </div>
        <i
          className="fa fa-times"
          aria-hidden="true"
          onClick={() => setPosDataSelectors(prevState => (prevState.length > 1 && prevState.filter(e => e.index !== posDataSelector.index)) || prevState)}></i>
      </div>
      <RangePicker
        inputReadOnly={true}
        className="date-picker"
        format={"DD.MM.YYYY"}
        onChange={dateRange => {
          setPeriod([
            dateRange[0],
            dateRange[1],
          ])
        }
        }
        ranges={{
          'Сегодня': [moment().startOf('day'), moment().endOf('day')],
          'Вчера': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
          'За эту неделю': [moment().startOf('week'), moment().endOf('week')],
          'За этот месяц': [moment().startOf('month'), moment().endOf('month')],
          '7 дней': [moment(), moment().subtract(7, 'days')],
          '30 дней': [moment().startOf('month'), moment().subtract(30, 'days')],
        }}
        defaultValue={period} />
      <AutoComplete
        className="dish-selector-input"
        options={availableBranches}
        placeholder="Рестораны"
        value={branchesearchValue}
        searchValue={branchesearchValue}
        onChange={(str, match) => {
          if (match.value) {
            setBranchesearchValue('');
            if (!selectedBranches.includes(match.value))
              setSelectedBranches(prevState => [match.value, ...prevState])
            setAvailableBranches(prevState =>
              prevState.filter(item => item.value !== match.value).sort((a, b) => a.value > b.value))
          }
          else
            setBranchesearchValue(str)

        }}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      >
      </AutoComplete>
      {
        selectedBranches.map(selectedItem =>
          <Tag key={selectedItem} style={{ borderLeft: '6px solid #aaa' }} onClick={(e) => {
            setSelectedBranches(prevState => prevState.filter(prevSelectedItem => prevSelectedItem !== selectedItem));
            setAvailableBranches(prevState => [...prevState, { value: selectedItem }].sort((a, b) => a.value > b.value));
          }}>{selectedItem}</Tag>
        )
      }
      <AutoComplete
        className="dish-selector-input"
        options={availableItems}
        placeholder="Позиции меню"
        value={itemSearchValue}
        searchValue={itemSearchValue}
        onChange={(str, match) => {
          if (match.value) {
            setItemSearchValue('');
            if (!selectedItems.includes(match.value))
              setSelectedItems(prevState => [match.value, ...prevState])
            setAvailableItems(prevState =>
              prevState.filter(item => item.value !== match.value).sort((a, b) => a.value > b.value))
          }
          else
            setItemSearchValue(str)

        }}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      >
      </AutoComplete>
      {
        selectedItems.map(selectedItem =>
          <Tag key={selectedItem} style={{ borderLeft: '6px solid #aaa' }} onClick={(e) => {
            setSelectedItems(prevState => prevState.filter(prevSelectedItem => prevSelectedItem !== selectedItem));
            setAvailableItems(prevState => [...prevState, { value: selectedItem }].sort((a, b) => a.value > b.value));
          }}>{selectedItem}</Tag>
        )
      }
      {
        canApply &&
        <Ripples className="apply-selector-btn-wrapper" color="rgba(255,255,255,0.4)">
          <button
            className="apply-selector-btn"
            onClick={() => {
              setCanApply(false);
              setSelectedOrders([]);
              setPosDataSelectors(prevState =>
                prevState.map(e =>
                  e.index !== posDataSelector.index ? e : {
                    ...e,
                    finish: false
                  }))
              fetchAvgPositionsQuery({
                variables: {
                  start: period[0].unix(),
                  end: period[1].unix(),
                  items: selectedItems,
                  stations: [],
                  branches: selectedBranches
                }
              });
              fetchPosOrdersQuery({
                variables: {
                  start: period[0].unix(),
                  end: period[1].unix(),
                  items: selectedItems,
                  stations: [],
                  branches: selectedBranches,
                  delayPerItem: delayPerItem,
                  limit: (selectedOrders.length === 0 && posDataSelector.ordersFetchCounter) ? posDataSelector.ordersFetchCounter * 10 : 10
                }
              });
            }}>
            <i className="fa fa-check" aria-hidden="true"></i>
            <span>Применить</span>
          </button>
        </Ripples>
      }
    </section>
  );
}

export default SelectorsCard;
