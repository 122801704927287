export const verifyPermissions = (user, permissions = []) => {
  if (
    user &&
    (
      contains(user.permissions, permissions) ||
      user.permissions.includes(1)
    )
  ) return true;
  else return false
}

// utils
function contains(where, what){
    for(var i=0; i<what.length; i++){
        if(where.indexOf(what[i]) === -1) return false;
    }
    return true;
}
