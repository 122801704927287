import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useLazyQuery } from 'react-apollo';
import { loader } from 'graphql.macro';

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

// components
import { QrReader } from 'react-qr-reader';

// styles
import './qrscanner.scoped.less';

// querys
const AUTHORIZE_CLIENT_QUERY = loader('../../graphql/AUTHORIZE_CLIENT_QUERY.gql');
const DEVICES_QUERY = loader('../../graphql/DEVICES_QUERY.gql');


function QRScanner() {
  const { iotDeviceId } = useParams();
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const location = useLocation();


  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: iotDeviceId ? 'IoT QR сканер' : 'QR сканер',
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [location.pathname, setTopNavBar, iotDeviceId]);

  const navigate = useNavigate();
  const [authID, setauthID] = useState(null);
  const [
    authorizeClient,
    { loading, error, data }
  ] = useLazyQuery(AUTHORIZE_CLIENT_QUERY, {
    fetchPolicy: "network-only",
    refetchQueries: [
      { query: DEVICES_QUERY }
    ]
  });
  const [result, setResult] = useState('No result');

  if (error) {
    console.log(error)
  }

  useEffect(() => {
    console.log(error)
  }, [error])

  let handleResult = data => {

    if (data && data.text) {
      setResult(data.text)
    }
  }
  let handleError = err => {
    console.error(err)
  }

  useEffect(() => {
    let appID = result.split('/')[3];
    if (appID && appID.length === 64) {
      try {
        window.navigator.vibrate(200);
      } catch {
        console.log("Looks like vibrate function not supported by your device 😓")
      }
      setauthID(appID);
    }
  }, [result]);

  if (!loading && data) {
    navigate("/account");
  }

  let beginAuthorizeClient = () => {
    if (authID !== null) {
      authorizeClient({
        variables: {
          authID,
          ...(iotDeviceId ? { iotDeviceId: parseInt(iotDeviceId) } : {})
        },
      })
    }
  }

  return (
    <div className="qrscanner scene" style={{ overflow: 'hidden' }}>
      <div className="mxW500">
        <div className="wrapper">
          <QrReader
            delay={300}
            onError={handleError}
            onResult={handleResult}
            constraints={{facingMode: 'environment'}}
            videoStyle={{ objectFit: 'cover', minWidth: '100%', minHeight: '100%' }}
            showViewFinder={false}
          />
          <div className="mask"></div>
        </div>
        <section className="buttons">
          <div
            className={"authorize-btn" + (authID === null ? '' : " active")}
            onClick={() => beginAuthorizeClient()}>
            <span>{ iotDeviceId ? ("link device by id " + iotDeviceId) : "Авторизировать" }</span>
          </div>
        </section>
      </div>
    </div>
  );
}

export default QRScanner;
