const {
  Network,
  AnonymousAuth
} = require('ataraxia');
// const {
//   TCPTransport,
//   TCPPeerMDNSDiscovery
// } = require('ataraxia-tcp');
const {
  WebSocketClientTransport
} = require('ataraxia-ws-client');


const net = new Network({
  name: 'pos',
  transports: [
    new WebSocketClientTransport({
      // URL to the websocket on the server
      url: 'wss://ataraxia.koreana.link:7001',
      webSocketFactory: url => new WebSocket(url),
      // If using outside a browser, define how a WebSocket is created
      factory: url => new WebSocket(url),
      authentication: [
        new AnonymousAuth()
      ]
    }),
  ]
});

// net.onNodeAvailable(node => {
//   console.log('Node ' + node.id + ' is now avalible');
// })
// net.onNodeUnavailable(node => {
//   console.log('Node ' + node.id + ' is now unavalible');
// })

module.exports = {
  net
}
