import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

import { useSetRecoilState, useRecoilValue } from 'recoil';
import { topNavBarState, userState } from "../../state";

// styles
import './ticket.scoped.less';

// components
import TicketCard from '../../components/TicketCard';

// querys & mutations
const TICKETS_QUERY = loader('../../graphql/TICKETS_QUERY.gql');
const EDIT_TICKET_STEP_STATUS_MUTATION = loader('../../graphql/EDIT_TICKET_STEP_STATUS_MUTATION.gql');
const ADD_TICKET_STEP_MUTATION = loader('../../graphql/ADD_TICKET_STEP_MUTATION.gql');
const UPDATE_TICKET_STEP_SUBSCRIPTION = loader('../../graphql/UPDATE_TICKET_STEP_SUBSCRIPTION.gql');
const NEW_TICKET_STEP_SUBSCRIPTION = loader('../../graphql/NEW_TICKET_STEP_SUBSCRIPTION.gql');


function Ticket() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const user = useRecoilValue(userState);
  const [ticket, setTicket] = useState();
  const { id } = useParams();

  const [editTicketStepStatus] = useMutation(EDIT_TICKET_STEP_STATUS_MUTATION, {
    refetchQueries: [
      { query: TICKETS_QUERY }
    ]
  });
  const [addTicketStepStatus] = useMutation(ADD_TICKET_STEP_MUTATION, {
    refetchQueries: [
      {
        query: TICKETS_QUERY,
        variables: {
          id
        }
      }
    ]
  });
  const ticketQuery = useQuery(TICKETS_QUERY, {
    variables: {
      id
    }
  });
  const updateTicketStepSub = useSubscription(UPDATE_TICKET_STEP_SUBSCRIPTION, {
    variables: {
      ticket_id: id
    }
  });
  const newTicketStepSub = useSubscription(NEW_TICKET_STEP_SUBSCRIPTION, {
    variables: {
      ticket_id: id
    }
  });

  // useEffect(() => {
  //   if(ticketQuery.data)
  //     ticketQuery.refetch()
  // }, [
  //   ticketQuery.data
  // ])

  useEffect(() => {
    // console.log(newTicketStepSub.data.newTicketSteps);

    if (newTicketStepSub.data) {
      setTicket(prevState => (prevState.id === newTicketStepSub.data.newTicketSteps[0].ticket_id ?
        ({ ...prevState, steps: [...prevState.steps, ...newTicketStepSub.data.newTicketSteps] }) : prevState));
    }
  }, [newTicketStepSub.data]);

  useEffect(() => {
    if (updateTicketStepSub.data) {
      setTicket(prevState => (prevState.id === updateTicketStepSub.data.updateTicketSteps[0].ticket_id ?
        ({
          ...prevState, steps: prevState.steps.map(step => (
            step.id === updateTicketStepSub.data.updateTicketSteps[0].id ?
              { ...step, done: updateTicketStepSub.data.updateTicketSteps[0].done } : step))
        }) : prevState));
    }
  }, [updateTicketStepSub.data]);

  //
  // useEffect(() => {
  //   if (updateTicketStepSub.data) {
  //     setTicket(prevState => (prevState.id === updateTicketStepSub.data.updateTicketSteps[0].ticket_id ?
  //       ({
  //         ...prevState, steps: prevState.steps.map(step => (
  //           step.id === updateTicketStepSub.data.updateTicketSteps[0].id ?
  //           { ...step, done: updateTicketStepSub.data.updateTicketSteps[0].done } : step))
  //         }) : prevState));
  //       }
  //     }, [updateTicketStepSub.data]);

  // set task state when task data is loaded
  useEffect(() => {
    if (!ticket && ticketQuery.data && ticketQuery.data.tickets && ticketQuery.data.tickets[0]) {
      setTicket(ticketQuery.data.tickets[0]);
    }
  }, [
      ticketQuery.data,
      ticket
    ]);

  // change app context
  useEffect(() => {
    if (ticket || id === "new") {
      setTopNavBar(prevState => ({
        ...prevState,
        title: id === "new" ? "Новый тикет" : ticket.title,
      }));
    }

    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [setTopNavBar, ticket, id]);


  return (
    <div className="ticket scene mx-lim">
      <TicketCard
        ticketIsNew={id === "new"}
        ticket={ticket}
        user={user}
        controlls={true}
        onAddTicketStepBtnClick={({ title, description }) => addTicketStepStatus({ variables: { ticket_id: ticket.id, title, description: description ? description : "" } })}
        onDoneStepBtnClick={step_id => editTicketStepStatus({ variables: { ticket_id: ticket.id, step_id, status: true } })} />
    </div>
  );
}

export default Ticket;
