import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Progress } from 'antd';
import moment from 'moment';

// styles
import './checklist-result.scoped.less';

// components
import Check from '../../components/Check';

// query`s
const STATISTICS_BY_RANGE_QUERY = loader('../../graphql/STATISTICS_BY_RANGE_QUERY.gql');

// export type BarGroupProps = {
//   width: number;
//   height: number;
//   margin?: { top: number; right: number; bottom: number; left: number };
//   events?: boolean;
// };

export const green = '#e5fd3d';
export const background = '#212325';

let sum = function(arr, task_id, param) {
  var total = 0;
  var amount = 0;
  for (var i = 0, _len = arr.length; i < _len; i++) {
    if (arr[i]['task_id'] === task_id) {
      total += arr[i][param]
      amount++;
    }
  }
  if (amount > 0)
    return Math.floor(total / (amount * 5) * 100);
  else
    return '?'
}

function ChecklistResult() {
  const [showOnly, setShowOnly] = useState([3, 4, 5, 6]);
  const [amdinRating, setAdminRating] = useState(0);
  const [sanpinRating, setSanpinRating] = useState(0);
  const [kitchenRating, setKitchenRating] = useState(0);
  const [barRating, setBarRating] = useState(0);

  const { data, loading } = useQuery(STATISTICS_BY_RANGE_QUERY, {
    variables: {
      start: moment().startOf('day').unix().toString(),
      end: moment().endOf('day').unix().toString()
    },
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    if (!loading && data) {
      setKitchenRating(sum(data.statisticsByRange, "4", "status"));
      setAdminRating(sum(data.statisticsByRange, "3", "status"));
      setSanpinRating(sum(data.statisticsByRange, "5", "status"));
      setBarRating(sum(data.statisticsByRange, "6", "status"));
    }
  }, [data, loading])

  return (
    <div className="ChecklistResult scene">
      <div className="title">
        <span>Результат проверки</span>
      </div>
      {!loading && <div className="rating">
        <div
          className={`ratingWrapper ${(showOnly.indexOf(3) > -1) ? 'active' : ''}`}
          onClick={() => {
            showOnly.indexOf(3) > -1 ? setShowOnly(showOnly.filter(item => item !== 3)) : setShowOnly(showOnly.concat([3]))
          }}>
          <Progress
            type="circle"
            percent={typeof amdinRating === 'number' ? amdinRating : 0}
            width={50} strokeColor={'#4C84F2'}
            format={() =>
              <div className="ratingPercentLabelWrapper">
                <span className="ratingPercentLabel">{typeof amdinRating === 'number' ? amdinRating : '?'}</span>
                <span className="ratingPercentSing">{typeof amdinRating === 'number' ? '%' : null}</span>
              </div>} />
          <h4 className="label">Админ</h4>
        </div>
        <div
          className={`ratingWrapper ${(showOnly.indexOf(4) > -1) ? 'active' : ''}`}
          onClick={() => {
            showOnly.indexOf(4) > -1 ?
              setShowOnly(showOnly.filter(item => item !== 4)) :
              setShowOnly(showOnly.concat([4]))
          }}>
          <Progress
            type="circle"
            percent={typeof kitchenRating === 'number' ? kitchenRating : 0}
            width={50}
            strokeColor={'#F26A4C'}
            format={() =>
              <div className="ratingPercentLabelWrapper">
                <span className="ratingPercentLabel">{typeof kitchenRating === 'number' ? kitchenRating : '?'}</span>
                <span className="ratingPercentSing">{typeof kitchenRating === 'number' ? '%' : null}</span>
              </div>} />
          <h4 className="label">Кухня</h4>
        </div>
        <div className={`ratingWrapper ${(showOnly.indexOf(5) > -1) ? 'active' : ''}`}
          onClick={() => {
            showOnly.indexOf(5) > -1 ? setShowOnly(showOnly.filter(item => item !== 5)) : setShowOnly(showOnly.concat([5]))
          }}>
          <Progress
            type="circle"
            percent={typeof sanpinRating === 'number' ? sanpinRating : 0}
            width={50} strokeColor={'#fff566'}
            format={() =>
              <div className="ratingPercentLabelWrapper">
                <span className="ratingPercentLabel">{sanpinRating}</span>
                <span className="ratingPercentSing">{typeof sanpinRating === 'number' ? '%' : null}</span>
              </div>} />
          <h4 className="label">СанПиН</h4>
        </div>
        <div className={`ratingWrapper ${(showOnly.indexOf(6) > -1) ? 'active' : ''}`}
          onClick={() => {
            showOnly.indexOf(6) > -1 ?
              setShowOnly(showOnly.filter(item => item !== 6)) :
              setShowOnly(showOnly.concat([6]))
          }}>
          <Progress
            type="circle"
            percent={typeof barRating === 'number' ? barRating : 0}
            width={50}
            strokeColor={'#4cf2a8'}
            format={() =>
              <div className="ratingPercentLabelWrapper">
                <span className="ratingPercentLabel">{barRating}</span>
                <span className="ratingPercentSing">{typeof barRating === 'number' ? '%' : null}</span>
              </div>} />
          <h4 className="label">Бар</h4>
        </div>
      </div>}
      <div className="checksContainer">
        {
          !loading &&
          data &&
          data.statisticsByRange &&
          data.statisticsByRange.map(check => {
            if (showOnly.indexOf(parseInt(check.task_id)) !== -1) return (
              <Check
                key={check.id}
                check={check}
                controls={{ isHidden: true }} />); else return null
          })}
      </div>
    </div>
  );
}

export default ChecklistResult;
