import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import stringToHslColor from '../../../assets/js/stringToHslColor'

// styles
import './device-card.scoped.less';

// utils
import { verifyPermissions } from '../../../assets/js/security'

const DeviceCard = ({ device }) => {
  const navigate = useNavigate();
  const [zoomFactor, setZoomFactor] = useState(1);

  useEffect(() => {
    if (device.iotDevice && device.iotDevice.servicesMap && device.iotDevice.servicesMap.has('change-zoom')) {
      let currentZoomStateState = device.iotDevice.servicesMap.get('change-zoom')
        .getState()
        .then(state => setZoomFactor(Math.round10(state, -1)))
        .catch(e => console.log(e));
      device.iotDevice.servicesMap.get('change-zoom').on('state-changed', state => {
        setZoomFactor(Math.round10(state, -1))
      });
    }
  }, [device.iotDevice, setZoomFactor]);


  return (
    <div
      className="device-card"
      key={'deviceCard' + device.id}>
      <h3
        className="name"
        onClick={e =>
          // navigate('/iot-devices/' + device.id)
          navigate('/qrscanner/link-iot/' + device.id)
        }
      >{device.name}<i className="fa fa-qrcode" aria-hidden="true"></i></h3>
      <span
        className={'status' + (device.iotDevice ? ' online' : ' offline')}
        onClick={() => {
          if (device.iotDevice)
            device.iotDevice.node.send('NEW_VERSION_AVAILABLE', {
              encryptedKey: `PdglxizEjvhhARXhLI5rokTmMJI+U58wGweufs/KsbTFG8vPGF3dILiD3l/cY6CXVVLaCqfq2Ua5n4Fmi5ew9vDqJpNJS2mAlgZfOys908nmwak2kEf7r+ha/a/J6Sd2aU/r8D1pIlSbvnLrHsRFRgoV2sCRDenx9OR6YmE8wFd6zq+hCMOR4aPngS6Cvzobgfd+/IMzdPm7N6+nKCMRuj7erVh60IWHziulgvuHmTl3h/1NzKZqb0fk7Sq6pyG6hxG3kANb2+u9jIbR6jzEA/8wXd0XM5mXD/JXVxoxb/bb8Y4xU9/LU8DUY1gfwOwfgMPo3uAN68CR4M6jRupNmoDpyLHFPSUqgSQ8K2Nyqe60IjbWogS00wu/L+ua3nt9BQGrf4vz0DG8GhcuV8HKicgHp9XNKEMALkXlTDzRoDZODEqs0JeNa6Q3aWSR6OgMVoSS89HLOFt9qL/FzrJPjbJQAZ0dunktjo4K7VdnJGfuPsa9rrg0jMmTeVE6zPMu4GgKFcvLyAj3TVnOE7Cet5jyEX721ieFBoxL49GGq8BVMDES97Tt+2tpadohv+23kGx9kQblVWw+RzMPEfKk+9fEXm171bxN07VwVvrvXxZ4ydenRVlNlG1DRRKeOJt3uCp1waTLq6cmY965sv++eBf4odgq5+ilJll38yFPyLo=`,
              url: 'https://upgrade.koreana.link:4000/lightback-pos_20_01_2022v1.tar.gz.aes',
              version: '1.1.248n'
            })
        }}>
        {device.iotDevice ? 'P2P' : 'offline'}
        <p>
          {(device.iotDevice && device.iotDevice.version) ? device.iotDevice.version : ''}
        </p>
      </span>
      <div
        className="group-name"
        style={{ backgroundColor: stringToHslColor(device.branch_id, 50, 45) }}>
        <span>{device.group_name}</span>
      </div>
      <div className="group-role">
        <span>{device.role && device.role.split('/')[1]}</span>
      </div>
      <div className="log-statistics">
        <div className="logs">
          <span className="label">LOGS</span>
          <div className="counter">
            <span className="value">
              {
                nFormatter(device.metaReport.map(metaLog => metaLog.LOG)
                  .reduce((acc, num) => acc + num, 0))
              }
            </span>
            <span className="period">/ week</span>
          </div>
          <div className="heat-map">
            {
              device.metaReport.map((metaLog, i) => metaLog.LOG ? nFormatter(metaLog.LOG) : 0)
                .splice(0, 7)
                .map((value, i) =>
                  <div
                    className={`dot-value ${value === 0 ? 'ok' : ''}`}
                    key={'iot-network-log-' + device.id + '-' + i}><span>{value}</span></div>
                )
            }
          </div>
        </div>
        <div className="warnings">
          <span className="label">WARNINGS</span>
          <div className="counter">
            <span className="value">
              {
                nFormatter(device.metaReport.map(metaLog => metaLog.WARNING)
                  .reduce((acc, num) => acc + num, 0))
              }
            </span>
            <span className="period">/ week</span>
          </div>
          <div className="heat-map">
            {
              device.metaReport.map(metaLog => metaLog.WARNING ? nFormatter(metaLog.WARNING) : 0)
                .concat(Array.from({ length: 7 }, (_, i) => 0))
                .splice(0, 7)
                .map((value, i) =>
                  <div
                    className={`dot-value ${value === 0 ? 'ok' : ''}`}
                    key={'iot-network-warning-' + device.id + '-' + i}><span>{value}</span></div>
                )
            }
          </div>
        </div>
        <div className="errors">
          <span className="label">ERRORS</span>
          <div className="counter">
            <span className="value">
              {
                nFormatter(device.metaReport.map(metaLog => metaLog.ERROR)
                  .reduce((acc, num) => acc + num, 0))
              }
            </span>
            <span className="period">/ week</span>
          </div>
          <div className="heat-map">
            {
              device.metaReport.map(metaLog => metaLog.ERROR ? nFormatter(metaLog.ERROR) : 0)
                .concat(Array.from({ length: 7 }, (_, i) => 0))
                .splice(0, 7)
                .map((value, i) =>
                  <div
                    className={`dot-value ${value === 0 ? 'ok' : ''}`}
                    key={'iot-network-error-' + device.id + '-' + i}><span>{value}</span></div>
                )
            }
          </div>
        </div>
      </div>
      <div className="services-wrapper">
        <div className="services">
          {
            device.iotDevice && device.iotDevice.services && device.iotDevice.services
              .map(service => {
                if (service.name === 'change-zoom')
                  return (
                    <div
                      title={service.name}
                      key={'iot-network-service-' + device.id + '-' + service.name}
                      className={`service-btn ${service.name}`}
                      onClick={() => {
                        if (zoomFactor < 1.8)
                          service.setState(Math.round10((zoomFactor * 100 + 10) / 100, -2))
                            .catch(e => console.log(e));
                        else
                          service.setState(0.5)
                            .catch(e => console.log(e));

                      }}>
                      <span>x{zoomFactor}</span>
                    </div>
                  )
                return (
                  <div
                    title={service.name}
                    key={'iot-network-service-' + device.id + '-' + service.name}
                    className={`service-btn ${service.name}`}
                    onClick={() => service.call({x: 10, y: 15}).then(r => console.log(r)).catch(e => console.log(e))}>
                    {
                      service.name === 'reboot' ?
                        <i className="fas fa-redo"></i> :
                        service.name === 'monitor-dpms-switch' ? (
                          service.state ?
                            <i className="fad fa-desktop"></i> :
                            <i className="fas fa-desktop"></i> ) :
                            service.name === 'relaunch' ?
                              <i className="fas fa-sync"></i> :
                              service.name === 'restart-tty' ?
                                <i className="fab fa-hornbill"></i> :
                                service.name === 'mark' ?
                                  <i className="fas fa-map-marker-exclamation"></i> :
                                  <i className="fa fa-cogs" aria-hidden="true"></i>
                    }
                  </div>
                )
              })
          }
        </div>
        <div className="status-indicator">
          <span>
          {
            device.iotDevice ?
              (device.iotDevice && device.iotDevice.services && device.iotDevice.services.length < 1) ?
                'no services defined' :
                '' :
                'services are unavailable'
          }
          </span>
        </div>
      </div>
    </div>
  );
}

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
}

function decimalAdjust(type, value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

Math.round10 = function(value, exp) {
  return decimalAdjust('round', value, exp);
};

export default DeviceCard;
