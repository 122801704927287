const {
  NamedGroup
} = require('ataraxia');
const {
  net
} = require('./net');

const serviceGroup = new NamedGroup(net, 'service');
const testSserviceGroup = new NamedGroup(net, 'testService');

module.exports = {
  serviceGroup,
  testSserviceGroup
}
