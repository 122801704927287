import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useDrag } from '@use-gesture/react'
import { useSpring } from 'react-spring'
import { useRef } from 'react'
import { useSprings, animated } from '@react-spring/web'
import clamp from 'lodash.clamp'

// components
import Navbar from '../../components/Navbar';
import TopNavbarComponent from '../../components/TopNavbar';
import Home from '../Home';
import Tickets from '../Tickets';
import Ticket from '../Ticket';
import Tasks from '../Tasks';
import Account from '../Account';
import AboutApp from '../Account/AboutApp';
import Devices from '../Account/Devices';
import Courses from '../Courses';
import CCTV from '../CCTV';
import Marketing from '../Marketing';
import Workers from '../Workers';
import IotDevices from '../IotDevices';
import Network from '../Network';
import IoTDevice from '../IoTDevice';
import Profile from '../Profile';
import Checklist from '../Checklist';
import ChecklistResult from '../ChecklistResult';
import ChecklistReport from '../ChecklistReport';
import TaskTemplates from '../TaskTemplates';
import Branches from '../Branches';
import Branch from '../Branch';
import QRScanner from '../QRScanner';
import Course from '../Course';
import CCTVView from '../CCTVView';
import ChecklistEditor from '../ChecklistEditor';
import Overlay from '../../components/Overlay';
import POSStatistics from '../POSStatistics';
import Sandbox from '../Sandbox';
// import ScriptEditor from '../ScriptEditor';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { swipedState, topNavBarState } from "../../state";

// styles
import "../../assets/fonts/fontawesome-pro/css/all.css";
import '../../assets/css/connect-tailwind.css';
import './stage.less';
import './stage.scoped.less';

import themeSwitcher from '../../assets/js/themeSwitcher'

window.childDragging = false;

const UAParser = require('ua-parser-js').UAParser;
const uaParser = new UAParser();

function MainStage() {
  const setSwiped = useSetRecoilState(swipedState);
  const topNavBar = useRecoilValue(topNavBarState);
  const location = useLocation();
  const navbarWidth = 200;
  const [currentTheme, setCurrentTheme] = useState('dark');
  const [{ x }, set] = useSpring(() => ({
    x: 0,
    config: {
      mass: 1,
      tension: 500,
      friction: 35
    }
  }));
  const bind = useDrag(({ down, movement: [mx, my], last }) => {
    if (
      uaParser.getBrowser().name === 'Chrome' &&
      last &&
      Math.abs(mx / my) > 1
    ) {
      setTimeout(() => {
        let timer;
        window.addEventListener('touchend', event => {
          if (timer) {
            clearTimeout(timer);
          }
          timer = window.setTimeout(() => {
            event.target ?.dispatchEvent(new Event('click', {
              bubbles: true,
              cancelable: true,
            }));
          }, 0);
        }, { once: true });

        window.addEventListener('click', () => {
          if (timer) {
            clearTimeout(timer);
          }
        }, { once: true });
      }, 0);
    }
    if (!window.childDragging && window.innerWidth < 992) {
      if (down && mx > 0 && mx <= navbarWidth && Math.abs(mx / my) > 1) {
        set({ x: mx })
      } else if (mx >= navbarWidth / 2) {
        set({ x: navbarWidth })
        setSwiped(true);
      } else {
        set({ x: 0 })
        setSwiped(false);
      }
    }
  });
  useEffect(() => {
    set({ x: 0 });
    setSwiped(false);
  }, [location, set, setSwiped]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 992) {
        set({ x: 0 });
        setSwiped(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [set, setSwiped]);

  {/*onClick={() => { themeSwitcher.toggleTheme(currentTheme); setCurrentTheme(p => (p === 'dark' ? 'light' : 'dark')) }}*/ }
  return (
    <div className="stage dark:bg-base-900 bg-base-900">
      <animated.div {...bind()}
        style={{ x, touchAction: 'none', zIndex: 10 }}
        className={`
          stage-content
          bg-base-900
          ${topNavBar.hidenOnDesctop ? ' top-bar-hidden-on-desctops' : ''}`}>
        <TopNavbarComponent onSwipeButtonClick={swiped => set({ x: swiped ? navbarWidth : 0 })} />
        <Routes>
          <Route exact path='/home' element={<Home />}></Route>
          <Route exact path='/tickets' element={<Tickets />}></Route>
          <Route exact path='/ticket/:id' element={<Ticket />}></Route>
          <Route exact path='/courses' element={<Courses />}></Route>
          <Route exact path='/cctv' element={<CCTV />}></Route>
          <Route exact path='/account' element={<Account />}></Route>
          <Route exact path='/account/about-app' element={<AboutApp />}></Route>
          <Route exact path='/account/devices' element={<Devices />}></Route>
          <Route exact path='/qrscanner' element={<QRScanner />}></Route>
          <Route exact path='/qrscanner/link-iot/:iotDeviceId' element={<QRScanner />}></Route>
          <Route exact path='/workers' element={<Workers />}></Route>
          <Route exact path='/branches' element={<Branches />}></Route>
          <Route exact path='/branch/:id' element={<Branch />}></Route>
          <Route exact path='/profile/:id' element={<Profile />}></Route>
          <Route exact path='/checklist/result/:taskId' element={<ChecklistResult />}></Route>
          <Route exact path='/checklist/:taskId/report/:checkId' element={<ChecklistReport />}></Route>
          <Route exact path='/checklist/editor/:taskId' element={<ChecklistEditor />}></Route>
          <Route exact path='/checklist/task_templates' element={<TaskTemplates />}></Route>
          <Route exact path='/checklist/:taskId' element={<Checklist />}></Route>
          <Route exact path='/tasks/:branch_id' element={<Tasks />}></Route>
          <Route exact path='/cctv/:branch_id' element={<CCTVView />}></Route>
          <Route exact path='/courses/:course_id' element={<Course />}></Route>
          <Route exact path='/marketing' element={<Marketing />}></Route>
          <Route exact path='/sandbox' element={<Sandbox />}></Route>
          <Route exact path='/iot/devices' element={<IotDevices />}></Route>
          <Route exact path='/network' element={<Network />}></Route>
          <Route exact path='/iot-devices/:id' element={<IoTDevice />}></Route>
          <Route exact path='/pos-statistics' element={<POSStatistics />}></Route>
          <Route
            path="*"
            element={<Navigate to="/home" />}
          />
        </Routes>
      </animated.div>
      <Navbar />
      <Overlay />
    </div>
  );
}

const pages = [
  <MainStage />,
  <div className="stage chat">Chat</div>
  // <ScriptEditor />,
  // <div style={{ width: '100vw', height: '100vh', position: 'absolute', touchAction: 'none', backgroundImage: `url(https://s1.1zoom.ru/b5050/596/Evening_Forests_Mountains_Firewatch_Campo_Santo_549147_1920x1080.jpg)` }}></div>,
  // <div style={{ width: '100vw', height: '100vh', position: 'absolute', touchAction: 'none', backgroundImage: `url(https://i.pinimg.com/originals/71/9e/80/719e80760999b4c355a723224120eb07.png)` }}></div>,
  // <div style={{ width: '100vw', height: '100vh', position: 'absolute', touchAction: 'none', backgroundImage: `url(https://i.pinimg.com/originals/99/f7/6b/99f76b3de162688defe73255366828e2.jpg)` }}></div>,
  // <div style={{ width: '100vw', height: '100vh', position: 'absolute', touchAction: 'none', backgroundImage: `url(https://img5.goodfon.ru/original/1920x1080/4/f4/forest-trees-deer-animal-horns-grass-sky-stars-tilight-night.jpg)` }}></div>,
  // <div style={{ width: '100vw', height: '100vh', position: 'absolute', touchAction: 'none' }}></div>,
]

function Viewpager({pageIndex}) {
  const index = useRef(0)
  const width = window.innerWidth
  const [currentPageIndex, setCurrentPageIndex] = useState(pageIndex);

  useEffect(() => {
    setCurrentPageIndex(pageIndex)
  }, [pageIndex])

  const [props, api] = useSprings(pages.length, i => ({
    x: i * width,
    scale: 1,
    display: 'block',
  }))

  useEffect(() => {
      api.start(i => {
        index.current = clamp(currentPageIndex, 0, pages.length - 1)
        const x = (i - currentPageIndex) * width
        if (i < currentPageIndex - 1 || i > currentPageIndex + 1) return { display: 'none', x }

        const scale = 1
        return { x, scale, display: 'block' }
      });
  }, [currentPageIndex]);

  const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
    // active = true
    // if(!active)
    //   return setCurrentPageIndex(index.current);
    if (active && Math.abs(mx) > width / 2) {
      index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, pages.length - 1)
      cancel()
    }
    api.start(i => {
      const x = (i - index.current) * width + (active ? mx : 0)
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none', x }

      const scale = active ? 1 - Math.abs(mx) / width / 2 : 1
      setCurrentPageIndex(index.current);
      return { x, scale, display: 'block' }
    })
  })
  return (
    <div className="flex fill flex-row center" style={{width: '100%', overflowX: 'hidden'}}>
      {props.map(({ x, display, scale }, i) => (
        <animated.div key={i} style={{ display, x}}>
          <animated.div style={{ scale, width: '100%', height: '100vh' }} >
            {pages[i]}
          </ animated.div>
        </animated.div>
      ))}
      {/*<div
        className="bottom-navbar"
        style={{
          bottom: 0,
          left: 0,
          height: '70px',
          position: 'absolute',
          zIndex: 10
        }} {...bind()}>
        <div className="nav-btn" onClick={() => setCurrentPageIndex(0)}>Main</div>
        <div className="nav-btn" onClick={() => setCurrentPageIndex(1)}>Chat</div>
      </div>*/}
    </div>
  )
}

export default function Stage() {
  const [ pageIndex, setPageIndex ] = useState(0);
  return (
    <div className="flex fill center" style={{
      width: '100vw',
      backgroundSize: '250px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      position: 'absolute',
      backgroundColor: '#202022',
      backgroundImage: 'url("logo.svg")'
    }}>
      <Viewpager pageIndex={pageIndex} />
    </div>
  )
}


// export default Stage;
