import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as vis from 'vis';
import QRCodeStyling from "qr-code-styling";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { userState } from "../../state";
import { loader } from 'graphql.macro';
import { useQuery, useSubscription } from "react-apollo";
import stringToHslColor from '../../assets/js/stringToHslColor'
// import { VncScreen } from 'react-vnc';

import { ConnectToCluster, WarpIotDevice, WarpIotService } from '../../warp-iot';

// styles
import './network.scoped.less';


// components
import Loading from '../../components/Loading';
import DeviceCard from "./DeviceCard";

import * as ataraxia from '../../ataraxia';
import { serviceGroup } from '../../ataraxia/groups';
import { net } from '../../ataraxia/net';

const IOT_LOG_ADDED_SUBSCRIPTION = loader('../../graphql/IOT_LOG_ADDED_SUBSCRIPTION.gql');
const IOT_DEVICES_QUERY = loader('../../graphql/IOT_DEVICES_QUERY.gql');

const today = new Date();

const NetworkView = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const location = useLocation();
  const [devices, setDevices] = useState([]);
  const [devicesInfo, setDevicesInfo] = useState([]);
  const [iotDevices, setIotDevices] = useState([]);
  const iotDevicesQuery = useQuery(IOT_DEVICES_QUERY);
  const newIotLogSubscription = useSubscription(IOT_LOG_ADDED_SUBSCRIPTION);

  useEffect(() => {
    if (!iotDevicesQuery.loading && iotDevicesQuery.data) {
      setDevicesInfo(iotDevicesQuery.data.iotDevices);
    }
  }, [
      iotDevicesQuery.data,
      iotDevicesQuery.loading,
    ]);


  useEffect(() => {
    net.join()
      .then(() => {
        console.log('Network has been joined with id', net.networkId);
      })
      .catch(err => console.error(err));
    serviceGroup.join();

    let d = new Date()/1;

    const group = serviceGroup;


    const cluster = new ConnectToCluster(group);


    cluster.devices.forEach(device => {
      device.on('deviceDataUpdated', (deviceData, device) => {
        if(device.id) {
          setIotDevices(prevState => [
            ...prevState,
            device
          ])
        }
      })
      if(device.id) {
        setIotDevices(prevState => [
          ...prevState,
          device
        ])
      }
    })


    cluster.on('deviceConnected', async device => {


      if(device.id) {
        setIotDevices(prevState => [
          ...prevState,
          device
        ])

      }

      device.on('deviceDataUpdated', (deviceData, device) => {

        if(device.id) {
          setIotDevices(prevState => [
            ...prevState,
            device
          ])
        }
      })
    })

    cluster.on('deviceDisconnected', async device => {
      if(device.id) {
        setIotDevices(prevState => prevState.filter(prevDevice => prevDevice.id !== device.id))
      }
    });

    return () => {
      cluster.disconnect();
      serviceGroup.leave();
      // testSserviceGroup.leave();
      net.leave();
    };
  }, []);


  useEffect(() => {
    if(devicesInfo.length > 0 )
      setDevices(devicesInfo.map(deviceInfo => {

        const iotDevice = iotDevices.filter(iotDevice => iotDevice.id === deviceInfo.id)[0]

        return {
          ...deviceInfo,
          iotDevice: iotDevice || false
        }
      }))
    else if (iotDevices.length > 0 && devicesInfo.length < 1)
      setDevices(iotDevices.map(iotDevice => {

        const deviceInfo = devicesInfo.filter(deviceInfo => deviceInfo.id === iotDevice.id)[0]

        return {
          ...(deviceInfo ? ({...deviceInfo}) : ({})),
          iotDevice
        }
      }))
  }, [
    devicesInfo,
    iotDevices
  ])

  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Сеть',
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [location.pathname, setTopNavBar]);

  return (
    <div className="network scene">
      <div className="devices grid">
        {
          iotDevicesQuery.loading ? <Loading/> :
          [].concat(devices)
            .sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
            .map((device, deviceIndex) => device.id && (
              <DeviceCard device={device} />
            )
            )
        }
      </div>
    </div>
  );
}

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
}

export default NetworkView;
