const autoResize = (element) => {
  element.style.height = 'auto';
  if (element.scrollHeight + 3 < 200 || element.style.height > element.scrollHeight + 3) {
    element.style.height = element.scrollHeight + 3 + 'px';
  } else {
    element.style.height = '200px';
  }
}

module.exports = autoResize;
