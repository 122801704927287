import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

// styles
import './profile.scoped.less';
import "animate.css/animate.min.css";

const USER_QUERY = loader('../../graphql/USER_QUERY.gql');

const Profile = () => {
  let { id } = useParams();
  const { data } = useQuery(USER_QUERY, {
    variables: { id }
  });

  return (
    <div className="profile scene">
      {id === 'new' ?
        <div className="profile-card">
          <img className="profile-photo" src={`https://koreana.link:4000/public/user/new.jpeg`} alt="" />
          <div className="profile-info">
            <input
              placeholder="Имя Фамилия Отчество"
              className="profileInput"
            />
            <div className="user-branch-position">
              <div className="user-branch">
                <span>филиал</span>
              </div>
              <div className="user-position">
                <span>должность</span>
              </div>
            </div>
            <div className="user-contacts">
              <div className="phone"><i class="fas fa-phone"></i><input
                placeholder="+7 XXX XX-XX"
                className="profileInput phoneInput"
                type="phone"
              /></div>
              <div className="email"><i class="fas fa-at"></i>
                <input
                  placeholder="user@koreanalight.ru"
                  className="profileInput emailInput"
                  type="email"
                /></div>
            </div>
            <div className="edit-lock"><i class="fas fa-save"></i></div>
          </div>
          <div className="online-marker"></div>
        </div>
        :
        <div className="profile-card">
          <img
            className="profile-photo"
            src={`https://koreana.link:4000/public/user/${id}.jpg`}
            alt="" />
          <div className="profile-info">
            <p className="user-name">{
              data &&
              data.user &&
              data.user.first_name &&
              data.user.last_name &&
              (data.user.first_name + " " + data.user.last_name)
            }</p>
            <div className="user-branch-position">
              <div className="user-branch"><span>Ligovsky, 87</span></div>
              <div className="user-position">
                <span>{
                  data &&
                  data.user &&
                  data.user.position &&
                  (data.user.position)
                }</span></div>
            </div>
            <div className="user-contacts">
              <div className="phone">
                <i class="fas fa-phone"></i>
                <a href={`tel:${data && data.user && (data.user.phone)}`}>
                  {data && data.user && (data.user.phone)}</a></div>
              <div className="email">
                <i class="fas fa-at"></i>
                <a href={`mailto:${data && data.user && (data.user.email)}`}>
                  {data && data.user &&
                    (data.user.email)}</a>
              </div>
            </div>
            <div className="edit-lock"><i class="fas fa-lock"></i></div>
          </div>
          <div className="online-marker"></div>
        </div>
      }
    </div>
  );
}

export default Profile;
