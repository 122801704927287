import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import * as config from "./config.js";
import { useQuery } from "react-apollo";
import { loader } from 'graphql.macro';
import * as cookie from 'cookie';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { useSetRecoilState } from 'recoil';
import { userState, appInfoState } from "./state";

// components
import Stage from './views/Stage'
import Login from './views/Login'


// querys
const USER_QUERY = loader('./graphql/USER_QUERY.gql');
const APP_INFO_QUERY = loader('./graphql/APP_INFO_QUERY.gql');

window.logOut = undefined;

// declare global {
//   interface Window { logOut: any; };
// };

const App = () => {
  const setUser = useSetRecoilState(userState);
  const setAppInfo = useSetRecoilState(appInfoState);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem(window.screenID ? window.screenID + '_' + 'token' : 'token') ? true : false);
  const userQuery = useQuery(USER_QUERY);
  const appInfoQuery = useQuery(APP_INFO_QUERY);
  useEffect(() => {
    window.logOut = () => {
      localStorage.removeItem(window.screenID ? window.screenID + '_' + 'token' : 'token');
      localStorage.removeItem(window.screenID ? window.screenID + '_' + 'permissions' : 'permissions');
      setLoggedIn(false);
    };
  }, []);


  useEffect(() => {

    if (!appInfoQuery.data || appInfoQuery.error)
      return;

    let allCookies = cookie.parse(document.cookie);
    const appType = appInfoQuery.data.appInfo.type;
    console.log("allCookies['app-type']", allCookies['app-type']);
    console.log("appType", appType);
    if(!allCookies['app-type'] && appType === 'lightback') {
      document.cookie = cookie.serialize("app-type", 'lightback')
    }
    else if (allCookies['app-type'] !== appType) {
      document.cookie = cookie.serialize("app-type", appType)
      serviceWorkerRegistration.update();
    }

    const currentAppVersion = localStorage.getItem('app-version');
    if (!currentAppVersion && currentAppVersion !== appInfoQuery.data.appInfo.version) {
      localStorage.setItem('app-version', appInfoQuery.data.appInfo.version);
      localStorage.setItem('app-relese-name', appInfoQuery.data.appInfo.releseName);
      localStorage.setItem('app-relese-link', appInfoQuery.data.appInfo.link);
      setAppInfo(prevState => ({
        version: appInfoQuery.data.appInfo.version,
        versionName: appInfoQuery.data.appInfo.releseName,
        link: appInfoQuery.data.appInfo.link
      }));
    } else if (currentAppVersion !== appInfoQuery.data.appInfo.version) {
      localStorage.setItem('app-version', appInfoQuery.data.appInfo.version);
      localStorage.setItem('app-relese-name', appInfoQuery.data.appInfo.releseName);
      localStorage.setItem('app-relese-link', appInfoQuery.data.appInfo.link);
      setAppInfo(prevState => ({
        version: appInfoQuery.data.appInfo.version,
        versionName: appInfoQuery.data.appInfo.releseName,
        link: appInfoQuery.data.appInfo.link
      }));
      serviceWorkerRegistration.update();
    }
  }, [
    appInfoQuery.data,
    appInfoQuery.error,
    setAppInfo,
  ]);

  if (window.swUpdateReady) {
    window.swUpdateReady = false;
    window.stop();
    window.location.reload();
  }

  useEffect(() => {
    if(userQuery.error || !userQuery.data || !userQuery.data.user)
      return;

    if (userQuery.data.user.blocked !== true)
      setUser(prevState => ({
        ...prevState,
        ...userQuery.data.user,
        position_id: parseInt(userQuery.data.user.position_id),
        profilePhotoPath: `https://koreana.link:${config.port}/public/user/${userQuery.data.user.id}.jpg?${Date.now()}`,
        permissions: userQuery.data.user.permissions
          .map(permission => parseInt(permission))
      }));
    else if(userQuery.data.user.blocked === true) {
      localStorage.removeItem(window.screenID ? window.screenID + '_' + 'token' : 'token');
      setLoggedIn(false);
    }
  }, [
    userQuery.error,
    userQuery.data,
    setUser,
  ]);

  useEffect(() => {
    loggedIn && userQuery.refetch()
  },
    [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Router>
      {loggedIn ? <Stage /> : <Login setLoggedIn={setLoggedIn} />}
    </Router>
  );
}

export default App;
