const CryptoJS = require("crypto-js");
const seed = 'Alyssa';

// String to hsl
function stringToHslColor(str, s, l) {
  let sha1Str = CryptoJS.SHA1(JSON.stringify(str + seed)).toString();
  let hash = 0;
  for (let i = 0; i < sha1Str.length; i++) {
    hash = sha1Str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

module.exports = stringToHslColor;
