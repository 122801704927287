import React, { useState, useEffect } from 'react';
import { useQuery, useSubscription } from "react-apollo";
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

// styles
import './tasks.scoped.less';

// components
import TaskCard from '../../components/TaskCard'



// querys
const TASKS_FOR_NOW_QUERY = loader('../../graphql/TASKS_FOR_NOW_QUERY.gql');
const NEW_TASKS_SUBSCRIPTION = loader('../../graphql/NEW_TASKS_SUBSCRIPTION.gql');
const BRANCH_QUERY = loader('../../graphql/BRANCH_QUERY.gql');

const Tasks = () => {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const [tasks, setTasks] = useState([]);
  const [unixNow, setUnixNow] = useState(moment().unix());
  const { branch_id } = useParams();
  const branch = useQuery(BRANCH_QUERY, {
    variables: {
      id: branch_id
    }
  });
  const { data, loading } = useQuery(TASKS_FOR_NOW_QUERY, {
    variables: {
      branch_id: parseInt(branch_id)
    }
  });
  const newTasksSubscription = useSubscription(NEW_TASKS_SUBSCRIPTION, {
    variables: {
      branchID: parseInt(branch_id)
    }
  });

  useEffect(() => {
    if (!(
      !branch.loading &&
      branch.data &&
      branch.data.branch &&
      branch.data.branch.address
    ))
      return;

    // configurating topNavBar
    setTopNavBar(prevState => ({
      ...prevState,
      title: branch.data.branch.address
    }));

  return () => setTopNavBar(prevState => ({
    ...prevState,
    title: ''
  }));
}, [branch.data, branch.loading, setTopNavBar]);


useEffect(() => {
  if (!loading && data) {
    setTasks(data.tasksForNow);
  }
  if (!newTasksSubscription.loading && newTasksSubscription.data) {
    setTasks(tasks.concat(newTasksSubscription.data.newTasks));
  }
}, [
    data,
    newTasksSubscription.data,
    newTasksSubscription.loading,
    loading,
    tasks
  ]);

useEffect(() => {
  let timer = setInterval(() => {
    let newUnixNow = moment().unix();
    setUnixNow(newUnixNow);

  }, 1000);
  return () => clearTimeout(timer);
}, []);

return (
  <div className="tasks scene mx-lim">
    <div className="tasks">
      {tasks.map(task => unixNow < task.hide &&
        <TaskCard
          key={task.id}
          task={task}
          unixNow={unixNow} />
      )}
    </div>
  </div>
);
}

export default Tasks;
