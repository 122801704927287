import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { DatePicker, AutoComplete, Tag, TimePicker } from 'antd';

import { useSetRecoilState } from 'recoil';
import { topNavBarState, overlayState } from "../../state";

// utils
import stringToHslColor from '../../assets/js/stringToHslColor'
import autoResize from '../../assets/js/autoResize'

// styles
import './checklist-editor.scoped.less';


// components
import TaskCard from '../../components/TaskCard'
import Check from '../../components/Check'
const { RangePicker } = DatePicker;

// queries
const CHECK_TEMPLATE_QUERY = loader('../../graphql/CHECK_TEMPLATE_QUERY.gql');
const EDIT_TASK_TEMPLATE_MUTATION = loader('../../graphql/EDIT_TASK_TEMPLATE_MUTATION.gql');
const TASKS_FOR_NOW_QUERY = loader('../../graphql/TASKS_FOR_NOW_QUERY.gql');
const TASKS_TEMPLATES_QUERY = loader('../../graphql/TASKS_TEMPLATES_QUERY.gql');
const DELETE_TASK_TEMPLATE = loader('../../graphql/DELETE_TASK_TEMPLATE.gql');
const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');
const DEPARTMENTS_QUERY = loader('../../graphql/DEPARTMENTS_QUERY.gql');
const POSITIONS_QUERY = loader('../../graphql/POSITIONS_QUERY.gql');
const TICKETS_QUERY = loader('../../graphql/TICKETS_QUERY.gql');

function ChecklistEditor() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const setOverlay = useSetRecoilState(overlayState);
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [getTask, { data }] = useLazyQuery(CHECK_TEMPLATE_QUERY, {
    fetchPolicy: "network-only"
  });
  const [mutateTask] = useMutation(EDIT_TASK_TEMPLATE_MUTATION, {
    onCompleted() {
      navigate(-1);
    }
  });
  const [deleteTaskTemplate] = useMutation(DELETE_TASK_TEMPLATE, {
    onCompleted() {
      navigate(-1);
    }
  }
  );
  const branchesQuery = useQuery(BRANCHES_QUERY);
  const positionsQuery = useQuery(POSITIONS_QUERY);
  const departmentsQuery = useQuery(DEPARTMENTS_QUERY);
  const [departments, setDepartments] = useState([]);
  const [unixNow, setUnixNow] = useState(moment().unix());
  const [selectedTile, setSelectedTile] = useState(-1);
  const [checklistIsCurrent, setChecklistIsCurrent] = useState(false);
  const [availableBranches, setAvailableBranches] = useState([]);
  const [availablePositions, setAvailablePositions] = useState([]);
  const [branchAddressSearchValue, setBranchAddressSearchValue] = useState('');
  const [positionSearchValue, setPositionSearchValue] = useState('');
  const [departmentSearchValue, setDepartmentSearchValue] = useState([]);
  const [branches, setBranches] = useState([]);
  const [positions, setPositions] = useState([]);
  const taskDescription = useRef(null);
  const checkDescription = useRef(null);
  const [task, setTask] = useState({
    title: '',
    description: '',
    isCorrect: false,
    branch_id: [],
    // available: [moment().unix(), moment().unix() + 3153610800, 86400, 0, 86399], // global start, global end, interval, delay from start of day, task length
    start: moment().startOf('day').unix(),
    end: moment().endOf('day').unix() + 3153610800,
    start_of_day: 0,
    end_of_day: 24*60*60-1,
    interval: 24*60*60,
    position_id: []
  });
  const [checks, setChecks] = useState([{
    title: '',
    description: '',
    isCorrect: false,
    department_id: null,
    type: 1
  }]);

  useEffect(()=> {
    console.log(data);

  }, [data])

  useEffect(() => {
    if (branchesQuery.data) {
      let newBranches = branchesQuery.data.branches.map(branch => ({
        ...branch,
        id: parseInt(branch.id)
      }));
      setBranches(newBranches);
      setAvailableBranches(branchesQuery.data.branches.map(branch => ({
        id: branch.id,
        value: branch.address
      })));
    }
  }, [branchesQuery]);

  useEffect(() => {
    if (departmentsQuery.data) {
      setDepartments(departmentsQuery.data.departments.map(department => ({
        id: department.id,
        value: department.name
      })));
    }
  }, [departmentsQuery]);

  useEffect(() => {
    if (data && branchesQuery.data) {
      setAvailableBranches(prevState => prevState.filter(prevState =>
        data.taskTemplate.branch_id === null ?
          true :
          !data.taskTemplate.branch_id.includes(prevState.id)));
    }
  }, [branchesQuery, data]);


  useEffect(() => {
    if (positionsQuery.data) {

      setPositions(positionsQuery.data.positions);

      setAvailablePositions(positionsQuery.data.positions.map(position => ({
        id: position.id,
        value: position.name
      })));

    }
  }, [positionsQuery]);

  useEffect(() => {
    if (data && positionsQuery.data) {
      setAvailablePositions(prevState =>
        prevState.filter(prevState =>
          data.taskTemplate.position_id === null ?
            true :
            !data.taskTemplate.position_id.includes(prevState.id)));
    }
  }, [positionsQuery, data]);


  useEffect(() => {
    if (data) {
      if (data.taskTemplate) {
        setTask({
          title: data.taskTemplate.title,
          description: data.taskTemplate.description !== null ? data.taskTemplate.description : '',
          id: data.taskTemplate.id,
          isCorrect: true,
          branch_id: data.taskTemplate.branch_id !== null ?
            data.taskTemplate.branch_id.map(branchIDItem => parseInt(branchIDItem)) : [],
          position_id: data.taskTemplate.position_id !== null ? data.taskTemplate.position_id : [],
          start: data.taskTemplate.start,
          end: data.taskTemplate.end,
          start_of_day: data.taskTemplate.start_of_day,
          end_of_day: data.taskTemplate.end_of_day,
          interval: data.taskTemplate.interval,
        });
        if (data.taskTemplate.checkTemplates) {
          let initChecks = data.taskTemplate.checkTemplates.map(x => ({
            ...x,
            title: x.title,
            description: (x.description !== null && x.description !== undefined) ?
              x.description : '',
            isCorrect: true,
            department_id: parseInt(x.department_id)
          }));
          setChecks(initChecks);
        }
      }
    }

  }, [data]);

  useEffect(() => {
    if (data && departmentsQuery.data) {
      setDepartmentSearchValue(data.taskTemplate.checkTemplates.map(check =>
        departmentsQuery.data.departments.filter(department =>
          department.id === check.department_id)[0].name));
    }
  }, [data, departmentsQuery]);

  useEffect(() => {
    if (!isNaN(Number(taskId))) {
      getTask({
        variables: {
          id: taskId
        }
      });
    }


    const timer = setTimeout(() => {
      setUnixNow(moment().unix());
    }, 1000);
    return () => clearTimeout(timer);
  }, [getTask, taskId]);

  const validate = (index, { value, department_id }) => {
    console.log({ value, department_id });

    if (index > -1)
      if (value.length > 0 && department_id !== null) {
        setChecks(prevState => {
          let newState = [...prevState];
          newState[index].isCorrect = true;
          return newState;
        });
      }
      else
        setChecks(prevState => {
          let newState = [...prevState];
          newState[index].isCorrect = false;
          return newState;
        });
    else
      if (value.length > 0)
        setTask(prevState => ({ ...prevState, isCorrect: true }));
      else
        setTask(prevState => ({ ...prevState, isCorrect: false }));
  }

  useEffect(() => {
    const validateChecklist = () =>
      task.isCorrect && checks.find(check => check.isCorrect === false) === undefined;
    setChecklistIsCurrent(validateChecklist());
  }, [checks, task]);

  useEffect(() => {
    if (selectedTile < 0)
      autoResize(taskDescription.current);
    else
      autoResize(checkDescription.current);
  }, [selectedTile]);

  function addBranchOnMatch(str) {
    branches.map(branch => {
      if (branch.address === str) {
        setBranchAddressSearchValue('');
        setAvailableBranches(prevState => prevState.filter(branchItem =>
          branchItem.value !== str));
        setTask(prevState => ({
          ...prevState,
          branch_id: [...prevState.branch_id, branch.id]
        }));
      }
      return null;
    });
  }
  function addPositionOnMatch(str) {
    positions.map(position => {
      if (position.name === str) {
        setPositionSearchValue('');
        setAvailablePositions(prevState =>
          prevState.filter(position => position.value !== str));
        setTask(prevState => ({
          ...prevState,
          position_id: [...prevState.position_id, position.id]
        }));
      }
      return null;
    });
  }
  function setDepartmentOnMatch(str, selectedTile) {
    departments.map(async department => {
      if (department.value === str) {
        await setChecks(prevState => {
          let newChecks = [...prevState];
          newChecks[selectedTile].department_id = department.id;
          return newChecks;
        });
        validate(selectedTile, {
          value: checks[selectedTile].title,
          department_id: department.id
        });
      }
      return null;
    });
  }

  useEffect(() => {
    let releaseTask = () => {
      if (checklistIsCurrent) {
        mutateTask({
          variables: {
            ...{
              id: task.id === undefined ? -1 : task.id,
              title: task.title,
              description: task.description,
              branch_id: task.branch_id,
              start: task.start,
              end: task.end,
              start_of_day: task.start_of_day,
              end_of_day: task.end_of_day,
              interval: task.interval,
              position_id: task.position_id,
            },
            checks: [...checks.map(check => {
              let releseCheck = {
                id: check.id,
                title: check.title,
                description: check.description,
                department_id: check.department_id,
                type: parseInt(check.type)
              }; return ({ ...releseCheck })
            })]
          },
          refetchQueries: [
            { query: TASKS_TEMPLATES_QUERY },
            { query: BRANCHES_QUERY },
            ...task.branch_id.map(branchID =>
              ({
                query: TASKS_FOR_NOW_QUERY,
                variables: { branch_id: branchID }
              })),
          ]
        });
      }
      else
        console.log('Checklist data is wrong');
    }


    setTopNavBar(prevState => ({
      ...prevState,
      ...prevState.topNavBar,
      title: 'Редактор чек-листа',
      actions: [
        ...prevState.actions,
        {
          type: 'button',
          content: <i className="fas fa-trash"></i>,
          onClick: () =>
            setOverlay({
              type: 'modal-window',
              data: {
                title: 'Предупреждение',
                description: `Удаление шаблона чеклиста повлечет за собой каскадную потерю данных о пройденных проверках.\n Вы действительно хотите продолжить?`,
                actions: [
                  {
                    title: 'Да',
                    callback: close => {
                      deleteTaskTemplate({
                        variables: { id: taskId },
                        refetchQueries: [
                          { query: TASKS_TEMPLATES_QUERY },
                          { query: BRANCHES_QUERY },
                          { query: TICKETS_QUERY },
                          ...task.branch_id.map(branchID => ({
                            query: TASKS_FOR_NOW_QUERY,
                            variables: { branch_id: branchID }
                          })),
                        ],
                      })
                      close();
                    }
                  },
                  {
                    title: 'Нет',
                    callback: close => { }
                  }
                ],
                visible: true
              }
            }),
        },
        {
          type: 'button',
          onClick: () => releaseTask(),
          inActive: !checklistIsCurrent,
          content: <i className="fas fa-save"></i>
        },
      ]
    }));

    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
      actions: []
    }));
  }, [
      setTopNavBar,
      checklistIsCurrent,
      data,
      deleteTaskTemplate,
      task.branch_id,
      taskId,
      checks,
      mutateTask,
      task.start,
      task.end,
      task.start_of_day,
      task.end_of_day,
      task.interval,
      task.description,
      task.id,
      task.position_id,
      task.title,
      setOverlay]);

  return (
    <div className="check-editor scene">
      <div className="container">
        <div className="editor">
          {selectedTile < 0 ? (
            <div className="form">
              <section>
                <span>Название чек-листа</span>
                <input
                  value={task.title}
                  onChange={e => {
                    e.target.value.length < 32 &&
                      setTask(prevState => ({
                        ...prevState,
                        title: e.target.value
                      }));
                    validate(selectedTile, { value: e.target.value })
                  }}
                  type="text" />
              </section>
              <section>
                <span>Описание чек-листа</span>
                <textarea
                  value={task.description}
                  ref={taskDescription}
                  onChange={e => {
                    e.target.value.length < 128 &&
                      setTask(prevState => ({
                        ...prevState,
                        description: e.target.value
                      }));
                    autoResize(e.target)
                  }}></textarea>
              </section>
              <div className="interval">
                <span>Начало и окончание действия</span>
                <RangePicker
                  inputReadOnly={true}
                  className="datePicker"
                  format={"DD.MM.YYYY"}
                  ranges={{
                    'Сегодня': [moment().startOf('day'), moment().endOf('day')],
                    'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  onChange={newInterval => newInterval !== null &&
                    setTask(prevState => {
                      let start = moment(newInterval[0]).startOf('day').unix() || prevState.start;
                      let end = moment(newInterval[1]).endOf('day').unix() || prevState.end;

                      return { ...prevState, start, end };
                    })
                  }
                  value={[
                    moment.unix(task.start),
                    moment.unix(task.end)]} />
              </div>
              <section className="interval">
                <span>Доступность в течении дня</span>
                <TimePicker.RangePicker
                  onChange={newInterval => newInterval !== null &&
                    setTask(prevState => {
                      let startOfDay = moment(newInterval[0]).unix() || prevState.start_of_day;
                      let endOfDay = moment(newInterval[1]).unix() || prevState.end_of_day;

                      return { ...prevState, start_of_day: startOfDay, end_of_day: endOfDay };
                    })
                  }
                  value={[
                    moment.unix(task.start_of_day).utc(),
                    moment.unix(task.end_of_day).utc()
                  ]} />
              </section>
              <section className="branch-selector">
                <span>Для должностей</span>
                <div className="selected-branches">
                  {positions.map((position) =>
                    task.position_id.includes(position.id) && (
                      <Tag
                        key={position.id}
                        color={stringToHslColor(position.id, 50, 50)}
                        onClick={(e) => {
                          setTask(prevState => {
                            let newState = { ...prevState };
                            newState.position_id = newState.position_id.filter(positionIDItem => positionIDItem !== position.id);
                            return newState
                          })
                          setAvailablePositions(prevState => [...prevState, { id: position.id, value: position.name }]);
                        }}>{position.name}</Tag>
                    ))}
                </div>
                <AutoComplete
                  className="position-selector-input"
                  options={availablePositions}
                  placeholder="Должности"
                  value={positionSearchValue}
                  onChange={str => {
                    setPositionSearchValue(str);
                    addPositionOnMatch(str);
                  }}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </section>
              <section className="branch-selector">
                <span>Доступен в ресторанах</span>
                <div className="selected-branches">
                  {branches.map((branch) => task.branch_id.includes(branch.id) && (
                    <Tag key={branch.id} color={stringToHslColor(branch.id, 50, 50)} onClick={(e) => {
                      setTask(prevState => {
                        let newState = { ...prevState };
                        newState.branch_id = newState.branch_id.filter(branchIDItem => branchIDItem !== branch.id);
                        return newState
                      })
                      setAvailableBranches(prevState => [
                        ...prevState, { value: branch.address }
                      ]);
                    }}>{branch.address}</Tag>
                  ))}
                </div>
                <AutoComplete
                  className="branch-selector-input"
                  options={availableBranches}
                  placeholder="Рестораны"
                  value={branchAddressSearchValue}
                  onChange={str => {
                    setBranchAddressSearchValue(str);
                    addBranchOnMatch(str);
                  }}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </section>
            </div>
          ) :
            (
              <div className="form">
                <section>
                  <span>Название чека</span>
                  <input
                    value={checks[selectedTile].title}
                    onChange={e => {
                      e.target.value.length < 64 &&
                        setChecks(prevState => {
                          let newState = [...prevState];
                          newState[selectedTile].title = e.target.value;
                          return newState
                        });
                      validate(selectedTile, {
                        value: e.target.value,
                        department_id: checks[selectedTile].department_id
                      })
                    }}
                    type="text" />
                </section>
                <section>
                  <span>Описание чека</span>
                  <textarea
                    ref={checkDescription}
                    value={checks[selectedTile].description}
                    onChange={e => {
                      e.target.value.length <= 256 &&
                        setChecks(prevState => {
                          let newState = [...prevState];
                          newState[selectedTile].description = e.target.value;
                          return newState
                        });
                      autoResize(e.target)
                    }}></textarea>
                </section>
                <section>
                  <span>Тип</span>
                  <select
                    value={checks[selectedTile].type}
                    onChange={e => {
                      setChecks(prevState => {
                        let newState = [...prevState];
                        newState[selectedTile].type = parseInt(e.target.value);
                        return newState
                      });
                    }}>
                    <option value="1" selected>да-нет</option>
                    <option value="5">от 0 до 5</option>
                  </select>
                </section>
                <section>
                  <span>Подразделение</span>
                  <AutoComplete
                    className="department-selector-input"
                    options={departments}
                    placeholder="Подразделение"
                    value={departmentSearchValue[selectedTile] || ""}
                    onChange={str => {
                      setDepartmentSearchValue(prevState => {
                        let newState = [...prevState];
                        newState[selectedTile] = str;
                        return newState;
                      });
                      setDepartmentOnMatch(str, selectedTile);
                    }}
                    filterOption={(inputValue, option) =>
                      departmentSearchValue[selectedTile] ?
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : true
                    }
                  />
                </section>
              </div>
            )}
        </div>
        <div className="selector">
          <TaskCard
            key={-1}
            selected={selectedTile < 0}
            isCorrect={task.isCorrect}
            onClick={() => setSelectedTile(-1)}
            template
            task={{
              title: task.title.trim() || "Название чек-листа",
              description: task.description.trim() || "Описание чек-листа",
              show: 1636073567,
              hide: 1636078567
            }}
            unixNow={unixNow} />
          {checks.map((check, index) => (
            <Check onClose={checks.length > 1 ? () => {
              setChecks(prevState => prevState.filter((checkElem, checkIndex) => checkIndex !== index));
            } : undefined}
              key={index}
              selected={selectedTile === index}
              isCorrect={check.isCorrect}
              onClick={e => e.target.className.includes('check-delete') ? setSelectedTile(-1) : setSelectedTile(index)}
              template
              check={{
                title: check.title.trim() || "Название чека",
                description: check.description.trim() || "Описание чека"
              }} />
          ))}
          {checks.length <= 32 && (
            <div
              className="add-check"
              onClick={() => setChecks(prevState => {
                let newState = [...prevState];
                newState.push({
                  title: '',
                  description: '',
                  isCorrect: false,
                  department_id: null, // checks[checks.length - 1].department_id
                  type: 1
                });
                return newState;
              }, setSelectedTile(checks.length))}>
              <i className="fas fa-plus"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChecklistEditor;
