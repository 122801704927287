import {
  useRef,
  useEffect,
  useState
} from 'react'
import {
  useSprings,
  animated
} from '@react-spring/web'
import {
  useDrag
} from '@use-gesture/react'
import clamp from 'lodash.clamp'

import styles from './styles.module.css'

const {
  ConnectToCluster,
  WarpIotDevice,
  WarpIotService
} = require('../../warp-iot');

import { net } from '../../ataraxia/net';
import { testSserviceGroup } from '../../ataraxia/groups';

export default function Sandbox() {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    net.join();
    const group = testSserviceGroup;
    group.join();


    const cluster = new ConnectToCluster(group);


    // const readline = require('readline').createInterface({
      //   input: process.stdin,
      //   output: process.stdout,
      // });


      // cluster.devices.forEach(devices => {
        //   devices.on('', data)
        // });

        cluster.on('deviceConnected', async device => {
          setDevices(cluster.devices);

          device.on('deviceDataUpdated', () => {
            console.log('cluster.devices', cluster.devices);
            setDevices([...cluster.devices]);
          })

          console.log('device', device.name, 'was connected');
          console.log('device', device.name, 'have', device.services.length, 'services');

        });

        cluster.on('deviceDisconnected', device => {
          console.log(cluster.devices);

          setDevices(cluster.devices);


          console.log('device', device.name, 'was disconnected');
        })

        return () => {
          group.leave();
        }
  }, []);
  return (
    <div className="scene" >
      {devices.map(device =>
        <div>
          <ul><span>{device.name}</span></ul>
          {device.services.map(service =>
            <li onClick={() => service.call({x: 1, y: 2}).then(r => console.log('r', r)).catch(e => console.log('e', e))}><span>{service.name}</span></li>
          )}
        </div>
      )}
    </div>
  )
}
