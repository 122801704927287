import React from 'react';
import { NavLink } from 'react-router-dom';

// styles
import './camera-card.scoped.less';

function CameraCard({ camID }) {
  return (
    <NavLink className="CameraCard"
      to={"/cctv/cam/" + camID} >
      <img
        className="cam-preview"
        src="https://koreana.link:4000/public/demo/cam-0-prev.jpg" alt="" />
      <div className="cam-card-overlay">
        <p className="cam-name">Hall-2</p>
        <p className="cam-live-status">
          <span>live</span>
          <i class="fas fa-signal-stream"></i>
        </p>
      </div>
    </NavLink>
  );
}
export default CameraCard;
