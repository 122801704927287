import React, { useState, useEffect } from 'react';

// styles
import './selectors.scoped.less';

// utils
import stringToHslColor from '../../assets/js/stringToHslColor'

function Selectors({
  onChange = () => { },
  openTicketsSelected,
  selectedBranch,
  branches
}) {

  const [state, setState] = useState({
    open: true,
    branch: 0
  });

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <section className="selectors">
      <div className="complited-selector">
        <div
          className={state.open ? "selected" : ""}
          onClick={() => setState(prevState => ({ ...prevState, open: true }))}
        ><span>Открытые</span></div>
        <div
          className={!state.open ? "selected" : ""}
          onClick={() => setState(prevState => ({ ...prevState, open: false }))}
        ><span>Закрытые</span></div>
      </div>
      {
        branches && branches.length > 1 && (
          <ul className="branches-selector">
            <li
              onMouseDown={mouseDown}
              onMouseUp={e => mouseUp(e, setState(prevState => ({ ...prevState, branch: 0 })))}
            >Все</li>
            {branches.map(branch => (
              <li
                onMouseDown={mouseDown}
                onMouseUp={e => mouseUp(e, setState(prevState => ({ ...prevState, branch: branch.id })))}
                style={branch.id === state.branch ?
                  {
                    backgroundColor: stringToHslColor(branch.id, 50, 50)
                  } : {
                    paddingBottom: "6px",
                    borderBottom: "4px solid " + stringToHslColor(branch.id, 50, 50),
                  }}>{branch.name}</li>
            ))}
          </ul>
        )}
    </section>
  );

}


// prevent click on horisontal slide
let down = false;
let position = {
  x: 0,
  y: 0
};

const mouseDown = e => {
  down = true;
  position.x = e.clientX
  position.y = e.clientY
}
const mouseUp = (e, callBack = () => { }) => {
  down = false;
  if (Math.abs(e.clientX - position.x) < 10) {
    callBack()
  }
}

export default Selectors;
