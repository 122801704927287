import React, { useState, useRef, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import Dropzone from 'react-dropzone'
import moment from 'moment'

// configs
import * as config from "../../config.js";

// utils
import getBase64 from '../../assets/js/getBase64'
import autoResize from '../../assets/js/autoResize'
import stringToHslColor from '../../assets/js/stringToHslColor'

// styles
import './ticket-card.scoped.less';

// components
import AttachedPhoto from '../../components/AttachedPhoto';
import Select from '../../components/Select';

// query`s
const ADD_TICKET_MUTATION = loader('../../graphql/ADD_TICKET_MUTATION.gql');
const TICKETS_QUERY = loader('../../graphql/TICKETS_QUERY.gql');
const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');
const DEPARTMENTS_QUERY = loader('../../graphql/DEPARTMENTS_QUERY.gql');

function TicketCard({
  ticket,
  onClick,
  controlls = false,
  onDoneStepBtnClick = () => { },
  onAddTicketStepBtnClick = () => { },
  user,
  ticketIsNew = false,
}) {
  const navigate = useNavigate();
  const [shortTicketSteps, setShortTicketSteps] = useState([]);
  const [stepDoneBtnLocked, setStepDoneBtnLocked] = useState(false);
  const [newTicketStep, setNewTicketStep] = useState({
    title: '',
    description: ''
  });

  const [progressLineHeight, setProgressLineHeight] = useState(0);
  const vstepsContRef = useRef();
  const [addStepSwitch, setAddStepSwitch] = useState(false);
  const [attachedImagesObject, setAttachedImagesObject] = useState([]);
  const [newTicket, setNewTicket] = useState({
    title: '',
    description: '',
    department_id: undefined,
    branch_id: undefined,
    priority: 1
  });




  const [addTicketMutation, ticketMutationData] = useMutation(ADD_TICKET_MUTATION, {
    refetchQueries: [
      { query: TICKETS_QUERY }
    ]
  });
  const branchesQuery = useQuery(BRANCHES_QUERY);
  const departmentsQuery = useQuery(DEPARTMENTS_QUERY);

  // navidate back when ticket is added
  useEffect(() => {
    if (!ticketMutationData.loading && ticketMutationData.data)
      navigate(-1)
  }, [
      ticketMutationData.data,
      ticketMutationData.loading,
      navigate
    ]);

  useEffect(() => {
    if (ticketIsNew) {
      if (!branchesQuery.loading &&
        branchesQuery.data &&
        branchesQuery.data.branches &&
        branchesQuery.data.branches.length === 1) {
        setNewTicket(prevState => ({
          ...prevState,
          branch_id: branchesQuery.data.branches[0].id
        }));
      }
    }
  }, [branchesQuery.data, branchesQuery.error, branchesQuery.loading]);


  // dropped image handler
  const handleDrop = acceptedFiles => {
    if ((attachedImagesObject.length + acceptedFiles.length) <= 6) { // max 6 photos
      let newAttachedImagesObject = [...attachedImagesObject];
      acceptedFiles.map(async file => {
        await getBase64(file).then(base64 => {
          newAttachedImagesObject.push({
            file: file,
            imagePreviewUrl: base64
          });
        });
        setAttachedImagesObject([...newAttachedImagesObject]);
      });
    }
  }

  const validateAndsubmitNewTicket = () => {
    if (!( // validate ticket data
      newTicket.title.length <= 32 &&
      newTicket.title.length > 0 &&

      newTicket.description.length <= 1024 &&

      !isNaN(parseInt(newTicket.department_id)) &&
      !isNaN(parseInt(newTicket.branch_id)) &&
      !isNaN(parseInt(newTicket.priority)))
    ) return;

    // add ticket
    addTicketMutation({
      variables: {
        title: newTicket.title,
        description: newTicket.description,
        department_id: newTicket.department_id,
        branch_id: newTicket.branch_id,
        priority: newTicket.priority,
        ...(attachedImagesObject.length > 0 ?
          { photos: attachedImagesObject.map(o => o.file) } : {})
      }
    });
  }

  // toggle card controlls
  useEffect(() => {
    if (controlls && ticket) {
      setStepDoneBtnLocked(false);
    } else if (!controlls && ticket.steps) {
      setShortTicketSteps(ticket.steps
        .filter((step, index) =>
          (index <= 2 || index > ticket.steps.length - 2) && step));
    }
  }, [
      ticket,
      controlls
    ]);


  // update steps progress line
  useEffect(() => {
    if (controlls &&
      ticket &&
      !ticket.status &&
      ticket.steps &&
      ticket.steps.length > 0 &&
      vstepsContRef.current &&
      vstepsContRef.current.children.length > 0) {
      let progressLineHeightTmp = 0;
      const stepsElemArray = Array.from(vstepsContRef.current.children);
      const stepsElemDoneLength = Array.from(vstepsContRef.current.children)
        .filter((stepEl, index) => ticket.steps[index].done).length;
      stepsElemArray.forEach((stepEl, index) => {
        if (ticket.steps[index].done) {
          if (stepsElemArray.length === stepsElemDoneLength &&
            index === stepsElemDoneLength - 1) {
            progressLineHeightTmp += stepEl.offsetHeight
          }
          else if (index === stepsElemDoneLength - 1) {
            progressLineHeightTmp += stepEl.offsetHeight / 2
          }
          else
            progressLineHeightTmp += stepEl.offsetHeight
          if (index !== 0) {
            progressLineHeightTmp += 10; // add grid-gap
          }
        }
      });
      setProgressLineHeight(progressLineHeightTmp);
    }
  }, [vstepsContRef,
      ticket,
      controlls]);

  if (ticketIsNew)
    return (
      <Dropzone onDrop={handleDrop}
        accept="image/jpeg,image/png">
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              onClick: event =>
                !(event.target.classList.contains("attachInput")) &&
                event.stopPropagation()
            })}
            className={`ticket controls`}
            onClick={() => onClick && onClick()}
            key={`ticket-new`}>
            <div className="ticket-info">
              <section className="ticket-info-section">
                <p>Заголовок</p>
                <input
                  className=""
                  value={newTicket.title}
                  placeholder="Например: сафит не исправен"
                  type="text"
                  onChange={e => {
                    e.target.value.length <= 32 &&
                      setNewTicket(prevState => ({
                        ...prevState, title: e.target.value
                      }))
                  }} />
              </section>
              <section className="ticket-info-section">
                <p>Описание</p>
                <textarea
                  name=""
                  value={newTicket.description}
                  placeholder="Например: мерцает 3ий софит над баром"
                  onChange={e => {
                    autoResize(e.target);
                    e.target.value.length <= 1024 &&
                      setNewTicket(prevState => ({
                        ...prevState, description: e.target.value
                      }))
                  }
                  }
                ></textarea>
              </section>
              <section className="ticket-info-section">
                <p>Подразделение</p>
                <Select options={(!branchesQuery.loading && branchesQuery.data && branchesQuery.data.branches) ?
                  branchesQuery.data.branches.map((branch, index) => ({
                    title: branch.name,
                    value: branch.id
                  })) : []}
                  placeholder={"-- выберите подразделение --"}
                  value={newTicket.branch_id || undefined}
                  onChange={value => {
                    setNewTicket(prevState => ({
                      ...prevState, branch_id: parseInt(value)
                    }))
                  }} />
              </section>
              <section className="ticket-info-section">
                <p>Отдел</p>
                <Select options={(!departmentsQuery.loading &&
                  departmentsQuery.data) ?
                  departmentsQuery.data.departments.map((department, index) => ({
                    title: department.name,
                    value: department.id
                  })) : []}
                  placeholder={"-- выберите отдел --"}
                  onChange={value => {
                    setNewTicket(prevState => ({ ...prevState, department_id: parseInt(value) }))
                  }} />
              </section>
              <section className="ticket-info-section">
                <p>Приоритетность</p>
                <Select options={[
                  { title: "низкая", value: 1 },
                  { title: "средняя", value: 2 },
                  { title: "высокая", value: 3 },
                  { title: "критическая", value: 4 },
                ]} onChange={value => setNewTicket(prevState => ({
                  ...prevState, priority: parseInt(value)
                }))} />
              </section>
              {attachedImagesObject.length > 0 && (
                <section className="ticket-info-section">
                  <p className={`
                    title${attachedImagesObject.length > 0 ? " active" : ""}
                  `}>Прикреплённые изображения</p>
                  <div className="attached-images">
                    {attachedImagesObject.map(({ imagePreviewUrl }, index) =>
                      <div key={"reportImage" + index}
                        onClick={() => {
                          setAttachedImagesObject(attachedImagesObject
                            .slice(0, index)
                            .concat(attachedImagesObject
                              .slice(index + 1, attachedImagesObject.length)))
                        }}>
                        <AttachedPhoto
                          imagePreviewUrl={imagePreviewUrl}
                          closeSing />
                      </div>
                    )}
                  </div>
                </section>
              )}
            </div>
            <div className="submit-container">
              <div className="action-buttons">
                <div className="attach-input-button attachInput">
                  <input {...getInputProps()}
                    style={{ display: "block", opacity: 0 }} />
                  <i className="far fa-camera attachInput"></i>
                </div>
                <div
                  className="submit"
                  onClick={() =>
                    !ticketMutationData.loading &&
                    validateAndsubmitNewTicket()}>
                  <span>отправить</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    )
  else if (ticket)
    return (
      <div
        className={`ticket ${controlls ? ' controls' : ''}`}
        onClick={() => onClick && onClick()}
        key={`ticket-${ticket.id}`}>
        <div className="ticket-info">
          <p>
            {ticket.title}
          </p>
          <p>
            {ticket.description}
          </p>
          {controlls && branchesQuery.data && (
            <div>
              <p
                className="branch"
                style={{ background: stringToHslColor(1, 50, 50) }}
              >{branchesQuery.data.branches
                .filter(branch => ticket.branch_id === branch.id)[0].address}</p>
            </div>
          )}
        </div>
        {!controlls ? (
          <div className="status">
            <div className="line">
              <div
                className="step-markers"
                style={{
                  gridTemplateColumns: `repeat(auto-fill, ${shortTicketSteps.length === 0 ?
                    100 : 100 / shortTicketSteps.length}%)`
                }}>
                {
                  shortTicketSteps.map(step => (
                    <div
                      className={'marker' + (step.done ? ' active' : '')}
                      key={'ticket' + ticket.id + '-step-' + step.id + '-marker'}></div>
                  ))}
              </div>
              <div className="progress" style={{
                width: `${
                  ticket.status === true ?
                    100 : shortTicketSteps.length > 0 ?
                      shortTicketSteps[shortTicketSteps.length - 1].done ? 100 :
                        ((shortTicketSteps.filter(step => (step.done)).length > 0 ?
                          ticket.status === true ?
                            100 : shortTicketSteps
                              .filter(step => (step.done)).length - 0.5 : 0) / (shortTicketSteps.length) * 100) : 0
                  }%`
              }}></div>
            </div>
            <div className="steps" style={{
              gridTemplateColumns: `repeat(auto-fill, ${shortTicketSteps.length === 0 ? 100 : 100 / shortTicketSteps.length}%)`
            }}>
              {shortTicketSteps.map((step, index) => (
                <span key={'ticket-' + step.id}>{step.title}</span>
              ))}
              {shortTicketSteps.length === 0 && (
                <span className="dummy">Тут пока нет событий 💤</span>
              )}
            </div>
          </div>
        ) :
          <div className="status-controlls">
            {ticket.attached + ticket.report_attached > 0 && (
              <div className="attached-images-container">
                <span>Прикреплённые изображения</span>
                <div className="attached-images">
                  {
                    (() => {
                      let photos = [];
                      for (let i = 0; i < ticket.attached; i++)
                        photos.push(<AttachedPhoto key={"AttachedPhoto" + ticket.id + "_" + i}
                          imagePreviewUrl={`https://koreana.link:${config.port}/public/tickets/${ticket.id}_${i}.jpg`}
                          viewable={true} />)
                      return photos
                    }
                    )()
                  }
                  {
                    (() => {
                      let photos = [];
                      for (let i = 0; i < ticket.report_attached; i++)
                        photos.push(
                          <AttachedPhoto
                            key={"AttachedPhoto" + ticket.id + "_report_" + i}
                            imagePreviewUrl={`https://koreana.link:${config.port}/public/reports/${ticket.report_id}_${i}.jpg`}
                            viewable={true} />)
                      return photos
                    }
                    )()
                  }
                </div>
              </div>
            )}
            <div className="add-step">
              <div className="line-deco-part"></div>
              <div className="history-header">
                <span className="title">События</span>
                <div
                  className="add-step-button"
                  onClick={() => setAddStepSwitch(prevStete => !prevStete)}>
                  <i className={`fas fa-plus${addStepSwitch ? ' active' : ''}`}></i>
                  <span>{addStepSwitch ? "отменить" : "добавить"} событие</span>
                </div>
              </div>
              {addStepSwitch && (
                <div className="add-step-form">
                  <div className="title">
                    <span className="label">Название</span>
                    <input
                      onChange={e => {
                        if (e.target.value.length <= 32) {
                          setNewTicketStep(prevStete => ({
                            ...prevStete,
                            title: e.target.value
                          }))
                        }
                      }}
                      value={newTicketStep.title}
                      type="text" />
                  </div>
                  <div className="description">
                    <span className="label">Описание</span>
                    <textarea
                      onChange={e => {
                        if (e.target.value.length <= 1024) {
                          setNewTicketStep(prevStete => ({
                            ...prevStete,
                            description: e.target.value
                          }))
                        }
                        autoResize(e.target);
                      }
                      }
                      value={newTicketStep.description}
                      type="text" />
                  </div>
                  <div className="save-buttons-container">
                    {newTicketStep.title.length > 0 ?
                      <div
                        className={`save-button ${stepDoneBtnLocked ? " inActive" : ""}`}
                        onClick={() => {
                          if (newTicketStep.title.length > 0 && !stepDoneBtnLocked) {
                            setStepDoneBtnLocked(true);
                            onAddTicketStepBtnClick(newTicketStep);
                            setAddStepSwitch(prevStete => !prevStete);
                            setNewTicketStep({
                              title: '',
                              description: ''
                            });
                          }
                        }}>
                        <span>сохранить</span>
                      </div> :
                      <div
                        className={`accept-button ${stepDoneBtnLocked ? " inActive" : ""}`}
                        key="ticket-step-btn-1"
                        onClick={() => {
                          if (!stepDoneBtnLocked) {
                            setStepDoneBtnLocked(true);
                            onAddTicketStepBtnClick({ title: 'в работе' });
                            setAddStepSwitch(prevStete => !prevStete);
                            setNewTicketStep({
                              title: '',
                              description: ''
                            });
                          }
                        }}>
                        <span>в работе</span>
                      </div>
                    }
                  </div>
                  <div className="step-pluse-icon-container">
                    <div className="pluse-icon-wrapper">
                      <i className="fas fa-plus"></i>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="ticket-history">
              <div className="line">
                <div
                  className="progress"
                  style={{
                    height: `${ticket.status ?
                      '100%' :
                      progressLineHeight + 'px'}`
                  }}></div>
              </div>
              <div className="steps-container" ref={vstepsContRef}>
                {ticket.steps && ticket.steps.map((step, index) => (
                  <div
                    className="step"
                    key={'ticket_step_' + step.id}>
                    <div className="step-marker-container">
                      <div className={'marker' + (step.done ? ' active' : '')}></div>
                    </div>
                    <div className="title-and-date">
                      <span className="step-name">{step.title}</span>
                      <span className="step-date">{step.created && moment.unix(step.created).format('HH:mm DD.MM.YY')}</span>
                    </div>
                    {step.description !== '' && (
                      <p className="step-description">{step.description}</p>
                    )}
                    <p className="step-creator-name">
                      {step.creator_first_name + " " + step.creator_last_name}
                    </p>
                    <section className="images-and-done">
                      <div
                        onClick={e => {
                          onDoneStepBtnClick(step.id);
                        }}
                        className={`done-button ${(
                          !step.done &&
                          !stepDoneBtnLocked &&
                          ticket.steps.filter(step => step.done).length === index && (
                            ticket.creator_id === user.id ||
                            ticket.department_id === user.department_id)
                        ) && " active"}`}><span>готово</span></div>
                    </section>
                  </div>
                ))}
                {(!ticket.steps || ticket.steps.length === 0) && (
                  <span className="dummy">Тут пока нет событий 💤</span>
                )}
              </div>
            </div>
          </div>
        }
        <div className="id-and-date">
          <div className="id">
            <span>{`#${ticket.id}`}</span>
          </div>
          {ticket.created &&
            <div className="date">
              <span className="font-bold">{moment.unix(ticket.created).format('DD.MM.YY')}</span>
            </div>
          }
        </div>
      </div>
    );
  else return null;
}

// setOverlay({
//   type: 'modal-window',
//   data: {
//     title: 'Предупреждение',
//     description: `Удаление шаблона чеклиста повлечет за собой каскадную потерю данных о пройденных проверках.\n Вы действительно хотите продолжить?`,
//     actions: [
//       {
//         title: 'Да',
//         callback: close => {
//           deleteTaskTemplate({
//             variables: { id: taskId },
//             refetchQueries: [
//               { query: TASKS_TEMPLATES_QUERY },
//               { query: BRANCHES_QUERY },
//               { query: TICKETS_QUERY },
//               ...task.branch_id.map(branchID => ({
//                 query: TASKS_FOR_NOW_QUERY,
//                 variables: { branch_id: branchID }
//               })),
//             ],
//           })
//           close();
//         }
//       },
//       {
//         title: 'Нет',
//         callback: close => { }
//       }
//     ],
//     visible: true
//   }
// })


export default TicketCard;
