import React, { useState, useEffect } from 'react';

// styles
import './options-list.scoped.less';

const RadioSing = ({ state}) => (
  <div className={`select-sing ${state ? "active" : ""}`}></div>
)

function OptionsList({ options, onChange = () => { }, selectedValue, close = () =>{} }) {
  const [currentOptions, setCurrentOptions] = useState(
    [...options].sort((a, b) => a.value > b.value ? 1 : 0));

  useEffect(() => {
    if (selectedValue) {
      setCurrentOptions(prevState =>
        prevState.map(option =>
          option.value === selectedValue ? ({ ...option, status: true }) : option))
    }
  }, [options, setCurrentOptions, selectedValue]);

  return (
    <div className="center-containter">
      <ul className="overlay-options-list options-list">
        {
          currentOptions
            .map(option =>
              <li
                key={'overlay-select-option-' + option.value}
                className="option" onClick={() => {
                  onChange(option.value);
                  close();
                  // setSelectState(prevState => [...prevState.map(prevOpt => ({
                  //   ...prevOpt,
                  //   status: false
                  // }))]);
                  // setSelectState(prevState => [...prevState.filter(prevOpt =>
                  //   prevOpt.value !== option.value), { ...option, status: !option.status }])
                }}>
                <span>{option.title}</span>
                <RadioSing state={option.status} />
              </li>
            )
        }
      </ul>
    </div>
  );
}

export default OptionsList;
