import React from 'react';

// styles
import './marketing.scoped.less';

function Marketing() {
  return (
    <div className="Marketing scene">
      <div className="sceneTitle">
        <span>Маркетинг</span>
      </div>
    </div>
  );
}

export default Marketing;
