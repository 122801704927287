const util = require("util");
const EventEmitter = require("events");

// let interfaceID = 0;

function RPCInterface(group, config) {
  // interfaceID++;
  this.config = {
    prefix: 'wrpc',
    ...(config ? ({...config}) : ({})),
    resolver: {
      timeout: 10000,
      ...((config && config.resolver) ? ({...config.resolver}) : ({}))
    }
  }
  this.rpcPeers = new Map();
  this.pendingRequests = new Map();
  this.groupSubscriptions = [];
  this.disconnect = () => {
    this.groupSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.groupSubscriptions = [];
    this.removeAllListeners();
    this.pendingRequests.forEach((request, key) => {
      request.abort('Aborted');
      this.pendingRequests.delete(key);
    });
    delete this.pendingRequests;
  }

  this.requestHandlers = new Map();

  this.addMethod = (methodName, handler) => {
    this.requestHandlers.set(methodName, (props, rpcPeer, source)  => {
      return handler(props, rpcPeer, source)
    })
  }


  this.appendpendingRequests = (id, pendingRequest) => {
    let requestTimeoutTimer = setTimeout(() => {
      this.pendingRequests.get(id).reject('Timeout', this.config.resolver.timeout, 'ms')
      this.pendingRequests.delete(id);
    }, this.config.resolver.timeout);
    this.pendingRequests.set(id, {
      ...pendingRequest,
      abort: (reson) => {
        clearTimeout(requestTimeoutTimer);
        pendingRequest.reject(reson);
        this.pendingRequests.delete(id);
      }
    });

  }


  this.lastActionID = 0;
  this.incrementLastActionID = () => {
    if(this.lastActionID === Number.MAX_SAFE_INTEGER)
      this.lastActionID = 0;
    else
      this.lastActionID++;
    return this.lastActionID;
  }
  this.setLastActionID = (id) => {
    this.lastActionID === id;
  }
  this.getLastActionID = () => {
    return this.lastActionID;
  }

  this.broadcast = function (methodName, props, timeout) {
    return new Promise((resolve, reject) => {
      group
        .broadcast(this.config.prefix+':broadcast:' + methodName, {props})
        .then(() => resolve())
        .catch(e => reject(e));

    });
  }

  let onNodeAvailableHandler = (node) => {
    let rpcPeer = new RPCPeer(
      node, {
        incrementLastActionID: this.incrementLastActionID,
        getLastActionID: this.getLastActionID,
        setLastActionID: this.setLastActionID,
        appendpendingRequests: this.appendpendingRequests},
      this.config);
    this.rpcPeers.set(node.id, {node, rpcPeer})
    this.emit('peerAvalible', rpcPeer)
  }

  if(group.shared)
    group.shared.nodes.forEach(node => {
      onNodeAvailableHandler(node)
    });


  let ddd = new Date()/1;

  this.groupSubscriptions.push(group.onNodeAvailable(node => onNodeAvailableHandler(node)));
  this.groupSubscriptions.push(group.onNodeUnavailable(node => {
    this.emit('peerUnavalible', this.rpcPeers.get(node.id));
    this.pendingRequests.forEach((request, key) => {
      if(request.node.id === node.id)
        request.abort('Aborted');
    });
    this.rpcPeers.delete(node.id);
  }));
  this.groupSubscriptions.push(group.onMessage(({type, data, source}) => {
    console.log(type);
    console.log(data);
    if(!type.includes(this.config.prefix))
      return;

    if(type.includes(this.config.prefix+':request:')) {
      let methodName = type.split(this.config.prefix+':request:')[1];

      if(this.requestHandlers.has(methodName))
        this.requestHandlers.get(methodName)(data.props, this.rpcPeers.get(source.id).rpcPeer, source)
        .then(result =>
          source.send(this.config.prefix+':response', {
            actionID: data.actionID,
            result
          }).catch(e => console.log(e))
        ).catch(error =>
          source.send(this.config.prefix+':response', {
            actionID: data.actionID,
            error
          }).catch(e => console.log(e))
        )
      else
        source.send(this.config.prefix+':response', {
          actionID: data.actionID,
          error: 'Method not found'
        }).catch(e => console.log(e))


    } else if(type === this.config.prefix+':response') {
      if(this.pendingRequests.has(data.actionID)) {
        if(data.error) {
          this.pendingRequests.get(data.actionID).reject(data.error)
          return;
        }

        this.pendingRequests.get(data.actionID).resolve(data.result)
      }
    } else if(type.includes(this.config.prefix+':broadcast:')) {
      let methodName = type.split(this.config.prefix+':broadcast:')[1];

      if(this.requestHandlers.has(methodName))
        this.requestHandlers.get(methodName)(data.props, this.rpcPeers.get(source.id).rpcPeer, source).then(result =>
          source.send(this.config.prefix+':response', {
            actionID: data.actionID,
            result
          }).catch(e => console.log(e))
        ).catch(error =>
          source.send(this.config.prefix+':response', {
            actionID: data.actionID,
            error
          }).catch(e => console.log(e))
        )
      else
        source.send(this.config.prefix+':response', {
          actionID: data.actionID,
          error: 'Method not found'
        }).catch(e => console.log(e))


    }
  }));
}

function RPCPeer(node, {appendpendingRequests, incrementLastActionID, getLastActionID, setLastActionID}, config) {
  this.handle = () => {}
  this.request = function (methodName, props, timeout) {
    return new Promise((resolve, reject) => {
      let actionID = incrementLastActionID();
      appendpendingRequests(actionID, {
        timeout: config.resolver.timeout,
        resolve,
        reject,
        node
      })
      node
        .send(config.prefix+':request:' + methodName, {actionID, props})
        .then(() => {})
        .catch(e => reject(e));

    });
  }
}

util.inherits(RPCPeer, RPCInterface);
util.inherits(RPCInterface, EventEmitter);

export { RPCInterface }
