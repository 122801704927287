import React from 'react';
import { useSetRecoilState } from 'recoil';
import { overlayState } from "../../state";

// styles
import './attached-photo.scoped.less';

const AttachedPhoto = ({
  imagePreviewUrl,
  closeSing,
  src,
  onClick = () => { },
  viewable
}) => {
  const setOverlay = useSetRecoilState(overlayState);
  return (
    <div className="reportImage" onClick={() => {
      viewable && setOverlay({
        type: 'modal-view',
        data: {
          src: src ? src : imagePreviewUrl,
        }
      });
      onClick();
    }}>
      <img src={imagePreviewUrl} alt="" />
      {
        closeSing &&
        <div className="removeImage">
          <i className="fas fa-times"></i>
        </div>
      }
    </div>
  )
};

export default AttachedPhoto;
