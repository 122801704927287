import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

// components
import Check from '../../components/Check'

// styles
import './checklist.scoped.less';

const CHECK_QUERY = loader('../../graphql/CHECK_QUERY.gql');

function Checklist() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  let { taskId } = useParams();
  const { data, loading } = useQuery(CHECK_QUERY, {
    variables: {
      id: taskId
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (!(
      !loading &&
      data &&
      data.task &&
      data.task.title
    )) return;

      // configurating topNavBar
      setTopNavBar(prevState => ({
        ...prevState,
        title: data.task.title
      }));

    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: ''
    }));
  }, [data, loading, setTopNavBar]);


  return (
    <div className="checklist scene mx-lim">
      <div className="checks">
        {
          !loading &&
          data &&
          data.task &&
          data.task.checks &&
          data.task.checks.length !== 0 &&
          data.task.checks.map(check => (
            <Check
              key={check.id}
              check={check} />
          ))}
      </div>
    </div>
  );
}

export default Checklist;
