import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';

// styles
import './cctv.scoped.less';

// components
import SelectCCTVBranchCard from '../../components/SelectCCTVBranchCard';

// querys
const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');

function CCTV() {
  const branchesQuery = useQuery(BRANCHES_QUERY);
  return (
    <div className="CCTV scene">
      <div className="sceneTitle">
        <span>Видеонаблюдение</span>
      </div>
      <div className="grid">
        {
          !branchesQuery.loading &&
          branchesQuery.data !== undefined &&
          branchesQuery.data.branches && (
            <>
              {branchesQuery.data.branches.map(branch => (
                <SelectCCTVBranchCard
                  key={"SelectCCTVBranchCard" + branch.id}
                  branch={branch}
                  linkTo={"/cctv/" + branch.id} />
              ))}
            </>
          )}
      </div>
    </div>
  );
}

export default CCTV;
