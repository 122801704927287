import React from 'react';
import { NavLink } from 'react-router-dom';
import sha1 from "js-sha1";

// styles
import './select-cctv-branch-card.scoped.less';

// String to hsl
function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}

function SelectCCTVBranchCard({ branch, linkTo }) {
  return (
    <NavLink
      to={linkTo !== undefined ? linkTo : "/cctv/" + branch.id}
      className="branch-card">
      <div className="select-cctv-branch-card">
        <p className="address">{branch.address}</p>
        <div
          className="color-label"
          style={{
            backgroundColor: stringToHslColor(sha1(branch.address), 70, 50)
          }}></div>
      </div>
    </NavLink>
  );
}

export default SelectCCTVBranchCard;
