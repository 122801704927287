import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import { useSpring, animated } from 'react-spring'
import { useDrag } from '@use-gesture/react'

// utils
import getBase64 from '../../assets/js/getBase64'
import autoResize from '../../assets/js/autoResize'

// components
import AttachedPhoto from '../AttachedPhoto';
import Select from '../Select';

// styles
import './check.scoped.less'

// query`s
const UPDATE_CHECK_STATUS_MUTATION = loader('../../graphql/UPDATE_CHECK_STATUS_MUTATION.gql');
const ADD_TICKET_MUTATION = loader('../../graphql/ADD_TICKET_MUTATION.gql');
const ADD_REPORT = loader('../../graphql/ADD_REPORT_MUTATION.gql');
const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');
const REPORTS_QUERY = loader('../../graphql/REPORTS_QUERY.gql');
const TICKETS_QUERY = loader('../../graphql/TICKETS_QUERY.gql');


const Check = ({
  check,
  controls,
  children,
  template = false,
  onClick,
  selected = false,
  isCorrect = false,
  onClose
}) => {
  const navigate = useNavigate();
  const [addTicketMutation, ticketMutationData] = useMutation(
    ADD_TICKET_MUTATION, {
      refetchQueries: [
        { query: TICKETS_QUERY }
      ]
    });
  const [addReport, { data, loading }] = useMutation(ADD_REPORT, {
    refetchQueries: [{ query: REPORTS_QUERY }]
  });
  if (!controls) {
    controls = {
      isHidden: false,
      report: true,
      feedback: false
    }
  }

  const [feedback, setFeedback] = useState('');
  const [updatecheckstatus] = useMutation(UPDATE_CHECK_STATUS_MUTATION, {
    refetchQueries: [{ query: BRANCHES_QUERY }]
  });
  const { taskId } = useParams();

  const [attachedImagesObject, setAttachedImagesObject] = useState([]);
  const handleDrop = acceptedFiles => {
    if ((attachedImagesObject.length + acceptedFiles.length) <= 6) { // max 6 photos
      let newAttachedImagesObject = [...attachedImagesObject];
      acceptedFiles.map(async file => {
        await getBase64(file).then(base64 => {
          newAttachedImagesObject.push({
            file: file,
            imagePreviewUrl: base64
          });
        });
        setAttachedImagesObject([...newAttachedImagesObject]);
      });
    }
  }

  const checkRef = useRef(null);
  const [checkStatus, setCheckStatus] = useState(check.status);
  const [newTicket, setNewTicket] = useState({
    title: '',
    description: '',
    department_id: parseInt(check.department_id),
    check_id: parseInt(check.id),
    check_template_id: parseInt(check.check_template),
    branch_id: check.branch_id,
    report_id: undefined,
    priority: 1
  });

  const [createTicketSwitch, setCreateTicketSwitch] = useState(false);
  const [{ x }, set] = useSpring(() => ({ x: 0 }))

  // Set the drag hook and define component movement based on gesture data
  const bind = useDrag(({ down, movement: [mx] }) => {
    if (!template && check.type > 1) {
      window.childDragging = down;
      setCheckStatus(Math.floor(-mx / (checkRef.current.offsetWidth - 150) * 5 >= 0 ?
        -mx / (checkRef.current.offsetWidth - 150) * 5 <= 5 ?
          -mx / (checkRef.current.offsetWidth - 150) * 5 : 5 : 0));
      set({
        x: down ? mx <= 0 ? mx > -(checkRef.current.offsetWidth - 40 - 35) ?
          mx : -(checkRef.current.offsetWidth - 40 - 35) : 0 : 0
      })
      if (!down) {
        updatecheckstatus({ variables: { status: checkStatus, id: check.id } });
      }
    }
  })

  // go back to checklist after report is sent
  useEffect(() => {
    if (createTicketSwitch) {
      if (!ticketMutationData.loading && ticketMutationData.data) {
        navigate(-1);
      }
    } else
      if (!loading && data) {
        navigate(-1);
      }
  }, [
      ticketMutationData.loading,
      ticketMutationData.data,
      navigate,
      createTicketSwitch,
      loading,
      data
    ]);
  useEffect(() => {
    if (!loading && data && createTicketSwitch) {
      addTicketMutation({
        variables: {
          title: newTicket.title,
          description: newTicket.description,
          department_id: newTicket.department_id,
          check_id: newTicket.check_id,
          branch_id: newTicket.branch_id,
          priority: newTicket.priority,
          check_template_id: newTicket.check_template_id
        }
      });
    }
  }, [
      loading,
      data,
      addTicketMutation,
      newTicket.check_id,
      newTicket.description,
      newTicket.title,
      newTicket.department_id,
      newTicket.branch_id,
      newTicket.check_template_id,
      newTicket.priority,
      createTicketSwitch
    ]);

  const changeCheckStatus = e => {
    if (!template && check.type > 1) {
      setCheckStatus(0);
      return;
    }
    setCheckStatus(prevState => prevState === 1 ? 0 : 1);
    updatecheckstatus({
      variables: {
        status: checkStatus === 1 ?
          0 : 1, id: check.id
      }
    });
  }

  const [uploadingStatus, setUploadingStatus] = useState(-1);
  let submitFeedback = () => {
    if (validated) {
      addReport({
        variables: {
          check_id: check.id,
          reason: feedback,
          ...(attachedImagesObject.length > 0 ?
            { photos: attachedImagesObject.map(o => o.file) } : {})
        },
        context: {
          fetchOptions: {
            onUploadProgress: progress => {
              setUploadingStatus(() => {
                return progress.loaded / progress.total * 100;
              })
            },
          }
        },
      });
    }
    return;
  }

  // validating form
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    setValidated(() => {
      return (
        // check report
        feedback.length > 0 && checkStatus !== null &&
        // ticket
        (
          !createTicketSwitch ||
          (
            newTicket.title.length > 0 &&
            typeof newTicket.department_id === 'number'
            && isFinite(newTicket.department_id) &&
            typeof newTicket.check_id === 'number'
            && isFinite(newTicket.check_id)
          )
        )
      )
    });
  }, [feedback, checkStatus, newTicket, createTicketSwitch]);

  return (
    <div
      className={`
        check
        ${selected ? " selected" : ""}
        ${template ? isCorrect ? " isCorrect" : " isIncorrect" : ""}
      `}
      ref={checkRef}
      onClick={onClick}>
      {template && onClose !== undefined &&
        <i className="check-delete fas fa-times" onClick={() => onClose()}></i>
      }
      <div className="contentWrapper">
        <div className="info">
          <p className="title">{check.title}</p>
          <p className="description">{check.description}</p>
        </div>
        {!template &&
          <div className="controls"
            style={{ display: controls.isHidden ? 'none' : 'block' }}>
            <animated.div {...bind()}
              style={{ x, touchAction: 'none' }}>
              <div
                className={`
                  control
                  setStatus
                  rating-${check.type > 1 ? "int" : "binary"}-${checkStatus}
                  ${template ? " disabled" : ""}
                `}
                onClick={e => changeCheckStatus(e)}
              >
                {checkStatus === null ? (
                  check.type > 1 ?
                    <div className="marker">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div> :
                    <div className="binary-marker"></div>
                ) :
                  <span>
                    {
                      check.type > 1 ? checkStatus : checkStatus ?
                        <i className="fa fa-check" aria-hidden="true"></i> :
                        <i className="fa fa-times" aria-hidden="true"></i>
                    }
                  </span>
                }
              </div>
            </animated.div>
            {controls.report && (
              check.ticket_id ?
                <NavLink
                  to={!template && ("/ticket/" + check.ticket_id)}
                  className={`
                    control add-ticket
                    ${template ? " disabled" : ''}
                  `}
                >
                  <i className="fas fa-flag-alt button"></i>
                </NavLink> :
                <NavLink
                  to={!template &&
                    ("/checklist/" + taskId + "/report/" + check.id)}
                  className={`
                    control add-report
                    ${template ? " disabled" : ''}
                  `}
                >
                  <i className="fas fa-pencil-alt"></i>
                </NavLink>
            )}
          </div>}
      </div>
      {!controls.report && (
        <Dropzone onDrop={handleDrop}
          accept="image/jpeg,image/png">
          {({ getRootProps, getInputProps }) => (
            <div
              className="reportWrapper"
              style={{ display: controls.isHidden ? 'none' : 'block' }}
              {...getRootProps({
                onClick: event =>
                  !(event.target.classList.contains("attachInput"))
                  && event.stopPropagation()
              })}>
              <p className="title">Отчёт</p>
              <textarea
                className="reportArea"
                placeholder="Опишите проблему..."
                value={feedback}
                onChange={e => {
                  uploadingStatus === -1
                    && setFeedback(e.target.value);
                  autoResize(e.target)
                }}></textarea>
              <div className="attached-images-container">
                <p className={`
                  title${attachedImagesObject.length > 0 ? " active" : ""}`}>
                  Прикреплённые изображения
                </p>
                <div className="attached-images">
                  {attachedImagesObject.map(({ imagePreviewUrl }, index) =>
                    <div
                      key={"reportImage" + index}
                      onClick={() => {
                        uploadingStatus === -1
                          && setAttachedImagesObject(attachedImagesObject
                            .slice(0, index)
                            .concat(attachedImagesObject
                              .slice(index + 1, attachedImagesObject.length)))
                      }}>
                      <AttachedPhoto
                        imagePreviewUrl={imagePreviewUrl}
                        closeSing />
                    </div>
                  )}
                </div>
              </div>
              {createTicketSwitch && (
                <form className="add-ticket-form">
                  <section className="ticket-info-section">
                    <p>Заголовок тикета</p>
                    <input
                      type="text"
                      placeholder="Например: не работает софит над баром..."
                      onChange={e => {
                        uploadingStatus === -1 &&
                          e.target.value.length <= 32 &&
                          setNewTicket(prevState => ({
                            ...prevState, title: e.target.value
                          }))
                      }}
                      value={newTicket.title} />
                  </section>
                  <section className="ticket-info-section">
                    <p>Описание тикета</p>
                    <textarea
                      type="text"
                      placeholder="Например: шестой софит слева неисправен..."
                      onChange={e => {
                        autoResize(e.target);
                        uploadingStatus === -1 &&
                          e.target.value.length <= 1024 &&
                          setNewTicket(prevState => ({
                            ...prevState, description: e.target.value
                          }))
                      }} value={newTicket.description} />
                  </section>
                  <section className="ticket-info-section">
                    <p>Приоритетность</p>
                    <Select options={[
                      { title: "низкая", value: 1 },
                      { title: "средняя", value: 2 },
                      { title: "высокая", value: 3 },
                      { title: "критическая", value: 4 },
                    ]}
                      onChange={value => {
                        setNewTicket(prevState => ({
                          ...prevState, priority: parseInt(value)
                        }))
                      }} />
                  </section>
                </form>
              )}
              <div className="reportControls">
                <div className="reverseContainer add-ticket-container">
                  <div className="add-ticket" onClick={() =>
                    uploadingStatus === -1
                    && setCreateTicketSwitch(prevState => !prevState)}>
                    <ul className={`
                      plus-icon${createTicketSwitch ? " active" : ""}
                      `}>
                      <li></li>
                      <li></li>
                    </ul>
                    <span>
                      {createTicketSwitch ? 'отменить' : 'создать'} тикет
                    </span>
                  </div>
                </div>
                <div className="reverseContainer">
                  <div className="attach attachInput">
                    <input {...getInputProps()}
                      style={{ display: "block", opacity: 0 }}/>
                    <i className="far fa-camera attachInput"></i>
                  </div>
                </div>
                <div className="reverseContainer">
                  <div className={`
                    submit
                    ${validated ? " validated" : ""}
                    ${uploadingStatus === -1 ?
                      "" : uploadingStatus === 100 ? " loaded" : " loading"}
                  `}
                    onClick={() => {
                      uploadingStatus === -1 && submitFeedback()
                    }}>
                    {(uploadingStatus < 100 && uploadingStatus !== 0) && (
                      <div
                        className="water"
                        style={{ top: "-" + uploadingStatus / 2 + "px", }}>
                        <div className="r1"></div>
                        <div className="r2"></div>
                      </div>
                    )}
                    <div className="content">
                      {uploadingStatus < 0 ?
                        (
                          <i className="fab fa-telegram-plane"></i>
                        ) : uploadingStatus === 100 ?
                          (
                            <i className="fas fa-check"></i>
                          ) :
                          (
                            <div className="progress">{
                              Math.floor(uploadingStatus) + "%"
                            }</div>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
        </Dropzone>
      )}
    </div>
  );
}

export default Check;
