import React, { useState, useEffect } from 'react';


import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import moment from 'moment';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Brush, ResponsiveContainer } from 'recharts';
import SelectorsCard from '../../components/SelectorsCard';
import Loading from '../../components/Loading';
import PosOrderCard from './PosOrderCard';
import Ripples from 'react-ripples'
import IsVisible from 'react-is-visible'

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

import EventEmitter from 'events';

// styles
import './pos-statistics.less';
import './pos-statistics.scoped.less';

// utils
import stringToHslColor from '../../assets/js/stringToHslColor';
import toHHMMSS from '../../assets/js/toHHMMSS';

const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');
const POS_UNIC_ITEMS_QUERY = loader('../../graphql/POS_UNIC_ITEMS_QUERY.gql');

let lastOrderIndex = 0;
let ordersFetchCounter = 0;
// chart tooltip
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ background: '#333', padding: '10px', borderRadius: '5px' }}>
        <h3>{label}</h3>
        {payload.map((payloadItem, i) => {
          return (
            <p
              className="label"
              key={label + payloadItem.name}>
              <span style={{ color: payloadItem.color }}>
                {payloadItem.name + ':'}
              </span>
              <span>{toHHMMSS(payloadItem.value)}</span>
            </p>
          )
        })}
      </div>
    );
  }
  return null;
};
const POSStatistics = () => {
  const [timeToTimeChart, setTimeToTimeChart] = useState(false);
  const [connectNulls, setConnectNulls] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState('line');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [delayPerItem, setDelayPerItem] = useState();
  // const [brushRange, setBrushRange] = useState([undefined, undefined]);
  const branchsQuery = useQuery(BRANCHES_QUERY);
  const posUnicItemsQuery = useQuery(POS_UNIC_ITEMS_QUERY);
  const [posDataSelectors, setPosDataSelectors] = useState([
    {
      index: 1,
      color: stringToHslColor(2, 65, 70),
      hidden: false,
      selectedOrders: [],
      finish: false,
      ordersFetchCounter: 0
    }
  ]);

  const setTopNavBar = useSetRecoilState(topNavBarState);
  const location = useLocation();

  const statisticsEvents = new EventEmitter();

  useEffect(() => {
    setSelectedOrders(uniqueByKey(posDataSelectors
      .map(posDataSelector => !posDataSelector.hidden && posDataSelector.selectedOrders)
      .filter(s => s)
      .flat()
      .map(order => {
        let markedItemsTimings = order.items.map(item => item.station_completed).filter(n => n > 0);
        let minTime = markedItemsTimings.length > 1 ? Math.min(...markedItemsTimings) : false;

        if (!minTime)
          return order;

        return ({
          ...order,
          items: order.items.map(item => ({
            ...item,
            delaysOrderBy: (item.station_completed ? item.station_completed - minTime : -1)
          })).sort((a, b) => b.delaysOrderBy - a.delaysOrderBy)
        });
      }), 'uid')
      .sort((a, b) => (delayPerItem ? b.max_delay_per_item - a.max_delay_per_item : b.created - a.created)))
  }, [posDataSelectors, delayPerItem]);
  useEffect(() => {
    if (selectedOrders.length < lastOrderIndex)
      lastOrderIndex = 0;
  }, [selectedOrders])
  useEffect(() => {
    let newChartDataTmp = posDataSelectors
      .map(posDataSelector =>
        (posDataSelector.data && !posDataSelector.hidden) ? posDataSelector.data.map(posDataSelectorDataItem => ({
          ...posDataSelectorDataItem,
          color: posDataSelector.color,
          date: moment.unix(posDataSelectorDataItem.date),
          ['selector-' + posDataSelector.index]: posDataSelectorDataItem.avg_cooking_time,
          // ['selector-' + posDataSelector.index]: toHHMMSS(posDataSelectorDataItem.avg_cooking_time)
        })) : []).flat();

    let newChartData = [];
    let unicDates = [];

    for (let index = 0; index < newChartDataTmp.length; index++) {
      let roundedDate
      if (timeToTimeChart)
        roundedDate = roundDate(newChartDataTmp[index].date / 1000).format('HH:mm');
      else
        roundedDate = roundDate(newChartDataTmp[index].date / 1000).format('DD.MM.YY HH:mm');
      let thisIndex = unicDates.indexOf(roundedDate);
      if (thisIndex >= 0) {
        newChartData[thisIndex] = { ...newChartDataTmp[index], ...newChartData[thisIndex] }
      }
      else {
        unicDates.push(roundedDate);
        newChartData.push({ ...newChartDataTmp[index], date: roundedDate });
      }

    }
    newChartData.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))


    setChartData(
      newChartData
    );
  }, [posDataSelectors, timeToTimeChart])
  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'POS статистика',
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [location.pathname, setTopNavBar]);


  return (
    <div className="pos-statistics scene">
      <div className="grid">
        <div className="selectors">
          <Ripples className="add-selector-btn-wrapper" color="rgba(255,255,255,0.4)" >
            <button
              className="add-selector-btn"
              onClick={() => setPosDataSelectors(prevState => [...prevState, {
                index: prevState[prevState.length - 1].index + 1,
                color: stringToHslColor(prevState[prevState.length - 1].index + 2, 65, 70),
                ordersFetchCounter
              }])}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              <span>добавить селектор</span>
            </button>
          </Ripples>
          {posDataSelectors
            .map((posDataSelector, index) =>
              <SelectorsCard
                key={'pos-statistics-selector' + posDataSelector.index}
                delayPerItem={delayPerItem}
                branches={branchsQuery.data ? branchsQuery.data.branches : []}
                posUnicItems={posUnicItemsQuery.data ? posUnicItemsQuery.data.posUnicItems : []}
                setPosDataSelectors={setPosDataSelectors}
                posDataSelector={posDataSelector}
                statisticsEvents={statisticsEvents} />
            )}
        </div>
        <div className="statistics">
          <div className="chart-wrapper">
            <div className="chart-header">
              <div className="chart-title">
                <span>Средне время приготовления</span>
              </div>
              <div className="chart-buttons">
                <button
                  className={timeToTimeChart ? 'active' : ''}
                  onClick={() => setTimeToTimeChart(prevState => !prevState)}
                  title="Сопоставлять по времени дня (минимальный интервал 15 минут)">
                  <i className="fa fa-clock" aria-hidden="true"></i>
                </button>
                <button
                  className={chartType === 'bar' ? 'active' : ''}
                  title={chartType !== 'bar' ? 'переключится на столбики 📊' : 'переключиться на линии 📈'}
                  onClick={() => setChartType(prevState => prevState !== 'bar' ? 'bar' : 'line')}>
                  {chartType === 'bar' ?
                    <i className="far fa-chart-line"></i>
                  :
                    <i className="far fa-chart-bar"></i>
                  }
                </button>
                <button
                  disabled={chartType === 'bar'}
                  className={chartType === 'bar' ? 'disabled ' : connectNulls ? 'active' : ''}
                  onClick={() => setConnectNulls(prevState => !prevState)}
                  title="Соединять нули (пробелы данных)">
                  <b>CN</b>
                </button>
              </div>
            </div>
            <div className="chart">
              <ResponsiveContainer width="99%" height="100%">
              {chartType === 'bar' ?
                <BarChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 20,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horisontal="true" vertical="" />
                  <XAxis dataKey="date" />
                  <YAxis unit={'c'} />
                  {
                    posDataSelectors.map(posDataSelector => !posDataSelector.hidden && posDataSelector.data ?
                      <Bar
                        type="monotone"
                        key={'selector-' + posDataSelector.index}
                        dataKey={'selector-' + posDataSelector.index}
                        name={'Период ' + posDataSelector.index}
                        fill={posDataSelector.color} /> : null
                    )
                  }
                  <Tooltip content={<CustomTooltip />} cursor={{fill: 'rgba(255,255,255,.3)'}}/>
                  <Brush
                    className="tb"
                    dataKey="date"
                    onChange={range => {
                      // setBrushRange([chartData[range.startIndex].date, chartData[range.endIndex].date]);
                    }} />
                  {/*<Legend />*/}
                </BarChart>
                :
                <LineChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 20,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid horisontal="true" vertical="" />
                  <XAxis dataKey="date" />
                  <YAxis unit={'c'} />
                  {
                    posDataSelectors.map(posDataSelector => !posDataSelector.hidden && posDataSelector.data ?
                        <Line
                          type="monotone"
                          connectNulls={setConnectNulls}
                          key={'selector-' + posDataSelector.index}
                          dataKey={'selector-' + posDataSelector.index}
                          name={'Период ' + posDataSelector.index}
                          activeDot={{ r: 6 }}
                          dot={false}
                          strokeWidth={3}
                          stroke={posDataSelector.color} /> : null
                    )
                  }
                  <Tooltip content={<CustomTooltip />} />
                  <Brush
                    className="tb"
                    dataKey="date"
                    onChange={range => {
                      // setBrushRange([chartData[range.startIndex].date, chartData[range.endIndex].date]);
                    }} />
                  {/*<Legend />*/}
                </LineChart>
              }
              </ResponsiveContainer>
            </div>
          </div>
          <div className="orders-section">
            <div className="orders-label">
              <div className="label">
                <span>Заказы за период{posDataSelectors.length > 1 && 'ы'}</span>
              </div>
              <div className="action-buttons">
                <Ripples color="rgba(255,255,255,0.4)" >
                  <button
                    className={delayPerItem ? 'active' : ''}
                    onClick={() => setDelayPerItem(p => !p)}>по задержкам заказов</button>
                </Ripples>
              </div>
            </div>

            <div className="orders">
              {
                selectedOrders.length < 1 &&
                <span>Нет данных</span>
              }
              {
                selectedOrders.map((order, i) =>
                  <IsVisible key={"pos-order-card-ovw-" + order.uid}>
                    {isVisible => {
                      if (isVisible && lastOrderIndex < (i - 5)) {
                        statisticsEvents.emit('LOAD_NEW_ORDERS');
                        lastOrderIndex = i;
                        ordersFetchCounter++;
                      }

                      return (
                        <PosOrderCard order={order} />
                      );
                    }
                    }
                  </IsVisible>

                )
              }
              {selectedOrders.length > 0 && posDataSelectors.filter(s => s.finish !== true).length > 0 &&
                <div className="loading-wrapper">
                  <Loading />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function roundDate(date, duration = moment.duration(15, "minutes"), method = 'floor') {
  return moment(Math[method]((+date) / (+duration)) * (+duration));
}

function uniqueByKey(array, key) {
  return [...new Map(array.map((x) => [x[key], x])).values()];
}

export default POSStatistics;
