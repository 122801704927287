import React, { useEffect, useState } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { NavLink } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import IsVisible from 'react-is-visible';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";
import { userState } from "../../state";


// styles
import './tickets.scoped.less';

// components
import Loading from '../../components/Loading';
import TicketCard from '../../components/TicketCard';
import Selectors from './Selectors';

// utils
import { verifyPermissions } from '../../assets/js/security'

// querys
const TICKETS_QUERY = loader('../../graphql/TICKETS_QUERY.gql');
const NEW_TICKETS_SUBSCRIPTION = loader('../../graphql/NEW_TICKETS_SUBSCRIPTION.gql');
const NEW_TICKET_STEP_SUBSCRIPTION = loader('../../graphql/NEW_TICKET_STEP_SUBSCRIPTION.gql');
const UPDATE_TICKET_STEP_SUBSCRIPTION = loader('../../graphql/UPDATE_TICKET_STEP_SUBSCRIPTION.gql');
const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');

const ticketsLoadLimit = 25;
let ticketsCounter = 0;

function Tickets() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const user = useRecoilValue(userState);
  const [lastLoadedTicketId, setLastLoadedTicketId] = useState();
  const [selecors, setSelectors] = useState({
    branch: 0,
    open: true
  });
  const [fetchTicketsQuery, ticketsQuery] = useLazyQuery(TICKETS_QUERY, {
    fetchPolicy: "network-only", // cache-and-network
  });
  const [filteredTickets, setFilteredTickets] = useState([]);
  const newTicketsSub = useSubscription(NEW_TICKETS_SUBSCRIPTION);
  const newTicketStepSub = useSubscription(NEW_TICKET_STEP_SUBSCRIPTION);
  const branchesQuery = useSubscription(BRANCHES_QUERY);
  const updateTicketStepSub = useSubscription(UPDATE_TICKET_STEP_SUBSCRIPTION);


  useEffect(() => {
    if (ticketsCounter === 0 || (ticketsQuery.data && ticketsQuery.data.tickets.length >= ticketsLoadLimit))
      fetchTicketsQuery({
        variables: {
          limit: ticketsLoadLimit,
          startMarker: lastLoadedTicketId,
          branch: selecors.branch,
          open: selecors.open,
        }
      });
  }, [fetchTicketsQuery, lastLoadedTicketId, selecors])
  useEffect(() => {
    if (ticketsQuery.data) {
      setFilteredTickets(prevState => [...prevState, ...ticketsQuery.data.tickets])
    }
  }, [ticketsQuery.data, setFilteredTickets])
  useEffect(() => {
    ticketsCounter = 0;
    setLastLoadedTicketId(undefined);
    setFilteredTickets([])
  }, [selecors])
  useEffect(() => {
    if (newTicketsSub.data && (selecors.branch < 1 || selecors.branch === newTicketsSub.data.newTickets[0].branch_id)) {
      setFilteredTickets(prevState => [...newTicketsSub.data.newTickets, ...prevState])
    }
  }, [newTicketsSub.data]);

  useEffect(() => {
    if (newTicketStepSub.data) {
      setFilteredTickets(prevState => prevState.map(ticket => (ticket.id === newTicketStepSub.data.newTicketSteps[0].ticket_id ?
        ({
          ...ticket,
          steps: [
            ...(ticket.steps ? ticket.steps : []),
            ...newTicketStepSub.data.newTicketSteps
          ]
        }) : ticket)));
    }
  }, [newTicketStepSub.data]);

  useEffect(() => {
    if (updateTicketStepSub.data) {
      setFilteredTickets(prevState => prevState.map(ticket =>
        (ticket.id === updateTicketStepSub.data.updateTicketSteps[0].ticket_id ?
          ({
            ...ticket, steps: (ticket.steps ? ticket.steps : []).map(step => (
              step.id === updateTicketStepSub.data.updateTicketSteps[0].id ?
                updateTicketStepSub.data.updateTicketSteps[0] : step))
          }) : ticket)));
    }
  }, [updateTicketStepSub.data]);

  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Тикеты',
      search: {
        ...prevState.search,
        visible: true
      },
      actions: [
        ...(verifyPermissions(user, [13002]) ? [
          {
            to: '/ticket/new',
            content: <i className="fas fa-plus"></i>
          }
        ] : [])
      ]
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
      search: {
        ...prevState.search,
        visible: false,
        value: ''
      },
      actions: []
    }));
  }, [setTopNavBar, user]);

  return (
    <div className="tickets scene">
      {branchesQuery.data && branchesQuery.data.branches && (
        <Selectors
          onChange={selecors => setSelectors(selecors)}
          branches={branchesQuery.data.branches} />
      )}
      {!ticketsQuery.loading && filteredTickets.length < 1 && ticketsQuery.data && ticketsQuery.data.tickets.length < 1 && (
        <section className="list-banner empty">
          <span>Тут пока нет тикетов 💤</span>
        </section>
      )}
      <TransitionGroup component="div" className="grid">
        {filteredTickets.length > 0 &&
          filteredTickets.map((ticket, index) =>
            <CSSTransition
              key={"ticket-card-transition-" + ticket.id}
              classNames="ticket-card-container"
            >
              <NavLink className="ticket-card-container" to={`/ticket/${ticket.id}`}>
                <IsVisible key={"ticket-" + ticket.id}>
                  {isVisible => {
                    if (
                      isVisible &&
                      filteredTickets.length === index + 1 &&
                      ticketsQuery.data &&
                      ticketsCounter < filteredTickets.length &&
                      ticketsQuery.data.tickets.length > 0
                    ) {
                      ticketsCounter = filteredTickets.length;
                      setLastLoadedTicketId(parseInt(filteredTickets[filteredTickets.length - 1].id));
                    }
                    return (
                      <TicketCard key={`ticketCard-${ticket.id}`} ticket={ticket} />
                    );
                  }
                  }
                </IsVisible>
              </NavLink>
            </CSSTransition>
          )}
      </TransitionGroup>
      {ticketsQuery.loading || (ticketsQuery.data && ticketsQuery.data.tickets.length >= ticketsLoadLimit) &&
        <section className="loading">
          <Loading />
        </section>
      }
      {!ticketsQuery.loading
        && ticketsQuery.data
        && ticketsQuery.data.tickets.length < ticketsLoadLimit
        && filteredTickets.length > 0 &&
        <section className="list-banner">
          <span>Там больше ничего не будет 👇</span>
        </section>}
    </div>
  );
}

export default React.memo(Tickets);
