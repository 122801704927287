import React, { useEffect } from 'react';

import { useSetRecoilState, useRecoilValue } from 'recoil';
import { topNavBarState, appInfoState } from "../../../state";

// styles
import './about-app.scoped.less';
function Account() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const appInfo = useRecoilValue(appInfoState);
  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'О приложении',
    }));

    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [setTopNavBar]);


  return (
    <div className="about-app scene">
      <div className="wrapper">
        <section className="app-banner">
          <h1>Lightback {appInfo.versionName} <b>{appInfo.githead}</b></h1>
        </section>
      </div>
    </div>
  );
}

export default Account;
