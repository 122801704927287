import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState, topNavBarState } from "../../state";

// components
// import CourseCard from '../../components/CourseCard';
import numWord from '../../assets/js/numWord'

// styles
import './courses.scoped.less';


function Courses() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const navigate = useNavigate();

  useEffect(() => {
    // configurating topNavBar
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Обучение',
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [setTopNavBar])


  let courses = [
    {
      id: 1,
      title: 'Krita advanced features',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore eius modi voluptatibus repellat ea id reiciendis vel ipsum obcaecati vero aut, iure aliquid libero dolores.',
      content: [
        {
          type: 'video',
          progress: 1
        },
        {
          type: 'exam',
          progress: 1
        },
      ]
    },
    {
      id: 2,
      title: 'Управление рестораном',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore eius modi voluptatibus repellat ea id reiciendis vel ipsum obcaecati vero aut, iure aliquid libero dolores.',
      content: [
        {
          type: 'manual',
          progress: 2
        },
        {
          type: 'video',
          progress: 1
        },
        {
          type: 'exam',
          progress: 1
        },
      ]
    },
    {
      id: 3,
      title: 'Krita advanced features',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore eius modi voluptatibus repellat ea id reiciendis vel ipsum obcaecati vero aut, iure aliquid libero dolores.',
      content: [
        {
          type: 'manual',
          progress: 0
        },
        {
          type: 'exam',
          progress: 1
        },
      ]
    },
  ]

  // <CourseCard
  // onClick={() => navigate(`/courses/course/1`)}
  // id={1}
  // name="Воки" />
  // <CourseCard
  // onClick={() => navigate(`/courses/course/1`)}
  // id={2}
  // name="Ролы" />

  return (
    <div className="courses flex items-center flex-col scene">
      <div className="courses w-full flex flex-col gap-4 max-w-[450px]">
        {courses.map(course => <CourseCard onClick={() => navigate(`/courses/`+course.id)} key={course.id} course={course} />)}
      </div>
    </div>
  );
}

function CourseCard({ course, style, onClick }) {
  if (!course)
    return null;

  const manualsAmount = course.content.filter(c => c.type === 'manual').length || 0;
  const videosAmount = course.content.filter(c => c.type === 'video').length || 0;
  const examsAmount = course.content.filter(c => c.type === 'exam').length || 0;

  const complitedContent = course.content.filter(c => c.progress >= 1);

  const courseProgress = [
    complitedContent.length || 0,
    course.content.length || complitedContent.length || 0];

  console.log(courseProgress);


  return (
    <div
      onClick={onClick}
      className={`
      w-full
      p-2.5
      flex
      rounded
      shadow
      gap-2.5
      duration-300
      cursor-pointer
      bg-base-800
      select-none
      hover:bg-base-750
      `}
      style={style}>
      <div
        className={`
        min-w-[75px]
        h-[75px]
        overflow-hidden
      `}>
        <img
          width="75px"
          className={`
          rounded
          bg-base-700
          object-cover
          w-[75px]
          h-[75px]
          `}
          src={`https://random.imagecdn.app/500/150?` + course.id}
        />
      </div>
      <div className={`
        w-full
        flex
        flex-col
        justify-between`}>
        <div className={`
      `}>
          <p className="font-9 font-bold leading-5">{course.title}</p>
          <p className="text-base-600 leading-3">
            {
              (manualsAmount > 0 ? ` ${manualsAmount} ${numWord(manualsAmount, ['пособие', 'пособия', 'пособий'])}` : '') +
              (videosAmount > 0 ? ((manualsAmount ? ', ' : ' ') + videosAmount + ' видео') : '') +
              (examsAmount > 0 ? (((videosAmount || manualsAmount) ? ', ' : ' ') + ` ${examsAmount} ${numWord(examsAmount, ['тест', 'теста', 'тестов'])}`) : '')
            }</p>
        </div>
        <div
          className={`
          w-full
        `}>
          <div className={'flex justify-between'}>
            <span className="font-4 text-base-600">{`${courseProgress[0]} ${numWord(courseProgress[0], ['модуль', 'модулей', 'модулей'])}`}</span>
            <span className="font-4 text-base-600">{`${courseProgress[1]} ${numWord(courseProgress[1], ['модуль', 'модулей', 'модулей'])}`}</span>
          </div>
          <div
            className={`
            w-full
            rounded-3xl
            bg-base-700
            shadow-inner
            overflow-hidden
        `}>
            <div
              className={`
              h-2
              duration-300
              rounded-3xl
              bg-green-400
              `}
              style={{ width: courseProgress[0] / courseProgress[1] * 100 + "%" }}></div>

          </div>
        </div>
      </div>
    </div>);
}

export default Courses;
