import React from 'react';
import { NavLink } from 'react-router-dom';
import stringToHslColor from '../../assets/js/stringToHslColor'

// styles
import './branch.scoped.less';

// components
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

const data = [
  {name: 'Page A', uv: 400},
  {name: 'Page B', uv: 350},
  {name: 'Page C', uv: 330},
  {name: 'Page D', uv: 315},
  {name: 'Page D', uv: 425},
  {name: 'Page D', uv: 345},
  {name: 'Page E', uv: 520}
];

function BranchCard({ address, rating, id, linkTo }) {
  return (
    <NavLink to={linkTo !== undefined ? linkTo : "/branch/" + id}
      className="branch-card">
      <div className="infoContainer">
        <span className="branchType">Koreana Light</span>
        <span className="branchName">{address}</span>
      </div>
      <div className="rating">
        <i className="fas fa-star"></i>
        <span>{ rating }</span>
      </div>
      <div className="chartContainer">
        <ResponsiveContainer width="100%" height={100}>
        <AreaChart  data={data}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#fff" stopOpacity={0.1}/>
          <stop offset="95%" stopColor="#fff" stopOpacity={0}/>
          </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="uv"
            stroke={stringToHslColor(id, 50, 50)}
            fillOpacity={1}
            strokeWidth={3}
            fill="url(#colorUv)" />
        </AreaChart>
        </ResponsiveContainer>
      </div>
  </NavLink>
  );
}

export default BranchCard;
