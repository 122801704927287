import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Dropzone from 'react-dropzone';
import stringToHslColor from '../../assets/js/stringToHslColor'
import * as config from "../../config.js";
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { topNavBarState, swipedState, userState } from "../../state";

// svg
import { ReactComponent as QRScanner } from '../../assets/svg/icons/qr-scanner.svg';

// styles
import './account.scoped.less';

// utils
import { verifyPermissions } from '../../assets/js/security'

// querys & mutations
const USER_QUERY = loader('../../graphql/USER_QUERY.gql');
const LOGOUT_MUTATION = loader('../../graphql/LOGOUT_MUTATION.gql');
const UPLOAD_FILE_MUTATION = loader('../../graphql/UPLOAD_FILE_MUTATION.gql');
const USERS_QUERY = loader('../../graphql/USERS_QUERY.gql');

// function subscripeNotifications() {
//   if ("Notification" in window) {
//     if (Notification.permission !== "granted")
//       Notification.requestPermission().then((result) => {
//         if (result === 'granted') {
//           console.log('eeee');
//           registration.pushManager.subscribe({
//             userVisibleOnly: true,
//             applicationServerKey: 'BAnWvgTCOKdW8ZC-gxX00ZSr_JhYuKlltaWcsTLSC1Ha8elKIPPSxKi-rozis93TsPEwwoQp-18_c79LBpwZZCw',
//           }).then(sub => {
//             console.log(sub.toJSON());
//             var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance
//             var theUrl = "https://koreana.link:4000/subscribe-notifications";
//             xmlhttp.open("POST", theUrl);
//             xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
//             xmlhttp.send(JSON.stringify(sub));
//           });
//         }
//       });
//   }
// }


function Account() {
  const swiped = useRecoilValue(swipedState);
  const setSwiped = useSetRecoilState(swipedState);
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const setUser = useSetRecoilState(userState);
  const user = useRecoilValue(userState);
  const { loading, data } = useQuery(USER_QUERY);
  const [mutateLogout, logoutMutation] = useMutation(LOGOUT_MUTATION);
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION, {
    onCompleted: () =>
      setUser(prevState => ({ ...prevState, profilePhotoPath: `https://koreana.link:${config.port}/public/user/${user.id}.jpg?${Date.now()}` }))
  });
  const navigate = useNavigate();

  useEffect(() => {
    // configurating topNavBar
    setTopNavBar(prevState => ({
      ...prevState,
      hiddenOndesctop: true
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      hiddenOndesctop: false
    }));;
  }, [setTopNavBar]);

  useEffect(() => {
    if (logoutMutation.data) {
      window.logOut();
      navigate("/");
    }
  }, [
      logoutMutation.data,
      navigate
    ]);

  return (
    <div className="account scene p-0">
      <div className="profile" style={{ fill: "green" }}>
        <svg className="circles" viewBox="0 0 375 659" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M654.949 -239.547C654.949 -60.9088 510.134 83.906 331.496 83.906C292.981 83.906 256.039 77.1746 221.782 64.824C194.111 -38.9413 117.354 -122.673 17.9015 -159.983C11.4633 -185.437 8.04272 -212.092 8.04272 -239.547C8.04272 -418.185 152.858 -563 331.496 -563C510.134 -563 654.949 -418.185 654.949 -239.547ZM20.4797 -150.413C113.141 -113.738 184.818 -35.4943 212.512 61.3193C119.851 24.644 48.1737 -53.5997 20.4797 -150.413ZM11.2096 -153.918C38.8807 -50.1527 115.637 33.5791 215.09 70.8888C221.528 96.3427 224.949 122.998 224.949 150.453C224.949 212.087 207.71 269.695 177.791 318.718C173.111 139.743 26.5573 -3.90601 -153.547 -3.90601C-263.631 -3.90601 -361.18 49.76 -421.46 132.357C-412.073 -37.8634 -271.07 -173 -98.5043 -173C-59.99 -173 -23.0479 -166.269 11.2096 -153.918ZM8.87369 -163.22C-24.802 -174.745 -60.9227 -181 -98.5043 -181C-279.567 -181 -426.722 -35.8175 -429.905 144.486C-464.719 196.939 -485 259.874 -485 327.547C-485 510.604 -336.603 659 -153.547 659C27.5162 659 174.67 513.818 177.853 333.514C212.668 281.061 232.949 218.126 232.949 150.453C232.949 124.185 229.893 98.6307 224.118 74.1262C257.793 85.6514 293.914 91.906 331.496 91.906C514.552 91.906 662.949 -56.4905 662.949 -239.547C662.949 -422.603 514.552 -571 331.496 -571C148.439 -571 0.0427246 -422.603 0.0427246 -239.547C0.0427246 -213.279 3.09842 -187.725 8.87369 -163.22ZM-98.5043 473.906C13.2596 473.906 111.784 417.221 169.888 331.033C169.9 329.872 169.906 328.71 169.906 327.547C169.906 148.909 25.0912 4.09399 -153.547 4.09399C-265.311 4.09399 -363.835 60.779 -421.939 146.967C-421.951 148.128 -421.957 149.29 -421.957 150.453C-421.957 329.091 -277.142 473.906 -98.5043 473.906ZM-98.5043 481.906C11.5793 481.906 109.129 428.24 169.408 345.643C160.021 515.864 19.0186 651 -153.547 651C-332.185 651 -477 506.185 -477 327.547C-477 265.913 -459.761 208.305 -429.842 159.282C-425.162 338.257 -278.609 481.906 -98.5043 481.906Z"
            fill={(data && data.user && data.user.id) ?
              stringToHslColor(data.user.id, 50, 50) : "#fff"} />
        </svg>
        <div className="wrapper">
          <ul
            style={{ pointerEvents: swiped ? "none" : "auto" }}
            className={"burger" + (swiped ? ' close' : '')}
            onClick={e => setSwiped(prevState => !prevState)}>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <QRScanner
            onClick={() => navigate("/qrscanner")}
            className="QRScannerButton" />
          <div className="photoAndRating">
            <div className="tasks">
              <p>12</p>
              <p>задач</p>
            </div>
            <Dropzone
              onDrop={([file]) => {
                file &&
                  uploadFile({
                    variables: { file },
                    refetchQueries: [USER_QUERY, USERS_QUERY]
                  })
              }}
              accept="image/jpeg">
              {({ getRootProps, getInputProps }) => (
                <div className="photo" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {data && data.user && data.user.id && (
                    <img src={user.profilePhotoPath} alt="" />
                  )}
                </div>
              )}
            </Dropzone>
            <div className="level">
              <p>2</p>
              <p>уровень</p>
            </div>
          </div>
          <div className="nameAndPosition">
            <p>{
              !loading &&
              data &&
              data.user &&
              (data.user.first_name + " " + data.user.last_name)
            }</p>
            <p>{
              !loading &&
              data &&
              data.user &&
              data.user.position.toLowerCase()
            }</p>
          </div>
        </div>
      </div>
      <div className="control">
        <div className="wrapper">
          <section className="control-section">
            <p className="section-title">Настройка аккаунта</p>
            <div className="control-elem"
              onClick={() => {
                navigate('/account/devices')
              }}>
              <span className="controlName">Устройства</span>
              <i className="far fa-chevron-right"></i>
            </div>
            {verifyPermissions(user, [13001]) && (
              <div className="control-elem"
                onClick={() => {
                  navigate('/iot/devices')
                }}>
                <span className="controlName">IoT-устройства</span>
                <i className="far fa-chevron-right"></i>
              </div>
            )}
            <div className="control-elem disabled">
              <span className="controlName">Редактировать</span>
              <i className="far fa-chevron-right"></i>
            </div>
            <div className="control-elem disabled">
              <span className="controlName">Безопасность</span>
              <i className="far fa-chevron-right"></i>
            </div>
          </section>
          <section className="control-section">
            <p className="section-title">Помощь</p>
            <div className="control-elem disabled">
              <span className="controlName">Обратная связь</span>
              <i className="far fa-chevron-right"></i>
            </div>
            <div className="control-elem disabled">
              <span className="controlName">Инструкции</span>
              <i className="far fa-chevron-right"></i>
            </div>
            <div
              className="control-elem"
              onClick={() => {
                navigate('/account/about-app')
              }}>
              <span className="controlName">О приложении</span>
              <i className="far fa-chevron-right"></i>
            </div>
          </section>
          <section className="control-section">
            <p className="section-title">Dev tools</p>
            <div className="control-elem"
              onClick={() => document.location.reload()}>
              <span className="controlName">Reload</span>
              <i className="far fa-chevron-right"></i>
            </div>
            <div className="control-elem"
              onClick={() => serviceWorkerRegistration.update()}>
              <span className="controlName">Update SW</span>
              <i className="far fa-chevron-right"></i>
            </div>
            <div className="control-elem"
              onClick={() => serviceWorkerRegistration.unregister()}>
              <span className="controlName">Unregister SW</span>
              <i className="far fa-chevron-right"></i>
            </div>
          </section>
          <button
            className="logOut"
            onClick={() => { mutateLogout() }}>Выйти</button>
        </div>
      </div>
    </div>
  );
}

export default Account;
