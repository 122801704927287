import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { topNavBarState } from "../../state";
import { loader } from 'graphql.macro';
import moment from 'moment';
import { useQuery, useSubscription } from "react-apollo";
// import { VncScreen } from 'react-vnc';

// styles
import './iot-device.scoped.less';

// components
import Loading from '../../components/Loading';

const IOT_LOG_ADDED_SUBSCRIPTION = loader('../../graphql/IOT_LOG_ADDED_SUBSCRIPTION.gql');
const IOT_DEVICES_QUERY = loader('../../graphql/IOT_DEVICES_QUERY.gql');
const IOT_LOGS_QUERY = loader('../../graphql/IOT_LOGS_QUERY.gql');

let resolutionFactor = 16 / 9;

const useFull = (el) => {
  const [full, setFull] = useState(false)
  const revert = () => {
    if (!document.fullscreenElement) {
      setFull(false)
      document.removeEventListener('fullscreenchange', revert)
    }
  }
  useEffect(() => {
    if (full) {
      const elem = el.current
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        document.addEventListener('fullscreenchange', revert)
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
        document.addEventListener('fullscreenchange', revert)
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
        document.addEventListener('fullscreenchange', revert)
      }
    }
    return () => {
      document.removeEventListener('fullscreenchange', revert)
    }
  }, [full])
  return [full, setFull]
}


function IotDevice() {
  const { id } = useParams();
  const location = useLocation();
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const sceneRef = useRef(null);
  const vncScreenContainerRef = useRef(null);
  const screenRef = useRef(null);
  const [vncStatus, setVncStatus] = useState(false);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState(300);
  const [full, setFull] = useFull(vncScreenContainerRef);
  const [sceneWidth, sceneHeight] = useElementSize(vncScreenContainerRef.current);
  const iotDevicesQuery = useQuery(IOT_DEVICES_QUERY, {
    variables: {
      id
    }
  });
  const iotLogsQuery = useQuery(IOT_LOGS_QUERY, {
    variables: {
      id
    }
  });
  const newIotLogSubscription = useSubscription(IOT_LOG_ADDED_SUBSCRIPTION);

  function useElementSize(element) {
    const [size, setSize] = useState([undefined, undefined]);
    useLayoutEffect(() => {
      if (!element)
        return;
      function updateSize() {
        setSize([element.offsetWidth, element.offsetWidth]);
      }
      window.addEventListener('resize', updateSize);

      // Listen to changes on the elements in the page that affect layout
      let observer = new MutationObserver(updateSize);
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
      });

      updateSize();
      return () => {
        observer.disconnect();
        window.removeEventListener('resize', updateSize)
      };
    }, [element]);
    return size;
  }

  useEffect(() => {
    if (!vncScreenContainerRef.current)
      return;

    setWidth(vncScreenContainerRef.current.offsetWidth);
    setHeight(vncScreenContainerRef.current.offsetWidth / resolutionFactor);
    if (
      screenRef &&
      screenRef.current &&
      screenRef.current.style &&
      screenRef.current.style.width !== vncScreenContainerRef.current.offsetWidth
    ) {
      screenRef.current.style.width = vncScreenContainerRef.current.offsetWidth;
      screenRef.current.style.height = vncScreenContainerRef.current.offsetWidth / resolutionFactor
    }
  }, [sceneWidth, screenRef, vncScreenContainerRef]);


  useEffect(() => {
    if (iotDevicesQuery.loading || !iotDevicesQuery.data || iotDevicesQuery.error)
      return;

    console.log('iotDevicesQuery.data.iotDevices', iotDevicesQuery.data.iotDevices);


    let iotDevice = iotDevicesQuery.data.iotDevices[0];
    setTopNavBar(prevState => ({
      ...prevState,
      title: iotDevice.name,
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));

  }, [
      iotDevicesQuery.data,
      iotDevicesQuery.loading,
      iotDevicesQuery.error,
      location.pathname,
      setTopNavBar
    ])


  return (
    <div className="iot-device scene mx-lim" ref={sceneRef}>
      <section
        ref={vncScreenContainerRef}
        className="vnc-screen"
        onDoubleClick={() => full ? setFull(false) : setFull(true)}
        style={{ height }}
        onClick={e => {
          if (
            (!screenRef && e.target !== e.currentTarget) ||
            (!iotDevicesQuery.data || !iotDevicesQuery.data.iotDevices[0].endpoint)
          )
            return;

          setVncStatus('connecting');
          screenRef.current.connect();
        }}>
        {
          screenRef && !vncStatus ?
            <i
              className="fa fa-play"
              aria-hidden="true"
              onClick={() => {
                if (!iotDevicesQuery.data || !iotDevicesQuery.data.iotDevices[0].endpoint)
                  return;

                setVncStatus('connecting');
                screenRef.current.connect();
              }}></i> :
            vncStatus === 'connecting' ?
              <Loading style={{ position: 'absolute' }} /> :
              vncStatus === 'error' ?
                <i className="fas fa-ban"></i> :
                (!iotDevicesQuery.data || !iotDevicesQuery.data.iotDevices[0].endpoint) ?
                  <i className="fas fa-compass-slash"></i> : null
        }
        {/*<VncScreen
          url={'wss://koreana.link:4000/novnc/' + (iotDevicesQuery.data ? iotDevicesQuery.data.iotDevices[0].endpoint : undefined)}
          background="rgba(0,0,0,0)"
          autoConnect={false}
          scaleViewport
          loadingUI={false}
          viewOnly={false}
          onConnect={() => setVncStatus(true)}
          onDisconnect={() => setVncStatus('error')}
          onCredentialsRequired={frb => {
            frb.sendCredentials({
              password: "#UTF8fat32"
            });
          }}
          ref={screenRef}
          style={{
            width,
            height,
            zIndex: 2,
            margin: 0,
          }}
        />*/}
      </section>
      {
        iotLogsQuery.data &&
        <section className="live-logs">
          <h1>Errors</h1>
          <div className="logs">
            {
              iotLogsQuery.data &&
              iotLogsQuery.data.iotLogs.map(log => log.type === 'ERROR' &&
                <div className="log error">
                  <div className="title">
                    <h3>{log.label === 'undefined' ? 'DEBUGER' : log.label}</h3>
                    <span>{moment.unix(log.date).format('hh:mm:ss DD.MM.YYYY')}</span>
                  </div>
                  <div className="data">
                    <span className="content">{log.message}</span>
                  </div>
                </div>
              )
            }
          </div>
          <h1>Warnings</h1>
          <div className="logs">
            {
              iotLogsQuery.data &&
              iotLogsQuery.data.iotLogs.map(log => log.type === 'WARNING' &&
                <div className="log warning">
                  <div className="title">
                    <h3>{log.label}</h3>
                    <span>{moment.unix(log.date).format('hh:mm:ss DD.MM.YYYY')}</span>
                  </div>
                  <div className="data">
                    <span className="content">{log.message}</span>
                  </div>
                </div>
              )
            }
          </div>
          <h1>Logs</h1>
          <div className="logs">
            {
              iotLogsQuery.data &&
              iotLogsQuery.data.iotLogs.map(log => log.type === 'LOG' &&
                <div className="log">
                  <div className="title">
                    <h3>{log.label}</h3>
                    <span>{moment.unix(log.date).format('hh:mm:ss DD.MM.YYYY')}</span>
                  </div>
                  <div className="data">
                    <span className="content">{log.message}</span>
                  </div>
                </div>
              )
            }
          </div>
        </section >
      }
    </div>
  );
}


export default IotDevice;
