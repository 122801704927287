import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from "react-apollo";
import { loader } from 'graphql.macro';

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

// styles
import './iot-devices.scoped.less';

// components
import Loading from '../../components/Loading';

const IOT_DEVICES = loader('src/graphql/IOT_DEVICES_QUERY.gql');


function IotDevices() {
  const location = useLocation();
  const navigate = useNavigate();
  const iotDevicesQuery = useQuery(IOT_DEVICES);
  const setTopNavBar = useSetRecoilState(topNavBarState);

  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'IoT устройства',
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [location.pathname, setTopNavBar]);


  useEffect(() => {
    console.log(iotDevicesQuery.data);
  }, [
    iotDevicesQuery.data
  ]);

  return (
    <div className="iot-devices scene">
      {
        iotDevicesQuery.loading ? <Loading/> :
        iotDevicesQuery.data && iotDevicesQuery.data.iotDevices &&
        iotDevicesQuery.data.iotDevices.map(iotDevice =>
          <div className="iot-device">
            <div className="icon">
              { iotDevice.role === "pos/kitchen-view" ?
                <i class="far fa-tv" aria-hidden="true"></i> :
                iotDevice.role === "pos/orders-tab" ?
                <i class="far fa-tablet" aria-hidden="true"></i> :
                <i class="fa fa-microchip" aria-hidden="true"></i>
               }
            </div>
            <div className="name">
              <span>{`#${iotDevice.id} ${iotDevice.name}`}</span>
            </div>
            <div className="role"><span>{iotDevice.role}</span></div>
            <div className="link-device-btn"
              onClick={() => navigate('/qrscanner/link-iot/' + iotDevice.id)}>
              <i class="fa fa-qrcode" aria-hidden="true"></i>
            </div>
          </div>
        )
      }
    </div>
  );
}


export default IotDevices;
