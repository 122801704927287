import React, { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

// components
import Loading from '../../components/Loading';
import BranchCard from '../../components/BranchCard';

// styles
import './branchs.scoped.less';

// queries
const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');

function Branchs() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const { data, loading } = useQuery(BRANCHES_QUERY);
  useEffect(() => {
    // configurating topNavBar
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Филиалы',
      search: {
        ...prevState.search,
        visible: true
      }
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
      search: {
        ...prevState.search,
        visible: false
      }
    }));
  }, [setTopNavBar])

  return (
    <div className="branchs scene">
      <div className="grid">
        {loading && <Loading />}
        {!loading && data !== undefined && data.branches && (
          <>
            {data.branches.map(branch => (
              <BranchCard
                address={branch.address}
                rating={branch.rating}
                id={branch.id}
                key={branch.id} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Branchs;
