import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";


// styles
import './checklist-report.scoped.less';

// components
import Check from '../../components/Check'

// query
const CHECK_QUERY = loader('../../graphql/CHECK_QUERY.gql');

function ChecklistReport() {
  const { taskId, checkId } = useParams();
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const [check, setCheck] = useState(false);
  const { loading, data } = useQuery(CHECK_QUERY, {
    variables: {
      id: taskId
    }
  });

  useEffect(() => {
    if (!loading && data && data.task) {
      setCheck({
        ...data.task.checks.filter(check => check.id === checkId)[0],
        branch_id: data.task.branch_id
      });

      // configurating topNavBar
      setTopNavBar(prevState => ({
        ...prevState,
        title: check.title,
      }));
    }
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [setTopNavBar, data, loading, check.title, checkId]);

  return (
    <div className="checklistReport scene mx-lim">
      {check && (
        <Check
          check={check}
          controls={{ report: false }} />
      )}

    </div>
  );
}

export default ChecklistReport;
