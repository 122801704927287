import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import 'moment/locale/ru'

// styles
import './taskcard.scoped.less';

// utils
import { verifyPermissions } from '../../assets/js/security'

// querys
const COMPLETED_CHECKS_QUERY = loader('../../graphql/COMPLETED_CHECKS_QUERY.gql');

function TaskCard({
  task,
  unixNow,
  template = false,
  onClick,
  selected = false,
  isCorrect = undefined,
  linkTo = "#",
  user
}) {

  const [toDoBefore, setToDoBefore] = useState('')
  const [toDoBeforePercent, setToDoBeforePercent] = useState(0.0)
  const [getCompletedChecks, { data, loading }] = useLazyQuery(COMPLETED_CHECKS_QUERY, {
    variables: {
      taskID: task.id
    },
    fetchPolicy: "network-only"
  });


  useEffect(() => {
    if (!template)
      getCompletedChecks();
  }, [getCompletedChecks, template]);

  useEffect(() => {
    let toDoBeforeHours = Math.floor((task.hide - unixNow) / 60 / 60);
    let toDoBeforeMinutes = Math.floor((task.hide - unixNow) / 60 - toDoBeforeHours * 60);
    let toDoBeforeSecounds = Math.floor((task.hide - unixNow) - (toDoBeforeHours * 60 * 60 + toDoBeforeMinutes * 60));


    setToDoBefore(unixNow < task.hide ? (toDoBeforeHours.toString().length < 2 ?
      ("0" + toDoBeforeHours.toString()) :
      toDoBeforeHours) + ":" +
      (toDoBeforeMinutes.toString().length < 2 ?
        ("0" + toDoBeforeMinutes.toString()) :
        toDoBeforeMinutes) + ":" +
      (toDoBeforeSecounds
        .toString().length < 2 ?
        ("0" + toDoBeforeSecounds.toString()) :
        toDoBeforeSecounds) :
      '00:00:00');

    let toDoBeforePercentValue = ((unixNow - task.show) / (task.hide - task.show) * 100);
    setToDoBeforePercent(toDoBeforePercentValue <= 100 ? toDoBeforePercentValue : 100);
  }, [unixNow, task.hide, task.show]);



  let checkdChecks = 0;
  let checksCount = 0;
  if (data) {
    for (let i = 0; data.completedChecks.length > i; i++) {
      if (data.completedChecks[i].status !== null)
        checkdChecks++;
    }
    checksCount = data.completedChecks.length;
  }

  return (
    <NavLink
      to={template ? linkTo : "/checklist/" + task.id}
      key={task.id}
      className="card"
      onClick={onClick}>
      <div className={`
        taskCard
        ${selected ? " selected" : ''}
        ${template ?
          isCorrect === undefined ?
            "" : isCorrect ? " isCorrect" : " isIncorrect" : ""}
        `}>
        <p className="title">{task.title}</p>
        {!template && verifyPermissions(user, [12003]) &&
          <NavLink to={"/checklist/editor/" + task.task_template}>
            <i className="fas fa-edit edit-button"></i></NavLink>}
        <p className="description">{task.description}</p>
        <div className="statusWrapper">
          <div className="statusBlock">
            <div className="iconContainer awardICON">
            </div>
            <div className="labels">
              {((data && checksCount !== 0) ?
                <span>Завершено</span> : <p>Начать проверку</p>)}
              <span>
                {
                  ((!loading &&
                    data &&
                    checksCount !== 0) ?
                    Math.floor(checkdChecks * 100 / checksCount) + '%' : null)
                }
              </span>
            </div>
            <div className="timer">
              <div
                className="progress"
                style={{
                  width: (checksCount === 0 ? 0 :
                    Math.floor(checkdChecks * 100 / checksCount)) + '%'
                }}></div>
            </div>
          </div>
          <div className="statusBlock">
            <div className="iconContainer clockICON">
            </div>
            <div className="labels">
              <span>Осталось времени</span>
              <span>{toDoBefore}</span>
            </div>
            <div className="timer">
              <div
                className="progress"
                style={{ width: toDoBeforePercent + '%' }}></div>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default TaskCard;
