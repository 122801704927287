import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery, useSubscription } from "react-apollo";
import { loader } from 'graphql.macro';
import * as cookie  from 'cookie';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

import { useRecoilValue } from 'recoil';
import { appInfoState } from "../../state";

import QRCode from 'qrcode.react';

// svg
import { ReactComponent as TreeSVG } from '../../assets/svg/tree.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';

import Loading from '../../components/Loading';

// styles
import './login.scoped.less';

// querys
const CLIENT_LOGIN_REQUEST_QUERY = loader('../../graphql/CLIENT_LOGIN_REQUEST_QUERY.gql');
const ON_CLIENT_AUTHORIZED_SUBSCRIPTION = loader('../../graphql/ON_CLIENT_AUTHORIZED_SUBSCRIPTION.gql');
const LOGIN_BY_PHONE_QUERY = loader('../../graphql/LOGIN_BY_PHONE_QUERY.gql');
const AUTHORIZE_BY_SMS_CODE_QUERY = loader('../../graphql/AUTHORIZE_BY_SMS_CODE_QUERY.gql');

function Login({ setLoggedIn }) {
  const appInfo = useRecoilValue(appInfoState);
  const [authID, setauthID] = useState();
  const loginQuery = useQuery(CLIENT_LOGIN_REQUEST_QUERY);
  const [fetchLoginByPhoneQuery, loginByPhoneQuery] = useLazyQuery(LOGIN_BY_PHONE_QUERY);
  const [fetchAuthorizeBySMSCodeQuery, authorizeBySMSCodeQuery] = useLazyQuery(AUTHORIZE_BY_SMS_CODE_QUERY);
  const phoneInputRef = useRef();
  const smsCodeInputRef = useRef();
  const [sataIsVrong, setDataIsVrong] = useState(false);
  const [currentPhone, setCurrentPhone] = useState(false);
  const [loginState, setLoginState] = useState('qr-code');

  useEffect(() => {
    if (!loginQuery.loading && loginQuery.data) {
      let loginQueryRefetchTimer = setInterval(() =>
        loginQuery.refetch(), 300 * 1000);

      return () => clearInterval(loginQueryRefetchTimer);
    }
  }, [loginQuery]);


  useEffect(() => {
    if (loginQuery.data) {
      setauthID(loginQuery.data.clientLoginRequest.authID);
    }
    if (
      !loginQuery.loading &&
      loginQuery.data &&
      loginQuery.data.clientLoginRequest &&
      loginQuery.data.clientLoginRequest.authID
    ) {
      localStorage.setItem(window.screenID ? window.screenID + '_' + 'authID' : 'authID', loginQuery.data.clientLoginRequest.authID);
    }
  }, [loginQuery.data, loginQuery.loading]);

  const { data, error } = useSubscription(ON_CLIENT_AUTHORIZED_SUBSCRIPTION, {
    variables: {
      authID
    },
  });

  if (error) {
    console.log(error);
  }

useEffect(() => {
  console.log(data);

  if (data && data.onClientAuthorized && data.onClientAuthorized.token) {
    let allCookies = cookie.parse(document.cookie);
    localStorage.setItem(window.screenID ? window.screenID + '_' + 'token' : 'token'
    , data.onClientAuthorized.token);
    if (data.onClientAuthorized.role)
      localStorage.setItem(window.screenID ? window.screenID + '_' + 'role' : 'role', data.onClientAuthorized.role);


    let appType;
    if(data.onClientAuthorized.role)
      appType = data.onClientAuthorized.role.split('/')[0]

    if(appType && (allCookies['app-type'] !== appType || appType === "pos")) {
      document.cookie = cookie.serialize("app-type", appType)
      serviceWorkerRegistration.update();
      document.location.reload();
    } else
      setLoggedIn(true);

  }
}, [data, setLoggedIn]);

  const enterPhoneAction = () => {
    if (loginQuery.loading)
      return;
    const phone = phoneInputRef.current.value.replace(/\s/g, '');
    setCurrentPhone(phone);
    console.log(phone);

    fetchLoginByPhoneQuery({
      variables: {
        phone,
        authID
      }
    });
  }
  useEffect(() => {
    if (!loginByPhoneQuery.loading &&
      loginByPhoneQuery.data &&
      loginByPhoneQuery.data.loginByPhone &&
      !loginByPhoneQuery.error) {
      setDataIsVrong(false);
      setLoginState('enter-code');
    } else if ((!loginByPhoneQuery.loading && loginByPhoneQuery.data && !loginByPhoneQuery.data.loginByPhone) || authorizeBySMSCodeQuery.error)
      setDataIsVrong(true);
  }, [
      loginByPhoneQuery,
      authorizeBySMSCodeQuery.error
    ]);

  const enterSMSCodeAction = () => {
    if (!currentPhone)
      return;

    const code = parseInt(smsCodeInputRef.current.value.replace(/\s/g, ''));


    fetchAuthorizeBySMSCodeQuery({
      variables: {
        phone: currentPhone,
        authID,
        code
      }
    });
  }

  useEffect(() => {
    if (!authorizeBySMSCodeQuery.loading &&
      authorizeBySMSCodeQuery.data &&
      authorizeBySMSCodeQuery.data.authorizeBySMSCode &&
      authorizeBySMSCodeQuery.data.authorizeBySMSCode.token) {


      setDataIsVrong(false);
      localStorage.setItem(window.screenID ? window.screenID + '_' + 'token' : 'token'
      , authorizeBySMSCodeQuery.data.authorizeBySMSCode.token);
      setLoggedIn(true);
    } else if((!authorizeBySMSCodeQuery.loading &&
      authorizeBySMSCodeQuery.data &&
      !authorizeBySMSCodeQuery.data.authorizeBySMSCode) || authorizeBySMSCodeQuery.error)
      setDataIsVrong(true);
  }, [
      authorizeBySMSCodeQuery,
      setLoggedIn
    ]);

  return (
    <div className="logIn scene">
      <Logo className="logo" />
      <svg className="circles" viewBox="0 0 375 659" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M344.989 101.505C296.638 173.001 214.808 220 122 220C-26.5646 220 -147 99.5646 -147 -49C-147 -49.8358 -146.996 -50.6707 -146.989 -51.5047C-98.6383 -123.001 -16.8078 -170 76 -170C224.565 -170 345 -49.5646 345 99C345 99.8358 344.996 100.671 344.989 101.505ZM352.83 89.2043C347.671 -59.242 225.704 -178 76 -178C-15.1324 -178 -95.9859 -133.991 -146.467 -66.0662C-137.664 -206.679 -20.8322 -318 122 -318C270.565 -318 391 -197.565 391 -49C391 1.53351 377.066 48.8125 352.83 89.2043ZM352.956 103.987C382.051 60.1513 399 7.55496 399 -49C399 -201.983 274.983 -326 122 -326C-29.3171 -326 -152.296 -204.669 -154.956 -53.9869C-184.051 -10.1513 -201 42.445 -201 99C-201 251.983 -76.9829 376 76 376C227.317 376 350.296 254.669 352.956 103.987ZM344.467 116.066C293.986 183.991 213.132 228 122 228C-27.704 228 -149.671 109.242 -154.83 -39.2043C-179.066 1.18753 -193 48.4665 -193 99C-193 247.565 -72.5646 368 76 368C218.832 368 335.664 256.679 344.467 116.066Z" fill="white" fill-opacity="0.1" />
      </svg>
      {loginState === 'qr-code' ? (
        <div className="login-form">
          <div className="qr-code-wrapper">
            {loginQuery.loading ? <Loading /> :
              <QRCode
                value={`http://login.koreana.link/${loginQuery.data.clientLoginRequest.authID}`}
                className="qr-code"
                level="H"
                renderAs="svg"
                fgColor={"#000"}
                bgColor="rgba(0,0,0,0)" />
            }
          </div>
          <div
            className="msm-login-btn first"
            onClick={() => setLoginState('enter-phone')}>
            <span>вход по смс</span>
          </div>
        </div>
      ) : loginState === 'enter-phone' ? (
        <div className="login-form">
          <div className="login-form-input-wrapper">
            <input
              ref={phoneInputRef}
              type="text"
              onChange={() => setDataIsVrong(false)}
              key="login-phone-input"
              placeholder="введите номер телефона" />
            { sataIsVrong && <i class="fa fa-exclamation" aria-hidden="true"></i> }
          </div>
          <div
            className="msm-login-btn"
            onClick={enterPhoneAction}>
            <span>далее</span>
          </div>
          <div
            className="msm-login-btn back"
            onClick={() => setLoginState('qr-code')}>
            <span>назад</span>
          </div>
        </div>
      ) : (
            <div className="login-form">
              <div className="login-form-input-wrapper">
                <input
                  type="number"
                  onChange={() => setDataIsVrong(false)}
                  ref={smsCodeInputRef}
                  key="login-code-input"
                  placeholder="введите код из смс" />
                { sataIsVrong && <i class="fa fa-exclamation" aria-hidden="true"></i> }
              </div>
              <div
                className="msm-login-btn"
                onClick={enterSMSCodeAction}>
                <span>войти</span>
              </div>
              <div
                className="msm-login-btn back"
                onClick={() => setLoginState('enter-phone')}>
                <span>назад</span>
              </div>
            </div>
          )}
      <div className="info">
        ?
      </div>
      <p class="build">
        <a href={appInfo.link}>{appInfo.versionName}</a>
        <span>{appInfo.version}</span>
      </p>
      <TreeSVG className="tree" />
    </div>
  );
}

export default Login;
