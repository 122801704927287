import React, {useEffect} from 'react';
import {
  useLocation,
} from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { overlayState } from "../../state";

// components
import Modal from '../Modal';
import ModalView from '../ModalView';
import OptionsList from '../OptionsList';

// styles
import './overlay.scoped.less';

const initialState = {
  type: null,
  data: {}
}

function Overlay() {
  const location = useLocation();
  const overlay = useRecoilValue(overlayState);
  const setOverlay = useSetRecoilState(overlayState);

  useEffect(() =>
    setOverlay(initialState),
  [location])

  return (
    <div
      className="overlay"
      style={{ display: overlay.type ? 'flex' : 'none' }}
      onClick={e => e.target.classList.contains('overlay') &&
        setOverlay(initialState)}>
      {
        overlay.type === 'modal-window' ?
          <Modal data={overlay.data} close={() => setOverlay(initialState)} /> :
          overlay.type === 'modal-view' ?
            <ModalView
              src={overlay.data.src}
              close={() => setOverlay(initialState)} /> :
            overlay.type === 'select' ?
              <OptionsList
                close={() => setOverlay(initialState)}
                options={overlay.data.currentOptions}
                selectedValue={overlay.data.selectedValue}
                onChange={newValue => overlay.events.onChange(newValue)} /> : null
      }
    </div>
  );
}

export default Overlay;
