import React, { useState } from 'react';
import stringToHslColor from '../../assets/js/stringToHslColor';
import toHHMMSS from '../../assets/js/toHHMMSS';
import moment from 'moment';

import './pos-order-card.scoped.less';

function PosOrderCard({order}) {
  const [isModifiersVisible, setIsModifiersVisible] = useState(false);
  const skipDelayFlag = order.items.map(item => item.delaysOrderBy).filter((value, index, self) => value !== undefined && self.indexOf(value) === index).length < 2;
  return (
    <div
      className="pos-order-card"
      key={"pos-order-card-" + order.uid}
      onClick={() => setIsModifiersVisible(p => !p)}>
      <div className="order-info">
        <span className="order-branch-id">
          <span className="branch-name" style={{ color: stringToHslColor(parseInt(order.branch_id), 50, 50) }}>{order.branch_name}</span>
          <span
            className="order-id"
            title="номер заказа в Iiko">#{order.order_id}</span>
        </span>
        <span className='order-dates'>
          <span
            className={'order-life-time ' + (
              order.assembled - order.created > 12 * 60 ? 'error' :
                order.assembled - order.created > 8 * 60 ? 'warning' : '')}
            title="время жизни заказа">{toHHMMSS(order.assembled - order.created)}</span>
          <span
            className="order-date"
            title="дата создания заказа">{moment.unix(order.created).format('HH:mm:ss DD.MM.YYYY')}</span>
        </span>
      </div>
      <div className="items">
        {order.items.map(item =>
          <div key={'order-item' + item.uid} className="order-item">
            <div className="item-parts">
              <div className="item-part">
                <span className="label">наименование</span>
                <span className="value">{item.name}</span>
              </div>
              <div className="item-part">
                <span className="label">кол-во</span>
                <span className="value">{item.amount}</span>
              </div>
              <div className="item-part">
                <span className="label">приготовлен</span>
                <span className="value">{toHHMMSS(item.station_completed - order.created)}</span>
              </div>
              <div className="item-part">
                <span className="label">собран</span>
                <span className="value">{
                  (item.assembled && item.station_completed) ?
                    toHHMMSS(item.assembled - item.station_completed) : '-'}</span>
              </div>
              <div className="item-part">
                <span className="label">цех</span>
                <span className={'value station-' + (item.station === 'ГЦ' ? 'hot' : item.station === 'ХЦ' ? 'cold' : '')}>{item.station}</span>
              </div>
              <div className="item-part">
                <span className="label">замедляет на</span>
                <span className={'value ' + (item.delaysOrderBy > 8 * 60 ? 'error' : item.delaysOrderBy > 5 * 60 ? 'warning' : '')}>{
                  (!skipDelayFlag && item.delaysOrderBy === 0) ?
                    <i
                      className="fas fa-flag"
                      title="точка отсчёта"></i> : item.delaysOrderBy ?
                      toHHMMSS(item.delaysOrderBy) : '-'
                }</span>
              </div>
            </div>
            {
              isModifiersVisible && item.modifiers &&
              <div className="modifiers">
                {
                  item.modifiers.map((m, index) =>
                    <span key={item.uid + index}>- {m}</span>
                  )
                }
              </div >
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default PosOrderCard;
