import React from 'react';

// styles
import './loading.scoped.less';

const Loading = ({style = {}, className = ""}) => {
  return (
    <div className={"lds-facebook" + " " + className} style={{...style}}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Loading;
