import React, { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from "react-apollo";

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

// styles
import './task-templates.scoped.less';

// components
import TaskCard from '../../components/TaskCard'

// querys
const TASKS_TEMPLATES_QUERY = loader('../../graphql/TASKS_TEMPLATES_QUERY.gql');

function TaskTemplates() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const { data, error } = useQuery(TASKS_TEMPLATES_QUERY);
  if (error) {
    console.log(error);
  }

  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Шаблоны задач',
      actions: [
        ...prevState.actions,
        {
          to: '/checklist/editor/new',
          content: <i className="fas fa-plus"></i>
        }
      ]
    }));

    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
      actions: []
    }));
  }, [setTopNavBar]);

  return (
    <div className="task-templates scene">
      <div className="tasks-container">
        {data && data.tasksTemplates && data.tasksTemplates.map(taskTemplate =>
          <TaskCard
            key={taskTemplate.id}
            task={taskTemplate}
            linkTo={"/checklist/editor/" + taskTemplate.id}
            template />
        )}
      </div>
    </div>
  );
}

export default TaskTemplates;
