const util = require("util");
const EventEmitter = require("events");

function WarpIotDevice(deviceProps) {
  const that = this;
  this.name = deviceProps.name
  this.id = deviceProps.id || undefined;
  this.remote = deviceProps.remote || false;
  this.source = deviceProps.remote && deviceProps.source;
  this.node = deviceProps.remote && deviceProps.node;
  this.services = [];
  this.servicesMap = new Map();
  this.version = deviceProps.version || undefined;

  this.cluster.on('disconnected', () => {
    this.removeAllListeners();
  })


  this.defineService = function(service) {
    service.device = that;
    if (this.remote)
      that.emit('serviceDefined', service)
    that.servicesMap.set(service.name, service)

    if (!this.remote)
      that.interface.broadcast('service:service-defined', {
        name: service.name
      })
      .catch(e => console.log(e))


    that.services = [...that.servicesMap.values()];
    service.emit('assignedToDevice');
  }

  this.setDeviceData = deviceData => {
    that.type = deviceData.type || that.type;
    that.name = deviceData.name || that.name;
    that.id = deviceData.id || that.id;
    that.version = deviceData.version || that.version;
    if(!that.remote)
      that.interface.broadcast('device:data-updated', {
        type: that.type,
        id: that.id,
        name: that.name,
        version: that.version
      });
    that.emit('deviceDataUpdated', {
      type: that.type,
      id: that.id,
      name: that.name,
      version: that.version
    }, that)
  }
}

export {
  WarpIotDevice
}
