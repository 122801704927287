module.exports = {
  port: process.env.REACT_APP_HOST_ORIGIN_PORT || 443, // 443 for production
  hostname: 'koreana.link',
  githead: process.env.REACT_APP_GIT_HEAD,
  releseName: '최남라',
  link: 'https://www.google.com/search?q=최남라.+지금+우리+학교는%2C+只今+우리+學校는'
}

// module.exports = {
//   ssl: true,
//   port: 443,
//   hostname: 'koreana.link'
// }
