import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import stringToHslColor from '../../assets/js/stringToHslColor'

// styles
import './select-branch-card.scoped.less';

function SelectBranchCard({ branch, linkTo }) {
  const [completedTasksInBranchInpercents, setCompletedTasksInBranchInpercents] = useState(0);
  const [completedTasksCount, setCompletedTasksCount] = useState(0);
  const [totalTasksCount, setTotalTasksCount] = useState(0);


  useEffect(() => {
    setCompletedTasksCount(
      branch.currentTasks
        .filter(currentTask => currentTask.completed !== null).length || 0);
    setTotalTasksCount(branch.currentTasks.length || 0);

    if (totalTasksCount > 0) {
      setCompletedTasksInBranchInpercents(completedTasksCount / totalTasksCount * 100)
    }
  }, [branch.currentTasks, totalTasksCount, completedTasksCount]);

  return (
    <NavLink
      to={linkTo !== undefined ? linkTo : "/branch/" + branch.id}
      className="branch-card bg-base-850 shadow">
      <p className="address text-base-100">{branch.address}</p>
      <p className="completedTasks text-base-200">
        {`${completedTasksCount}/${totalTasksCount}`}</p>
      <div className="timer">
        <div
          className="progress"
          style={{ width: completedTasksInBranchInpercents + '%' }}></div>
      </div>
      <div
        className="colorLabel"
        style={{ backgroundColor: stringToHslColor(branch.id, 50, 50) }}></div>
    </NavLink>
  );
}

export default SelectBranchCard;
