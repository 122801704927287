import * as config from "../config.js";
import { atom } from 'recoil';

export const userState = atom({
  key: 'user',
  default: {
    firstName: localStorage.getItem('first-name') || '',
    secoundName: localStorage.getItem('secound-name') || '',
    lastName: localStorage.getItem('last-name') || '',
    permissions: localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : [],
    profilePhotoPath: `https://koreana.link:${config.port}/public/user/new.jpeg`,
  }
});

export const overlayState = atom({
  key: 'overlay',
  default: {
    type: null,
    data: {}
  }
});

export const appInfoState = atom({
  key: 'appInfo',
  default: {
    // version: localStorage.getItem('app-version') || '',
    version: config.githead,
    versionName: localStorage.getItem('app-relese-name') || '',
    link: localStorage.getItem('app-relese-link') || ''
  }
});

export const swipedState = atom({
  key: 'swiped',
  default: false
});

export const topNavBarState = atom({
  key: 'topNavBar',
  default: {
    title: '',
    search: {
      visible: false,
      value: '',
    },
    hidenOnDesctop: false, // window.innerWidth >= 992
    swiped: false,
    actions: [],
  }
});


export const permissions = atom({
  key: 'permissions',
  default: []
});
