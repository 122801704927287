import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import stringToHslColor from '../../assets/js/stringToHslColor'
import * as config from "../../config.js";

import { useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";

import {
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line
} from 'recharts';
import { DatePicker } from 'antd';
import { Progress } from 'antd';

import AttachedPhoto from '../../components/AttachedPhoto';

// styles
// import 'antd/dist/antd.css';
import './branch.scoped.less';

const { RangePicker } = DatePicker;

// querys
const BRANCH_QUERY = loader('../../graphql/BRANCH_QUERY.gql');
const STATISTICS_BY_RANGE_QUERY = loader('../../graphql/STATISTICS_BY_RANGE_QUERY.gql');

function Branch() {
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const branchID = parseInt(useParams().id);
  const [getData, { data, loading }] = useLazyQuery(STATISTICS_BY_RANGE_QUERY, {
    fetchPolicy: "network-only"
  });
  const branchesQuery = useQuery(BRANCH_QUERY, { variables: { id: branchID } });

  if (data)
    console.log(data);


  const [chartData, setChartData] = useState([]);
  const [departments, setDepartments] = useState({});
  const [mean, setMean] = useState(0);

  useEffect(() => {
    getData({
      variables: {
        start: moment().startOf('day').unix().toString(),
        end: moment().endOf('day').unix().toString(),
        branch: branchID
      }
    });
  }, [branchID, getData]);

  useEffect(() => {
    if (!branchesQuery.loading && branchesQuery.data && branchesQuery.data.branch) {

      // configurating topNavBar
      setTopNavBar(prevState => ({
        ...prevState,
        title: branchesQuery.data.branch.address,
      }));
      return () => setTopNavBar(prevState => ({
        ...prevState,
        title: '',
      }));
    }
  }, [branchesQuery.data, branchesQuery.loading, setTopNavBar]);

  useEffect(() => {
    if (data && data.statisticsByRange) {

      let sfc = {};
      let deps = {};

      console.log(data.statisticsByRange);


      for (let i = 0; i < data.statisticsByRange.length; i++) {
        let status = data.statisticsByRange[i].type === '1' ?
          data.statisticsByRange[i].status === 1 ? 5 : 0
          : data.statisticsByRange[i].status;
        if (sfc[moment.unix(data.statisticsByRange[i].created)
          .format("DD.MM.YYYY")] !== undefined) {
          if (sfc[moment.unix(data.statisticsByRange[i].created)
            .format("DD.MM.YYYY")][data.statisticsByRange[i].department_name] !== undefined) {
            sfc[moment.unix(data.statisticsByRange[i].created)
              .format("DD.MM.YYYY")][data.statisticsByRange[i].department_name][0] += status;
            sfc[moment.unix(data.statisticsByRange[i].created)
              .format("DD.MM.YYYY")][data.statisticsByRange[i].department_name][1]++;
          }
          else {
            sfc[moment.unix(data.statisticsByRange[i].created)
              .format("DD.MM.YYYY")][data.statisticsByRange[i].department_name] = [status, 1];
          }
        }
        else {
          sfc[moment.unix(data.statisticsByRange[i].created)
            .format("DD.MM.YYYY")] = {};
          sfc[moment.unix(data.statisticsByRange[i].created)
            .format("DD.MM.YYYY")][data.statisticsByRange[i].department_name] = [status, 1];
          sfc[moment.unix(data.statisticsByRange[i].created)
            .format("DD.MM.YYYY")].date = moment.unix(data.statisticsByRange[i].created)
              .format("DD.MM.YYYY");
        }

        // mean by departments
        if (deps[data.statisticsByRange[i].department_name] === undefined) {
          deps[data.statisticsByRange[i].department_name] = {
            active: true,
            status: undefined,
            sum: status,
            amount: 1
          }
        } else {
          deps[data.statisticsByRange[i].department_name].sum += status;
          deps[data.statisticsByRange[i].department_name].amount++;
        }

      }

      let meanCalc = { amount: 0, sum: 0 };

      Object.keys(deps).forEach(dep => {
        meanCalc.amount++;
        meanCalc.sum += deps[dep].sum / deps[dep].amount;
      });

      setMean((meanCalc.sum / meanCalc.amount * 20).toFixed(0));


      let res = Object.entries(sfc).map((e) => (e[1]));
      for (let i = 0; i < res.length; i++) {
        for (let k = 0; k < Object.keys(res[i]).length; k++) {
          if (Object.keys(res[i])[k] !== "date") {
            res[i][Object.keys(res[i])[k]] = (res[i][Object.keys(res[i])[k]][0] / res[i][Object.keys(res[i])[k]][1]).toFixed(2);
          }
        }
      }


      setDepartments(deps);
      setChartData(res);
    }
  }, [data])



  // chart tooltip
  const CustomTooltip = ({ active, payload, label }) => active ? (
    <div className="custom-tooltip">
      {payload ? payload.map(element => (
        <p className="label" key={element.dataKey}>{`${element.dataKey} : ${element.value}`}</p>
      )) : null}
    </div>
  ) : null;


  return (
    <div className="branch scene">
      <div className="photoPreview">
      </div>
      <div className="grid">
        <div className="selectors">
          <div className="datePickerContainer">
            <RangePicker
              inputReadOnly={true}
              className="datePicker"
              format={"DD.MM.YYYY"}
              ranges={{
                'Сегодня': [moment().startOf('day'), moment().endOf('day')],
                'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
              }}
              defaultValue={[
                moment().startOf('day'),
                moment().endOf('day')
              ]}
              onChange={range => (range !== null && range[0] !== null && range[1] !== null) ?
                getData({
                  variables: {
                    start: range[0].unix().toString(),
                    end: range[1].unix().toString(),
                    branch: branchID
                  }
                }) : null} />
          </div>
          <div className="circlesContainer">
            {Object.keys(departments).length > 0 && (
              <div className="circle">
                <div
                  className="progress">
                  <Progress
                    strokeWidth="7"
                    trailColor="#191a1d"
                    strokeColor={{
                      '0%': '#EB5757',
                      '20%': '#EB5757',
                      '90%': '#87d068',
                      '100%': '#87d068',
                    }}
                    width="75px"
                    type="circle" percent={mean}
                    format={percent =>
                      <div className="progressFormat">
                        <span>{mean}</span><span>/100</span>
                      </div>} />
                </div>
                <div className="description">
                  <span>Среднее значение</span>
                </div>
              </div>
            )}
            {Object.keys(departments).map((department, index) => (
              <div className={"circle" + (departments[department].active ? " active" : "")} key={"statistic-branch-selectir-"+index} onClick={() => {
                let dp = Object.assign({}, departments);
                dp[department].active = !dp[department].active;
                setDepartments(dp)
              }}>
                <div className="progress">
                  <Progress
                    strokeWidth="7"
                    trailColor="#191a1d"
                    strokeColor={stringToHslColor(department, 50, 50)}
                    width="75px"
                    type="circle"
                    percent={(departments[department].sum / departments[department].amount)
                      .toFixed(2) * 20}
                    format={percent =>
                      <div className="progressFormat">
                        <span>{
                          (departments[department].sum / departments[department].amount * 20)
                            .toFixed(0)}</span><span>/100</span></div>} />
                </div>
                <div className="description">
                  <span>{department}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="data">
          {Object.keys(chartData).length > 1 ? (
            <div className="chart">
              <ResponsiveContainer width="99%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 20,
                    left: -10,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  {Object.keys(departments).map((department, index) =>
                    departments[department].active ?
                      <Line
                        type="monotone"
                        dataKey={department}
                        key={"statistic-chart-line-" + index}
                        stroke={stringToHslColor(department, 50, 50)}
                        activeDot={{ r: 8 }} /> : null
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          ) : null}
          <div className="glass">
            {!loading && data !== undefined && data.statisticsByRange && (
              <>
                {data.statisticsByRange.map(report =>
                  Object.keys(departments).some(department =>
                    (departments[report.department_name] !== undefined &&
                      departments[report.department_name].active)) ? (
                      <div className="report" key={report.id}>
                        <div className="wrapper">
                          <div className="title">
                            <span>{report.name}</span>
                            <span>{moment.unix(report.created).format("DD.MM.YY")}</span>
                          </div>
                          <div className="description">
                            <span>{report.description}</span>
                          </div>
                          <div className={"rating" + (
                            (report.type === "1" && report.status === 1) ? " rating-true" :
                              (report.type === "1" && report.status === 0) ? " rating-false" :
                                report.status === 1 ? " rating-1" :
                                  report.status === 2 ? " rating-2" :
                                    report.status === 3 ? " rating-3" :
                                      report.status === 4 ? " rating-4" :
                                        report.status === 5 ? " rating-5" : "")}>
                            <span>{
                              (report.type === "1" && report.status === 1) ? <i className="fa fa-check" aria-hidden="true"></i> :
                                (report.type === "1" && report.status === 0) ? <i className="fa fa-times" aria-hidden="true"></i> :
                                  report.status
                            }</span>
                          </div>
                        </div>
                        {report.reason !== null && (
                          <div className="feedBack">
                            <span className="message">{report.reason}</span>
                            <div className="ticketIDContainer">
                              <span className="ticketID">#{report.report_id}</span>
                            </div>
                            <div className="photos">
                              {
                                (() => {
                                  let photos = [];
                                  for (let i = 0; i < report.attached; i++)
                                    photos.push(
                                      <AttachedPhoto
                                        key={"AttachedPhoto" + report.report_id + "_" + i}
                                        imagePreviewUrl={`https://koreana.link:${config.port}/public/reports/${report.report_id}_${i}.jpg`}
                                        viewable />)
                                  return photos
                                }
                                )()
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null)}
              </>
            )}
          </div>
        </div>
      </div>

    </div>
  );
}

export default Branch;
