import React, { useEffect } from 'react';

// styles
import './course.scoped.less';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState, topNavBarState } from "../../state";

// components
import CourseCard from '../../components/CourseCard';

const courseData = {
  id: 1,
  title: 'Krita advanced features',
  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore eius modi voluptatibus repellat ea id reiciendis vel ipsum obcaecati vero aut, iure aliquid libero dolores.',
  content: [
    {
      type: 'video',
      progress: 1
    },
    {
      type: 'exam',
      progress: 1
    },
  ]
};

const blocks = [
  {
    name: 'Введение',
    description: '2 видео'
  },
  {
    name: 'Теория',
    description: '2 видео'
  },
  {
    name: 'Практика',
    description: '2 видео'
  },
  {
    name: 'Тестирование',
    description: '1 тест'
  },

  {
    name: 'Введение',
    description: '2 видео'
  },
  {
    name: 'Теория',
    description: '2 видео'
  },
  {
    name: 'Практика',
    description: '2 видео'
  },
  {
    name: 'Тестирование',
    description: '1 тест'
  },
]

const Course = ({ id, name }) => {
  const setTopNavBar = useSetRecoilState(topNavBarState);

  useEffect(() => {
    // configurating topNavBar
    setTopNavBar(prevState => ({
      ...prevState,
      title: courseData.title,
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
    }));
  }, [setTopNavBar])

  return (
    <div
      className="flex scene pb-2"
      style={{ padding: 0, margin: 'auto' }}>
      <div className={`
          mx-auto
          max-h-full
          px-4
          h-full
          max-w-[450px]
          duration-700
          grid-rows-[1fr_auto]
          inline-grid
          relative`}>
        <div className="max-h-full mb-8 pb-16 overflow-y-auto">
          <section className="mt-4">
            <img
              style={{ aspectRatio: "1 / 1" }}
              className={`
                w-full
                bg-base-700
                rounded-xl
                object-cover
              `}
              src={`https://random.imagecdn.app/1000/1000?${courseData.id}`} alt="" />
            <p className="text-2xl text-base-100 font-bold mt-5 mb-0">{courseData.title}</p>
            <div className="flex gap-2 text-xl">
              <span className="text-base-200">4 ⭐</span>
              <span className="text-base-200">200 🪙</span>
            </div>
            <p className={`text-base-200 mt-1`}>{courseData.description}</p>
          </section>
          <section
            className="flex flex-col gap-3 mt-2 pb-4">
            {blocks.map(block =>
              <div className={`bg-base-800 p-3 rounded-lg w-full grid grid-cols-[auto_1fr_auto] duration-200 hover:bg-base-700 cursor-pointer items-center gap-2 shadow`}>
                <i className="fa fa-play-circle text-base-200 text-2xl" aria-hidden="true"></i>
                <div className="flex flex-col leading-none">
                  <span className="text-base-200 font-bold">{block.name}</span>
                  <span className={`text-base-400`}>{block.description}</span>
                </div>
                <i className={`fas fa-chevron-right text-base-400`}></i>
              </div>
            )}
          </section>
        </div>
        <section className="pb-4 px-4 w-full bottom-0 absolute">
          <button className="w-full btn-primary">Начать 🚀</button>
        </section>
      </div>
    </div>
  );
}

export default Course;
