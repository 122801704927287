import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { topNavBarState } from "../../state";
import { userState } from "../../state";

// components
import Loading from '../../components/Loading';
import SelectBranchCard from '../../components/SelectBranchCard';

// styles
import './home.scoped.less';

// utils
import { verifyPermissions } from '../../assets/js/security';

// querys
const BRANCHES_QUERY = loader('../../graphql/BRANCHES_QUERY.gql');

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useRecoilValue(userState);
  const setTopNavBar = useSetRecoilState(topNavBarState);
  const branchesQuery = useQuery(BRANCHES_QUERY);

  useEffect(() => {
    if (user) {
      switch (user.position_id) {
        case "6":
          navigate("/tickets", { replace: true });
          break;
      }
    }
  }, [user, navigate]);


  useEffect(() => {
    setTopNavBar(prevState => ({
      ...prevState,
      title: 'Главная',
      actions: [
        ...(verifyPermissions(user, [12003]) ? [
          {
            to: '/checklist/task_templates',
            content: <i className="fas fa-pen"></i>
          },
          {
            to: '/checklist/editor/new',
            content: <i className="fas fa-plus"></i>
          },
        ] : [])
      ]
    }));
    return () => setTopNavBar(prevState => ({
      ...prevState,
      title: '',
      actions: []
    }));
  }, [location.pathname, user, setTopNavBar]);

  return (
    <div className="home scene">
      <div className="grid">
        {
          !branchesQuery.loading &&
            branchesQuery.data !== undefined &&
            branchesQuery.data.branches ? (
              <>
                {branchesQuery.data.branches.map(branch => (
                  <SelectBranchCard
                    key={"SelectBranchCard" + branch.id}
                    branch={branch}
                    linkTo={"/tasks/" + branch.id} />
                ))}
              </>
            ) : <Loading />}
      </div>
    </div>
  );
}


export default Home;
